import Flex from "Components/Flex.js";
import Text from "Components/Text.js";
import scss from "./ErrorFallback.module.scss";
import {Button, Container} from "semantic-ui-react";

export default ({noTitle, noRefresh, noMargin, padded}) => {

	const classes = [];
	if (noMargin) classes.push(scss.noMargin);
	if (padded) classes.push(scss.padded);

	return (
		<Container className={classes.join(" ")} text={true}>
			{(!noTitle && <h1>Unhandled error</h1>)}
			<Flex gap={0.5} mb={1}>
				<Text
					content={`The application has experienced a problem.\nPlease try what you were doing again.`}
					noMargin={true} />
			</Flex>
			{(!noRefresh && <Button content="Refresh" onClick={() => window.location.reload()} />)}
		</Container>
	);

};
