import api from "api.js";
import useAdminViewport from "Admin/useAdminViewport.js";
import useData from "Hooks/useData.js";
import useQuery from "Hooks/useQuery.js";
import AdminAttendanceTable from "Admin/Attendance/AdminAttendanceTable.js";
import AdminFamilyView from "./AdminFamilyView.js";
import {useCallback, useMemo} from "react";

export default ({familyId, familyState}) => {

	const {computeBodyTopOffset} = useAdminViewport();

	const query = useQuery(useMemo(() => ({
		Limit: 10,
		Page: 1,
		Sort: "Date",
		SortDirection: "Desc"
	}), []));

	const state = useData(useCallback(
		() => {

			const familyId = familyState?.data?.Id;

			if (familyId) {
				return api({url: `/attendance`, params: {...query.query, Family: familyState.data?.Id}}).then(({data}) => data);
			}
			else return null;

		},
		[familyState, query.query]
	), true);

	return (
		<AdminFamilyView
			familyId={familyId}
			state={familyState}>
			<AdminAttendanceTable
				dontCombineFamilyIntoPersonColWhenFamilyColHidden={true}
				hiddenColumns={["Family"]}
				onQueryChange={query.setQuery}
				query={query.query}
				state={state}
				stickyHeader={computeBodyTopOffset({hasViewTabs: true})} />
		</AdminFamilyView>
	);

};
