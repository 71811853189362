import ActiveIcon from "Components/ActiveIcon.js";
import DataTable from "Components/DataTable.js";
import Link from "Components/Link.js";
import Text from "Components/Text.js";
import useViewport from "Hooks/useViewport.js";

export default props => {

	const {width: viewportWidth} = useViewport();

	const columns = [
		{
			id: "Name",
			render: i => <Link label={i.Name} uri={`/admin/sessions/${i.Id}`} />,
			sortable: true,
			width: 4
		},
		{
			id: "Description",
			render: i => <Text color={(!i.Description ? "secondary" : undefined)} content={(i.Description || "(None)")} singleLine={true} />,
			width: 6,
			hidden: (viewportWidth < 820)
		},
		{
			id: "External",
			render: i => <ActiveIcon active={i.External} />,
			width: 2,
			hidden: (viewportWidth < 450),
			filter: {
				labels: {
					true: "External",
					false: "Internal"
				},
				value: props.query.External,
				defaultValue: null
			}
		},
		{
			id: "Archived",
			render: i => <ActiveIcon active={i.Archived} />,
			width: 2,
			filter: {
				labels: {
					true: "Archived",
					false: "Active"
				},
				value: props.query.Archived,
				defaultValue: null
			}
		}
	];

	return (
		<DataTable
			columns={columns}
			definition={true}
			onQueryChange={props.onQueryChange}
			query={props.query}
			state={props.state}
			stickyHeader={props.stickyHeader} />
	);

};
