import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

export default () => {

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const navigationKey = useSelector(store => store.navigationKey);

	return useCallback(uri => {

		if (uri === `${location.pathname}${location.search}`) {
			dispatch({
				type: "update",
				data: {
					navigationKey: (navigationKey + 1)
				}
			});
		}
		else navigate(uri);

	}, [dispatch, location, navigate, navigationKey]);

};
