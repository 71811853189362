import Input from "./Input.js";
import {isValidE164} from "Includes/Phone.js";
import {useCallback} from "react";

export default props => {

	const {onChange, name} = props;

	const handleChange = useCallback(value => {
		onChange(value?.replaceAll(" ", ""), name);
	}, [name, onChange]);

	return (
		<Input
			autoFocus={props.autoFocus}
			disabled={props.disabled}
			error={(props.value ? ((props.value.length < 6) || (props.value.length > 16) || !isValidE164(props.value)) : false)}
			maxLength={16}
			minLength={6}
			name={name}
			onChange={handleChange}
			pattern="^((\+[1-9])|\d)\d{6,14}$"
			placeholder={(props.placeholder || "Phone number")}
			required={props.required}
			type="tel"
			value={props.value} />
	);

};
