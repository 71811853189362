import scss from "./AppBar.module.scss";
import useMenuItems from "./useAppBarMenuItems.js";
import useNavigator from "Hooks/useNavigator.js";
import useViewport from "Hooks/useViewport.js";
import Hidden from "Components/Hidden.js";
import ProfileBar from "./AppBarProfileBar";
import {Icon, Menu} from "semantic-ui-react";

export default () => {

	const menuItems = useMenuItems();
	const navigate = useNavigator();
	const {width: viewportWidth} = useViewport();

	const handleNavigate = e => {

		e.preventDefault?.();

		navigate(
			e.target.getAttribute("href") ||
			e.target.getAttribute("value") ||
			e.target.parentNode.getAttribute("href") ||
			e.target.parentNode.getAttribute("value")
		);

	};

	const hideLogo = (viewportWidth < 460);
	const menuItemsIconOnly = (viewportWidth < 1040);

	return (
		<>
			<nav className={scss.root}>
				<section className={scss.app}>
					<Hidden hidden={hideLogo}>
						<a
							href="/"
							onClick={handleNavigate}>
							<img
								alt="Abingdon Carousel"
								className={scss.icon}
								src="/icon.png" />
						</a>
					</Hidden>
					<Menu className={scss.menu}>
						{
							menuItems.map((i, key) => (
								<Menu.Item
									key={key}
									icon={menuItemsIconOnly}
									link={true}
									onClick={handleNavigate}
									title={i.label}
									value={i.uri}>
									<Icon name={i.icon} />
									{(!menuItemsIconOnly ? i.label : undefined)}
								</Menu.Item>
							))
						}
					</Menu>
				</section>
				<ProfileBar />
			</nav>
			<div className={scss.spacer} />
		</>
	);

};
