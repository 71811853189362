import DropdownButton from "Components/DropdownButton.js";
import ErrorBoundary from "App/Errors/ErrorBoundary.js";
import ErrorState from "Components/ErrorState.js";
import Flex from "Components/Flex.js";
import Hidden from "Components/Hidden.js";
import Link from "Components/Link.js";
import TabPanel from "Components/TabPanel.js";
import View from "Components/View.js";
import debounce from "lodash.debounce";
import scss from "./AdminView.module.scss";
import useNavigator from "Hooks/useNavigator.js";
import useAdminViewport from "./useAdminViewport.js";
import useAdminViewTabs from "./useAdminViewTabs.js";
import {useCallback, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {Button, Header, Input, Loader} from "semantic-ui-react";

export default props => {

	const navigate = useNavigator();
	const viewport = useAdminViewport();
	const {pathname: currentUri} = useLocation();

	const [search, setSearch] = useState(props.initialSearch);

	const handleSearchChange = useMemo(() => {
		return debounce(search => {
			if (search !== props.search) {
				props.onSearchChange(search);
			}
		}, 300);
	}, [props]);

	const handleSearchInputChange = useCallback(
		e => {
			const val = (e.target.value || null);
			setSearch(val);
			handleSearchChange.cancel();
			handleSearchChange(val);
		},
		[handleSearchChange]
	);

	const actions = props.actions?.filter?.(a => !a.hidden);
	const actionsDisabled = (props.actionsDisabled || props.actionsLoading);

	const hasActions = !!(props.onAction || props.onActionCancel || actions?.length);
	const showLoader = (props.loading && props.showLoader);
	const showToolbar = (!showLoader && (hasActions || props.searchable));

	const rootClasses = [scss.root];
	if (props.hideTabs) rootClasses.push(scss.hiddenTabs);
	if (viewport.useMobileToolbar) rootClasses.push(scss.useMobileToolbar);

	const headerClasses = [scss.header];
	if (!showToolbar) headerClasses.push(scss.noToolbar);

	const tabs = useAdminViewTabs().map(tab => {

		const finalUri = `/admin${(tab.uri || "")}`;

		return {
			...tab,
			active: (tab.uri ? currentUri.startsWith(finalUri) : (currentUri === finalUri)),
			uri: finalUri
		};

	});

	const renderViewTabs = () => {
		return (
			<TabPanel
				className={scss.viewTabs}
				iconsOnly={(props.tabsIconsOnly || (props.tabsIconsOnlyBreakpoint && (viewport.width <= props.tabsIconsOnlyBreakpoint)))}
				items={props.tabs} />
		);
	};

	return (
		<View className={rootClasses.join(" ")}>
			<Hidden hidden={props.hideTabs}>
				<nav className={scss.tabs}>
					<TabPanel
						iconsOnly={viewport.tabsIconsOnly}
						items={tabs}
						onSelect={tab => navigate(tab.uri)} />
				</nav>
			</Hidden>
			<header className={headerClasses.join(" ")}>
				<Flex alignItems="flex-start" gap={0.5}>
					{(props.backLink && <Link label={(props.backLinkLabel || "Back")} startArrow={true} uri={props.backLink} />)}
					{(props.title && !showLoader && <Header as="h1" className={scss.heading} content={props.title} />)}
				</Flex>
				<Hidden hidden={!showToolbar}>
					<Flex
						alignItems="center"
						className={scss.headerToolbar}
						columnar={true}
						gap={0.5}>
						<Hidden hidden={!props.searchable}>
							<Input
								icon="search"
								loading={props.loading}
								onChange={handleSearchInputChange}
								placeholder="Search..."
								readOnly={props.loading}
								value={(search || "")} />
						</Hidden>
						<Flex
							className={scss.headerToolbarActions}
							columnar={!!props.onActionCancel}
							display={((!hasActions || props.error) ? "none" : undefined)}
							gap={0.5}
							width="100%">
							{(props.onActionCancel && <Button content={(props.actionCancelLabel || "Cancel")} disabled={actionsDisabled} icon={(props.actionCancelIcon || "close")} onClick={props.onActionCancel} />)}
							{((props.onAction || actions?.length) && <DropdownButton color="blue" disabled={actionsDisabled} content={props.actionLabel} icon={props.actionIcon} loading={props.actionsLoading} onClick={props.onAction} options={actions} />)}
						</Flex>
					</Flex>
				</Hidden>
			</header>
			<Loader active={showLoader} />
			<ErrorBoundary noMargin={true} noTitle={true}>
				{(props.tabs && !props.loading && !props.error && renderViewTabs())}
			</ErrorBoundary>
			<ErrorBoundary noMargin={true} noTitle={true}>
				{(((!props.loading || !props.hideChildrenWhenLoading) && !props.error) && props.children)}
				{(props.error && <ErrorState e={props.error} onRetry={props.onErrorRetry} />)}
			</ErrorBoundary>
		</View>
	);

};
