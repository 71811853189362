import PeopleTypes from "Resources/PeopleTypes.js";
import Radio from "./Radio.js";

export default props => {

	return (
		<Radio
			{...props}
			disableValueTransformations={true}
			options={PeopleTypes.cases.map(c => ({label: c, value: PeopleTypes[c]}))} />
	);

};
