import DataTable from "Components/DataTable.js";
import Link from "Components/Link.js";

export default props => {

	const columns = [
		{
			id: "Name",
			render: i => <Link label={i.Name} uri={`/admin/families/${i.Id}`} />,
			sortable: true,
			width: 4
		},
		{
			id: "Town",
			render: i => i.Town,
			sortable: true,
			width: 2
		}
	];

	return (
		<DataTable
			columns={columns}
			definition={true}
			onQueryChange={props.onQueryChange}
			query={props.query}
			state={props.state}
			stickyHeader={props.stickyHeader} />
	);

};
