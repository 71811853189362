import {Button, Container, Header} from "semantic-ui-react";

export default ({e, onRetry}) => {

	return (
		<Container>
			<Header as="p" size="small" color="red" content={`Error loading content: ${(e?.message || "(No details available)")}.`} />
			{(onRetry && <Button content="Retry" onClick={onRetry} />)}
		</Container>
	);

};
