import scss from "./Segment.module.scss";
import Flex from "Components/Flex.js";
import {Header, Segment} from "semantic-ui-react";

export default props => {

	return (
		<Flex>
			{(props.label && <Header as="h2" className={scss.header} content={props.label} />)}
			{(!props.disableContentOutline ? <Segment className={scss.root}>{props.children}</Segment> : props.children)}
		</Flex>
	);

};
