import Checkbox from "Components/Checkbox.js";
import EnumCaseCheckboxes from "Components/EnumCaseCheckboxes.js";
import Flex from "Components/Flex.js";
import Input from "Components/Input.js";
import PeopleReferralTypes from "Resources/PeopleReferralTypes.js";
import {Button} from "semantic-ui-react";
import {useCallback} from "react";

export default props => {

	const {onChange, state} = props;

	const handleChange = useCallback((value, name) => {

		const update = {...state, [name]: value};

		if ((name === "Active") && !value) {
			update.Dismissable = false;
		}

		onChange(update);

	}, [onChange, state]);

	return (
		<>
			<Input
				autoFocus={true}
				control="textarea"
				disabled={props.disabled}
				maxLength={65535}
				name="Content"
				placeholder="Alert content"
				onChange={handleChange}
				required={true}
				rows={3}
				value={state.Content} />
			<Checkbox
				checkbox={true}
				disabled={props.disabled}
				label={`Display alert when ${props.person.Name}'s details are accessed?`}
				name="Active"
				onChange={handleChange}
				value={state.Active} />
			<Flex pl={2}>
				<Checkbox
					checkbox={true}
					disabled={(props.disabled || !state.Active)}
					label="Allow dismissal?"
					name="Dismissable"
					onChange={handleChange}
					value={state.Dismissable} />
			</Flex>
			<Checkbox
				checkbox={true}
				disabled={props.disabled}
				label={`Trigger the "Further information available" warning on the Session Sign In screen?`}
				name="Warning"
				onChange={handleChange}
				value={state.Warning} />
			<EnumCaseCheckboxes
				disabled={props.disabled}
				enum={PeopleReferralTypes}
				label="Mark as referred to external services?"
				name="Referrals"
				onChange={handleChange}
				selection={state.Referrals} />
			<Flex columnar={true} gap={0.5}>
				<Button
					content="Save"
					disabled={props.disabled}
					icon="check"
					loading={props.submitting}
					primary={true}
					type="submit" />
				<Button
					content="Cancel"
					disabled={props.disabled}
					onClick={props.onCancel}
					icon="close"
					type="button" />
			</Flex>
		</>
	);

};
