import useAuth from "Hooks/useAuth.js";
import useUriMatcher from "Hooks/useUriMatcher.js";
import AdminView from "Admin/AdminView.js";

export default props => {

	const {personId, state} = props;
	const {hasPermission} = useAuth();

	/**
	 * Define the view tabs
	 */
	const tabs = useUriMatcher([
		{
			label: "General",
			icon: "address book outline",
			uri: `/admin/people/${personId}`
		},
		{
			label: "Alerts",
			icon: "comments outline",
			uri: `/admin/people/${personId}/alerts`,
			hidden: !hasPermission("PersonAlertsManage")
		},
		{
			label: "Attendance Record",
			icon: "calendar alternate outline",
			uri: `/admin/people/${personId}/attendance`
		}
	], 0);

	/**
	 * Render!
	 */
	return (
		<AdminView
			actionIcon={props.actionIcon}
			actionLabel={props.actionLabel}
			actionsLoading={props.actionsLoading}
			backLink="/admin/people"
			backLinkLabel="All People"
			error={state.error}
			hideChildrenWhenLoading={true}
			loading={state.loading}
			onAction={props.onAction}
			onActionCancel={props.onActionCancel}
			onErrorRetry={state.fetch}
			showLoader={true}
			tabs={(personId ? tabs.items : undefined)}
			tabsIconsOnlyBreakpoint={430}
			title={(!personId ? "New Person" : state.data?.Name)}>
			{props.children}
		</AdminView>
	);

};
