import api from "api.js";
import scss from "./LoginView.module.scss";
import Flex from "Components/Flex.js";
import Hidden from "Components/Hidden.js";
import RefreshUserProfile from "Tasks/RefreshUserProfile.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {createRef, useCallback, useEffect, useState} from "react";
import {Button, Container, Form, Label, Message, Ref, Segment} from "semantic-ui-react";

export default () => {

	const emailInputRef = createRef();
	const passwordInputRef = createRef();
	const totpInputRef = createRef();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isTotp, setIsTotp] = useState(false);

	/** Initial form state */
	const initialFormState = {
		Email: "",
		Password: "",
		TotpCode: ""
	};

	/** Form state */
	const [formState, setFormState] = useState(initialFormState);

	/** Cancel handler */
	const handleCancel = () => {
		setError(null);
		setIsTotp(false);
		setFormState({...initialFormState});
	};

	/** Form field change handler */
	const handleChange = useCallback((e, {name, value}) => {
		if (((error?.response?.status !== 403) || (name === "Email")) &&
			((error?.response?.status !== 404) || (name === "Email"))) {
			setError(false);
		}
		setFormState({...formState, [name]: value});
	}, [error, formState]);

	/** Submission handler */
	const handleSubmit = useCallback(async () => {

		let auth;
		setError(false);
		setLoading(true);

		try {

			const authResponse = await api({
				url: "/auth",
				method: "POST",
				data: {...formState}
			});

			auth = authResponse.data?.auth;

		}
		catch (e) {
			if (!isTotp && (e?.response?.status === 401) && (e?.response?.data?.["auth.reason"] === "TotpInvalid")) {
				setIsTotp(true);
			}
			else setError(e);
		}

		if (auth) {
			try {
				await RefreshUserProfile({
					authToken: auth.token,
					extraDispatchData: {auth},
					throwOnError: true
				});
			}
			catch (e) {
				ToastStore.error(e);
			}
		}

		setLoading(false);

	}, [formState, isTotp]);

	/** Refocus inputs after errors */
	useEffect(() => {

		let inputRef = null;
		const status = error?.response?.status;

		if ((status === 404) || (status === 403)) {
			inputRef = emailInputRef;
		}
		else if (status === 401) {
			inputRef = (!isTotp ? passwordInputRef : totpInputRef);
		}

		inputRef?.current?.querySelector?.("input")?.select?.();

	}, [error, isTotp, emailInputRef, passwordInputRef, totpInputRef]);

	/** Render! */
	return (
		<div className={scss.root}>
			<Segment
				className={scss.segment}
				compact={true}
				textAlign="center">
				<Container
					className={scss.container}
					text={true}>
					<Flex>
						<img
							alt="Abingdon Carousel"
							className={scss.icon}
							src="/icon.png" />
						<Label
							content="Login to Abingdon Carousel FRSIS"
							size="large" />
						<Form
							className={scss.form}
							error={(error && (error?.response?.status !== 403))}
							loading={loading}
							onSubmit={handleSubmit}
							warning={(error?.response?.status === 403)}>
							<Flex alignItems="center">
								<Hidden hidden={isTotp}>
									<Ref innerRef={emailInputRef}>
										<Form.Input
											autoFocus={true}
											disabled={loading}
											error={(error?.response?.status === 404)}
											icon="at"
											iconPosition="left"
											name="Email"
											onChange={handleChange}
											placeholder="Email Address"
											required={true}
											type="email"
											value={formState.Email} />
									</Ref>
									<Ref innerRef={passwordInputRef}>
										<Form.Input
											disabled={loading}
											error={(error?.response?.status === 401)}
											icon="lock"
											iconPosition="left"
											name="Password"
											onChange={handleChange}
											placeholder="Password"
											required={true}
											type="password"
											value={formState.Password} />
									</Ref>
								</Hidden>
								<Hidden hidden={!isTotp}>
									<Message
										className={scss.totpMessage}
										header="Two-factor authentication required"
										content="Your account is protected with two-factor authentication. Please enter the code displayed in your authenticator app." />
									<Ref innerRef={totpInputRef}>
										<Form.Input
											autoComplete="off"
											autoFocus={true}
											disabled={loading}
											error={(error?.response?.status === 401)}
											icon="key"
											iconPosition="left"
											inputMode="numeric"
											maxLength={6}
											minLength={6}
											name="TotpCode"
											onChange={handleChange}
											placeholder="123456"
											required={true}
											value={formState.TotpCode} />
									</Ref>
								</Hidden>
								<Button
									className={scss.submit}
									content={(!isTotp ? "Login" : "Confirm")}
									disabled={loading}
									primary={true}
									type="submit" />
								<Hidden hidden={!isTotp}>
									<Button
										basic={true}
										content="Cancel"
										disabled={loading}
										onClick={handleCancel} />
								</Hidden>
								<Hidden hidden={!error}>
									<Container
										className={scss.messages}
										textAlign="left">
										<Message
											className={scss.message}
											error={true}
											header={(!isTotp ? "Login error" : undefined)}
											content={`${(((error?.response?.status === 401) && (!isTotp ? "Password incorrect." : "Incorrect authentication code.")) || ((error?.response?.status === 404) && "Unknown email address.") || error?.message || "No details available.")}`} />
										<Message
											className={scss.message}
											warning={true}
											header="Account deactivated"
											content="You cannot login at the moment because your account has been deactivated." />
									</Container>
								</Hidden>
							</Flex>
						</Form>
					</Flex>
				</Container>
			</Segment>
		</div>
	);

};
