import PeopleTypes from "Resources/PeopleTypes.js";

export default () => ({
	Name: null,
	Type: PeopleTypes.Parent,
	Dob: null,
	EmailAddresses: [{Email: null}],
	PhoneNumbers: [{Phone: null}],
	PhotoConsent: false,
	Notes: null,
	AllergyNotes: null,
	MedicalNotes: null
});
