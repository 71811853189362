import api from "api.js";
import AdminView from "Admin/AdminView.js";
import AdminReportsViewToolbar from "./AdminReportsViewToolbar.js";
import ButtonMenu from "Components/ButtonMenu.js";
import FileDownload from "js-file-download";
import Flex from "Components/Flex.js";
import Reports from "Resources/Reports.js";
import moment from "moment";
import strings from "./AdminReportsView.strings.json";
import useAdminViewport from "Admin/useAdminViewport.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useCallback, useState} from "react";

export default () => {

	const maxTimeframeYears = 1;
	const {computeBodyTopOffset} = useAdminViewport();

	const [downloadingReports, setDownloadingReports] = useState([]);

	const [query, setQuery] = useState({
		StartDate: (new moment()).subtract(1, "year").add(1, "day").format("YYYY-MM-DD"),
		EndDate: (new moment()).format("YYYY-MM-DD")
	});

	/** Report menu items to show */
	const menuItems = Reports.map(r => {
		return {
			...r,
			loading: downloadingReports.includes(r.id)
		};
	});

	/** Toolbar styles */
	const toolbarStyles = {
		position: "sticky",
		top: computeBodyTopOffset({hasTabs: false}),
		zIndex: 100
	};

	/**
	 * Query value changed.
	 * 
	 * @param {mixed} value
	 * @param {String} name
	 * @return {void}
	 */
	const handleQueryChange = useCallback((value, name) => {
		setQuery({...query, [name]: value});
	}, [query]);

	/**
	 * Set whether a report is currently downloading.
	 *
	 * @param {String} id
	 * @param {Boolean} loading
	 * @return {void}
	 */
	const setReportDownloading = useCallback((id, loading) => {
		let downloads = downloadingReports;
		if (loading && !downloads.includes(id)) downloads = [...downloads, id];
		else downloads = downloads.filter(i => (i !== id));
		setDownloadingReports(downloads);
	}, [downloadingReports]);

	/**
	 * We are downloading a report!
	 *
	 * @async
	 * @param {String} options.id Report ID
	 * @return {void}
	 */
	const handleDownload = useCallback(async ({id}) => {

		const {StartDate, EndDate} = query;
		const mStartDate = (StartDate ? new moment(StartDate) : null);
		const mEndDate = (EndDate ? new moment(EndDate) : null);

		if (!(mStartDate && mEndDate) || mStartDate.isAfter(mEndDate)) {
			ToastStore.toast({message: strings.datesInvalidError, type: "error"});
			return;
		}
		else if (Math.abs(mStartDate.diff(mEndDate, "years")) >= maxTimeframeYears) {
			ToastStore.toast({message: strings.datesDurationInvalidError, type: "error"});
			return;
		}

		setReportDownloading(id, true);
		ToastStore.toast({message: strings.downloadStarted});

		try {

			const response = await api({
				url: `/reports/${id}`,
				params: query,
				timeout: 60000,
				responseType: "blob"
			});

			const filename = response.headers?.["content-disposition"]?.split?.("filename=")?.[1];
			FileDownload(response.data, (filename || "abcarousel-report.csv"));

		}
		catch (e) {
			ToastStore.error(e);
		}

		setReportDownloading(id, false);

	}, [query, setReportDownloading]);

	/** Render! */
	return (
		<AdminView
			hideTabs={true}
			title="Reports">
			<Flex>
				<AdminReportsViewToolbar
					maxTimeframeYears={maxTimeframeYears}
					onChange={handleQueryChange}
					query={query}
					style={toolbarStyles} />
				<ButtonMenu
					basic={true}
					items={menuItems}
					onClick={handleDownload} />
			</Flex>
		</AdminView>
	);

};
