import api from "api.js";
import useAdminViewport from "Admin/useAdminViewport.js";
import useData from "Hooks/useData.js";
import useQuery from "Hooks/useQuery.js";
import AdminAttendanceTable from "Admin/Attendance/AdminAttendanceTable.js";
import AdminSessionView from "./AdminSessionView.js";
import {useCallback, useMemo} from "react";

export default ({sessionId, sessionState}) => {

	const {computeBodyTopOffset} = useAdminViewport();

	const query = useQuery(useMemo(() => ({
		Limit: 10,
		Page: 1,
		Sort: "Date",
		SortDirection: "Desc"
	}), []));

	const state = useData(useCallback(
		() => {

			const sessionId = sessionState?.data?.Id;

			if (sessionId) {
				return api({url: `/attendance`, params: {...query.query, Session: sessionId}}).then(({data}) => data);
			}
			else return null;

		},
		[sessionState, query.query]
	), true);

	return (
		<AdminSessionView
			sessionId={sessionId}
			state={sessionState}>
			<AdminAttendanceTable
				alwaysVisibleColumns={["Family"]}
				dontCombineFamilyIntoPersonColWhenFamilyColHidden={true}
				hiddenColumns={["Session"]}
				onQueryChange={query.setQuery}
				query={query.query}
				state={state}
				stickyHeader={computeBodyTopOffset({hasViewTabs: true})} />
		</AdminSessionView>
	);

};
