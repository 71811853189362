import api from "api.js";
import useData from "Hooks/useData.js";
import {useParams} from "react-router-dom";
import {useCallback} from "react";

export default ({child: Child}) => {

	const {Id: familyId} = useParams();

	const state = useData(useCallback(
		() => (familyId ? api({url: `/families/${familyId}`}).then(({data}) => data) : null),
		[familyId]
	));

	return <Child familyId={familyId} familyState={state} />;

};
