import AdminView from "Admin/AdminView.js";
import useUriMatcher from "Hooks/useUriMatcher.js";

export default props => {

	const {familyId, state} = props;

	const tabs = useUriMatcher([
		{
			label: "General",
			icon: "address book",
			uri: `/admin/families/${familyId}`
		},
		{
			label: "Members",
			icon: "address book outline",
			uri: `/admin/families/${familyId}/members`
		},
		{
			label: "Attendance Record",
			icon: "calendar alternate outline",
			uri: `/admin/families/${familyId}/attendance`
		}
	]);

	return (
		<AdminView
			actionIcon={props.actionIcon}
			actionLabel={props.actionLabel}
			actionsLoading={props.actionsLoading}
			backLink="/admin/families"
			backLinkLabel="All Families"
			error={state.error}
			hideChildrenWhenLoading={true}
			loading={state.loading}
			onAction={props.onAction}
			onActionCancel={props.onActionCancel}
			onErrorRetry={state.fetch}
			showLoader={true}
			tabs={(familyId ? tabs.items : undefined)}
			tabsIconsOnlyBreakpoint={500}
			title={(!familyId ? "New Family" : state.data?.Name)}>
			{props.children}
		</AdminView>
	);

};
