/**
 * Toast store
 *
 * Handles storage of toast notifications.
 *
 * @package Abcarousel
 * @subpackage Toasts
 * @author Heron Web Ltd
 * @copyright Abingdon Carousel
 */
class ToastStore {

	/**
	 * Constructor.
	 *
	 * @return {self}
	 */
	constructor() {

		/**
		 * Toast notifications
		 *
		 * @type {Array<Object>}
		 */
		this.toasts = [];

	}


	/**
	 * Get the toasts.
	 *
	 * @return {Array<Object>}
	 */
	getToasts() {
		return this.toasts;
	}


	/**
	 * Set the toasts.
	 *
	 * @param {Array<Object>} toasts
	 * @return {void}
	 */
	setToasts(toasts) {
		this.toasts = toasts;
	}


	/**
	 * Issue a toast.
	 *
	 * @param {String} options.message
	 * @param {String} options.header
	 * @param {String} options.type
	 * @param {String} options.icon
	 * @return {void}
	 */
	toast({message, header, type, icon}) {
		const toast = {message, header, type, icon};
		this.setToasts([...this.getToasts(), toast]);
		setTimeout(() => this.remove(toast), 7000);
	}


	/**
	 * Issue an error toast.
	 *
	 * @param {Error} error optional
	 * @param {String} options.message optional
	 * @param {String} options.header optional
	 * @return void
	 */
	error(error, {message=undefined, header="Error"}={}) {
		this.toast({message: (message || error?.message || undefined), header, type: "error"});
	}


	/**
	 * Issue a success toast.
	 *
	 * @param {String} message
	 * @param {String} header optional
	 * @return {void}
	 */
	success(message, header=undefined) {
		this.toast({message, header, type: "success"});
	}


	/**
	 * Remove a toast object from the store.
	 *
	 * @param {Object} toast
	 * @return {void}
	 */
	remove(toast) {
		const toasts = this.getToasts();
		this.setToasts(toasts.filter(t => (t !== toast)));
	}

}

export default ToastStore;
