import api from "api.js";
import Dialog from "Components/Dialog.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useEffect, useState} from "react";

export default ({alert, open, onClose, onDeleted}) => {

	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		setIsSubmitting(false);
	}, [alert]);

	const handleSubmit = async () => {

		setIsSubmitting(true);

		try {

			await api({
				url: `/people/alerts/${alert.Id}`,
				method: "DELETE"
			});

			onClose();
			onDeleted(alert);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	};

	return (
		<Dialog
			loading={isSubmitting}
			onClose={onClose}
			onOk={handleSubmit}
			open={open}
			header="Delete alert"
			content="Are you sure you want to delete this alert?" />
	);

};
