import Flex from "Components/Flex.js";
import PeopleTypes from "Resources/PeopleTypes.js";
import Text from "Components/Text.js";
import scss from "./SigninVisitorSelectorPersonListItem.module.scss";
import {Button, Icon, Label, Message} from "semantic-ui-react";

export default props => {

	const {person} = props;

	/**
	 * Render the alerts warning.
	 *
	 * @return {ReactNode}
	 */
	const renderAlertsWarning = () => (
		<Flex alignItems="flex-start">
			<Label
				basic={true}
				color="orange"
				content="Further information available."
				icon="warning sign"
				size="medium" />
		</Flex>
	);

	/**
	 * Render the allergy details.
	 *
	 * @return {ReactNode}
	 */
	const renderAllergies = () => (
		<Message warning={true}>
			<Message.Content>
				<Message.Header content="Allergy Warning" />
				<Text content={person.AllergyNotes} />
			</Message.Content>
		</Message>
	);

	/** Render! */
	return (
		<Flex
			alignItems="flex-start"
			columnar={true}
			removeContentMargins={true}>
			<Icon
				color="blue"
				name="user circle"
				size="big" />
			<Flex
				className={scss.contentContainer}
				gap={1}
				removeContentMargins={true}>
				<Flex
					gap={0}
					removeContentMargins={true}>
					<p className={scss.personName}>
						<strong>{person.Name}</strong>
					</p>
					<Text
						color="secondary"
						content={`${PeopleTypes.getCaseFromValue(person.Type)} • ${(person.Family?.Name || "No Family")}`} />
				</Flex>
				{(person.AllergyNotes && renderAllergies())}
				{(person.HasWarningAlerts && renderAlertsWarning())}
			</Flex>
			<Button
				className={scss.removeButton}
				icon="trash"
				negative={true}
				onClick={() => props.onRemove(person)}
				size="small" />
		</Flex>
	);

};
