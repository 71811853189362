import api from "api.js";
import Checkbox from "Components/Checkbox.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import Text from "Components/Text.js";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ToastStore} from "Toasts/ToastProvider.js";

export default props => {

	const {target, onClose, onComplete, open} = props;
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [selection, setSelection] = useState([]);

	/** Attendances we can sign out of */
	const eligibleAttendances = useMemo(
		() => target?.Attendance.filter(a => !a.SignedOut),
		[target]
	);

	/**
	 * Toggled whether to sign out of an attendance.
	 *
	 * @param {Integer} attendanceId
	 * @return {void}
	 */
	const handleSelectionToggle = useCallback(attendanceId => {
		let newSelection = [...selection];
		if (!newSelection.includes(attendanceId)) newSelection.push(attendanceId);
		else newSelection = newSelection.filter(a => (a !== attendanceId));
		setSelection(newSelection);
	}, [selection]);

	/**
	 * Submitting.
	 *
	 * @async
	 * @return {void}
	 */
	const handleSubmit = useCallback(async () => {

		if (!selection.length) {
			ToastStore.toast({message: "Please select one or more sessions to sign out of.", type: "warning"});
			return;
		}

		setIsSubmitting(true);

		try {

			const result = await api({url: `/attendance/signout`, method: "POST", data: {Records: selection}}).then(({data}) => data);

			const allSuccess = Object.values(result).every(r => r);
			const successIds = Object.keys(result).filter(r => result[r]).map(r => parseInt(r));
			if (!allSuccess) ToastStore.toast({message: "Some records couldn't be signed out.", type: "warning"});

			onClose();
			onComplete(target, successIds);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [target, selection, onClose, onComplete]);

	/** Reset the selection when props change */
	useEffect(() => {
		setSelection((eligibleAttendances?.map(a => a.Id) || []));
	}, [eligibleAttendances, target, open]);

	/** Render! */
	return (
		<Dialog
			header={`Sign Out: ${target?.Name}`}
			loading={isSubmitting}
			okLabel="Sign Out"
			onOk={handleSubmit}
			onClose={onClose}
			open={open}>
			<Text
				color="secondary"
				content={`${target?.Name} is signed into several sessions. Please select the ones to sign out of.`} />
			<Flex gap={0.5}>
				{
					eligibleAttendances?.map((a, key) => {

						const selected = selection.includes(a.Id);

						return (
							<Checkbox
								checkbox={true}
								disabled={(isSubmitting || (!selected && selection.length >= 100))}
								key={key}
								label={(a.Session?.Name || "(Unknown)")}
								onChange={() => handleSelectionToggle(a.Id)}
								value={selected} />
						);

					})
				}
			</Flex>
		</Dialog>
	);

};
