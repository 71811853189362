import scss from "./RegistrationViewSteps.module.scss";
import useAuth from "Hooks/useAuth.js";
import useViewport from "Hooks/useViewport.js";
import {Step} from "semantic-ui-react";

export default props => {

	const {hasPermission} = useAuth();

	const steps = [
		{
			icon: "address book",
			title: "Family Details"
		},
		{
			icon: "users",
			title: "Family Members"
		},
		{
			icon: "check circle outline",
			title: "Review"
		},
		{
			icon: "calendar check",
			title: "Sign In",
			permission: "SessionSignIn"
		}
	].filter(step => (!step.permission || hasPermission(step.permission)));

	const {width: viewportWidth} = useViewport();
	const isDesktop = (viewportWidth > 640);
	const size = (isDesktop ? "tiny" : "mini");

	return (
		<div className={scss.container}>
			<Step.Group
				className={scss.root}
				size={size}
				unstackable={true}
				widths={steps.length}>
				{
					steps.map((step, key) => (
						<Step
							{...step}
							active={((props.active === key) && !props.disabled)}
							disabled={(props.disabled || !props.unlockedSteps.includes(key))}
							key={key}
							onClick={() => props.onChange(key)}
							title={(isDesktop ? step.title : undefined)} />
					))
				}
			</Step.Group>
		</div>
	);
};
