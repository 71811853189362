import DataTableFilterDropdown from "./DataTableFilterDropdown.js";
import Flex from "Components/Flex.js";
import Hidden from "./Hidden.js";
import Pagibar from "./Pagibar.js";
import Text from "Components/Text.js";
import scss from "./DataTable.module.scss";
import useViewport from "Hooks/useViewport.js";
import {useCallback} from "react";
import {Container, Loader, Table} from "semantic-ui-react";

export default props => {

	const {width: viewportWidth} = useViewport();

	const data = (!props.unpaginatedData ? props.state?.data?.Objects : props.state?.data);
	const filteredData = (props.filterData ? props.filterData(data) : data);

	const isDisabled = (props.disabled || props.state.loading);
	const isStacked = (props.stackWidth && (viewportWidth <= props.stackWidth));
	const visibleColumns = props.columns.filter(c => ((!c.hidden && !props.hiddenColumns?.includes?.(c.id)) || props.alwaysVisibleColumns?.includes?.(c.id)));
	const visibleColumnsIds = visibleColumns.map(c => c.id);

	const columnRendererData = {visibleColumns: visibleColumnsIds};

	const handleQueryChange = useCallback(
		query => props.onQueryChange({...props.query, ...query}),
		[props]
	);

	const handleFilterChange = useCallback(
		(filter, value) => handleQueryChange({[filter]: value}),
		[handleQueryChange]
	);

	const handlePageChange = useCallback(
		Page => handleQueryChange({Page}),
		[handleQueryChange]
	);

	const handleSortChange = useCallback(
		e => {
			/** Can click the cell or the label */
			const col = (e.target.getAttribute("value") || e.target.parentNode.getAttribute("value"));
			const asc = ((col === props.query.Sort) ? (props.query.SortDirection.toLowerCase() !== "asc") : true);
			handleQueryChange({Sort: col, SortDirection: (asc ? "Asc" : "Desc")});
		},
		[handleQueryChange, props]
	);

	const renderEmpty = (msg, error=null, loading=false) => (
		<Table.Row>
			<Table.Cell
				colSpan={visibleColumns.length}
				disabled={true}
				error={(!loading ? !!error : false)}
				textAlign="center">
				{(!loading ? (error?.message || msg) : <Loader active={true} inline="centered" size="small" />)}
			</Table.Cell>
		</Table.Row>
	);

	const classes = [scss.container];

	if (isStacked) {
		classes.push(scss.stackable);
	}

	return (
		<Container
			className={classes.join(" ")}
			fluid={true}
			textAlign="center">
			<Table
				className={scss.table}
				definition={props.definition}
				selectable={!isStacked}
				sortable={true}
				striped={!isStacked}
				unstackable={true}>
				<Table.Header
					fullWidth={true}
					style={{top: props.stickyHeader}}>
					<Table.Row>
						{
							visibleColumns.map((col, key) => (
								<Table.HeaderCell
									className={scss.th}
									key={key}
									onClick={((col.sortable && !isDisabled) ? handleSortChange : undefined)}
									sorted={((col.id === props.query.Sort) ? `${props.query.SortDirection.toLowerCase()}ending` : undefined)}
									value={col.id}
									width={(props.columnWidthOverrides?.[col.id] || col.width)}>
									<Flex
										alignItems="center"
										className={scss.thContentContainer}
										columnar={true}
										justifyContent="space-between">
										{(col.label || col.id)}
										{(col.filter && <DataTableFilterDropdown col={col} disabled={isDisabled} onChange={handleFilterChange} />)}
									</Flex>
								</Table.HeaderCell>
							))
						}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{
						filteredData?.map?.((i, key) => (
							<Table.Row key={key}>
								{
									visibleColumns.map((col, key) => (
										<Table.Cell
											key={key}
											style={col.getCellStyles?.(i)}
											width={(props.columnWidthOverrides?.[col.id] || col.width)}>
											<div>
												{(isStacked && <Text className={scss.stackedLabel} bold={true} content={(col.label || col.id)} />)}
												<div>{col.render(i, columnRendererData)}</div>
											</div>
										</Table.Cell>
									))
								}
							</Table.Row>
						))
					}
					{(!filteredData?.length && renderEmpty((props.state.error ? "Error loading results." : (props.emptyMessage || "No results.")), props.state.error, props.state.loading))}
				</Table.Body>
			</Table>
			<Hidden hidden={(!data?.length || props.unpaginatedData)}>
				<Pagibar
					activePage={props.query.Page}
					disabled={isDisabled}
					onPageChange={handlePageChange}
					totalPages={Math.ceil(((props.state.data?.Count || 0) / props.query.Limit))} />
			</Hidden>
		</Container>
	);

};
