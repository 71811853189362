import api from "api.js";
import useNavigator from "Hooks/useNavigator.js";
import ActiveIcon from "Components/ActiveIcon.js";
import AdminSessionArchiveDialog from "./AdminSessionArchiveDialog.js";
import AdminSessionView from "./AdminSessionView.js";
import Checkbox from "Components/Checkbox.js";
import Input from "Components/Input.js";
import TableList from "Components/TableList.js";
import Text from "Components/Text.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {createRef, useEffect, useState} from "react";
import {Ref} from "semantic-ui-react";

export default props => {

	const create = !props.sessionId;
	const state = props.sessionState;

	const formRef = createRef();
	const nameInputRef = createRef();
	const navigate = useNavigator();

	const [editState, setEditState] = useState({
		Name: null,
		Description: null,
		External: false
	});

	const [archiveDialogOpen, setArchivedDialogOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submissionError, setSubmissionError] = useState(null);

	const handleArchiveDialogOpen = () => {
		setArchivedDialogOpen(true);
	};

	const handleArchiveDialogClose = () => {
		setArchivedDialogOpen(false);
	};

	const handleArchiveToggled = () => {
		state.setData({...state.data, Archived: !state.data.Archived});
	};

	const handleEditChange = (value, name) => {
		setSubmissionError(null);
		setEditState({...editState, [name]: value});
	};

	const handleEditingStart = () => {
		setIsEditing(true);
		setEditState({...state.data});
	};

	const handleEditingCancel = () => {
		setIsEditing(false);
	};

	const handleEditSubmit = async () => {

		if (!formRef?.current?.reportValidity?.()) {
			return;
		}

		setIsSubmitting(true);

		try {

			const result = await api({
				url: (create ? `/sessions` : `/sessions/${state.data.Id}`),
				method: (create ? "POST" : "PUT"),
				data: {
					Name: editState.Name,
					Description: editState.Description,
					External: editState.External
				}
			});

			if (create) {
				state.setLoading(true);
				navigate(`/admin/sessions/${result.data.Id}`);
			}
			else {
				setIsEditing(false);
				state.setData(result.data);
			}

		}
		catch (e) {

			setSubmissionError(e);

			if (e?.response?.status === 409) {
				ToastStore.toast({
					header: "This session already exists",
					message: `There's already a session named ${editState.Name}. Session names must be unique. Please choose a new name.`,
					type: "error"
				});
			}
			else ToastStore.error(e);

		}

		setIsSubmitting(false);

	};

	const actions = [
		{
			text: (!state.data?.Archived ? "Archive" : "Unarchive"),
			onClick: handleArchiveDialogOpen
		}
	];

	useEffect(() => {
		if (submissionError?.response?.status === 409) {
			nameInputRef?.current?.querySelector?.("input")?.select?.();
		}
	}, [nameInputRef, submissionError]);

	return (
		<AdminSessionView
			actions={(!(isEditing || create) && actions)}
			actionIcon={(!(isEditing || create) ? "edit" : "check")}
			actionLabel={(!(isEditing || create) ? "Edit" : "Save")}
			actionsLoading={isSubmitting}
			onAction={(!(isEditing || create) ? (!state.data?.Archived ? handleEditingStart : undefined) : handleEditSubmit)}
			onActionCancel={(isEditing && handleEditingCancel)}
			sessionId={props.sessionId}
			state={props.sessionState}>
			<TableList
				data={[
					{
						label: "Name",
						render: () => state.data?.Name,
						renderEditor: () => (
							<Ref innerRef={nameInputRef}>
								<Input
									autoFocus={true}
									disabled={isSubmitting}
									error={(submissionError?.response?.status === 409)}
									name="Name"
									maxLength={255}
									minLength={1}
									onChange={handleEditChange}
									required={true}
									value={editState.Name} />
							</Ref>
						)
					},
					{
						label: "Description",
						render: () => <Text color={(!state.data?.Description ? "secondary" : undefined)} content={(state.data?.Description || "(None)")} />,
						renderEditor: () => (
							<Input
								control="textarea"
								disabled={isSubmitting}
								name="Description"
								maxLength={65535}
								onChange={handleEditChange}
								rows={5}
								value={editState.Description} />
						)
					},
					{
						label: "External",
						subtext: "External sessions are run by third-party providers.",
						render: () => <ActiveIcon active={state.data?.External} />,
						renderEditor: () => (
							<Checkbox
								disabled={isSubmitting}
								name="External"
								onChange={handleEditChange}
								value={editState.External} />
						)
					},
					{
						label: "Archived",
						subtext: "Archived sessions are not shown on the sign in screen.",
						render: () => <ActiveIcon active={state.data?.Archived} />,
						renderEditor: () => <Text color="secondary" content="Archive the session by pressing Save and then selecting Archive from the session's dropdown menu." />,
						retainContentWhenEditing: true,
						hidden: create,
						cellGap: 1
					}
				]}
				disabled={isSubmitting}
				formRef={formRef}
				isEditing={(isEditing || create)} />
			<AdminSessionArchiveDialog
				onClose={handleArchiveDialogClose}
				onComplete={handleArchiveToggled}
				open={archiveDialogOpen}
				session={state.data} />
		</AdminSessionView>
	);

};
