import api from "api.js";
import useAdminViewport from "Admin/useAdminViewport.js";
import useData from "Hooks/useData.js";
import useQuery from "Hooks/useQuery.js";
import AdminFamilyView from "./AdminFamilyView.js";
import AdminPeopleTable from "Admin/People/AdminPeopleTable.js";
import {useCallback, useMemo} from "react";

export default ({familyId, familyState}) => {

	const {computeBodyTopOffset} = useAdminViewport();

	const query = useQuery(useMemo(() => ({
		Limit: 10,
		Page: 1,
		Sort: "Name",
		SortDirection: "Asc",
		HasNotes: null,
		HasAllergyNotes: null,
		HasMedicalNotes: null,
		PhotoConsent: null
	}), []), useMemo(() => ({
		HasNotes: "boolean",
		HasAllergyNotes: "boolean",
		HasMedicalNotes: "boolean",
		PhotoConsent: "boolean"
	}), []));

	const state = useData(useCallback(
		() => {

			const familyId = familyState?.data?.Id;

			if (familyId) {
				return api({url: `/people`, params: {...query.query, Family: familyId}}).then(({data}) => data);
			}
			else return null;

		},
		[familyState, query.query]
	), true);

	return (
		<AdminFamilyView
			familyId={familyId}
			state={familyState}>
			<AdminPeopleTable
				hiddenColumns={["Family"]}
				onQueryChange={query.setQuery}
				query={query.query}
				state={state}
				stickyHeader={computeBodyTopOffset({hasViewTabs: true})} />
		</AdminFamilyView>
	);

};
