import scss from "./Input.module.scss";
import {Form} from "semantic-ui-react";

export default props => {

	const classes = [];
	const name = ((props.name !== undefined) ? props.name : props.label);

	/**
	 * Props that we have to selectively apply depending on control type
	 *
	 * (Otherwise we can get an unavoidable console error, even with 
	 * `undefined` as the value, if a prop's supplied to `Form.Input` 
	 * which the rendered `control` type doesn't understand.)
	 */
	const inputProps = {};

	/**
	 * These props can't be used with textarea components
	 */
	if (props.control !== "textarea") {
		inputProps.iconPosition = props.iconPosition;
	}

	/**
	 * Textareas need special styles
	 */
	if (props.control === "textarea") {
		classes.push(scss.textarea);
	}


	/**
	 * Change handler
	 */
	const handleChange = e => {
		props.onChange((e.target.value || null), name);
	};


	/**
	 * Key handler
	 */
	const handleKeyDown = e => {
		if (e.which === 13) {
			props.onEnter?.();
		}
	};


	/**
	 * Render!
	 */
	return (
		<Form.Input
			autoComplete="off"
			autoFocus={props.autoFocus}
			className={classes.join(" ")}
			control={props.control}
			disabled={props.disabled}
			error={props.error}
			icon={props.icon}
			inputMode={props.inputMode}
			inline={props.inlineLabel}
			label={props.label}
			name={name}
			max={props.max}
			maxLength={props.maxLength}
			min={props.min}
			minLength={props.minLength}
			onBlur={props.onBlur}
			onChange={handleChange}
			onKeyDown={(props.onEnter && handleKeyDown)}
			pattern={props.pattern}
			placeholder={(props.placeholder || props.label || props.name)}
			required={props.required}
			rows={props.rows}
			type={props.type}
			value={(props.value || "")}
			{...inputProps} />
	);

};
