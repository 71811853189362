import scss from "./Appv.module.scss";
import {Container} from "semantic-ui-react";

export default ({label=false, showUnknown=false}) => {
	return (
		<Container className={scss.container} text={true}>
			<p><small>{(label && <strong>App version:</strong>)} {(process.env.REACT_APP_APP || (showUnknown ? "Unknown" : "-"))}</small></p>
		</Container>
	);
};
