import Checkbox from "Components/Checkbox.js";
import FieldLabel from "Components/FieldLabel.js";
import Flex from "Components/Flex.js";

export default props => {

	const handleChange = (value, name) => {
		let selection = [...props.selection];
		if (!value) selection = selection.filter(cs => (cs !== name));
		else if (value && !selection.includes(name)) selection.push(name);
		props.onChange(selection, props.name);
	};

	return (
		<Flex gap={0.5}>
			{(props.label && <FieldLabel label={props.label} resetCss={true} />)}
			<Flex
				columnar={true}
				wrap={true}>
				{
					props.enum.cases.map((cs, key) => {
						return (
							<Checkbox
								checkbox={true}
								disabled={props.disabled}
								key={key}
								label={props.enum.toFriendlyCaseName(cs)}
								name={cs}
								onChange={handleChange}
								value={props.selection.includes(cs)} />
						);
					})
				}
			</Flex>
		</Flex>
	);

};
