import {Container} from "semantic-ui-react";

export default () => {
	return (
		<Container text={true}>
			<h1>Page Not Found</h1>
			<p>Please check the URI you've visited.</p>
		</Container>
	);
};
