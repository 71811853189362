import api from "api.js";
import useData from "Hooks/useData.js";
import ActiveIcon from "Components/ActiveIcon.js";
import AdminUserActivationDialog from "./AdminUserActivationDialog.js";
import AdminUserPasswordResetDialog from "./AdminUserPasswordResetDialog.js";
import AdminUserTotpDisableDialog from "./AdminUserTotpDisableDialog.js";
import AdminView from "Admin/AdminView.js";
import Input from "Components/Input.js";
import Link from "Components/Link.js";
import TableList from "Components/TableList.js";
import Text from "Components/Text.js";
import useAuth from "Hooks/useAuth.js";
import useNavigator from "Hooks/useNavigator.js";
import useToggle from "Hooks/useToggle.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {createRef, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Message, Ref} from "semantic-ui-react";

export default ({create}) => {

	const formRef = createRef();
	const emailInputRef = createRef();

	const navigate = useNavigator();
	const params = useParams();

	const [editState, setEditState] = useState({
		Name: null,
		Email: null
	});

	const {authUser} = useAuth();

	const [isEditing, setIsEditing] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submissionError, setSubmissionError] = useState(null);

	const activationDialogOpen = useToggle(false);
	const disableTotpDialogOpen = useToggle(false);
	const pwrDialogOpen = useToggle(false);

	const state = useData(useCallback(
		() => (!create ? api({url: `/users/${params.Id}`}).then(({data}) => data) : null),
		[create, params]
	));

	const handleActivationToggled = () => {
		state.setData({...state.data, Active: !state.data.Active});
	};

	const handleTotpDisabled = () => {
		state.setData({...state.data, TotpEnabled: false});
	};

	const handleEditChange = (value, name) => {
		setSubmissionError(null);
		setEditState({...editState, [name]: value});
	};

	const handleEditingStart = () => {
		setIsEditing(true);
		setEditState({...state.data});
	};

	const handleEditingCancel = () => {
		setIsEditing(false);
	};

	const handleEditSubmit = async () => {

		if (!formRef?.current?.reportValidity?.()) {
			return;
		}

		setIsSubmitting(true);
		setSubmissionError(null);

		try {

			const result = await api({
				url: (create ? `/users` : `/users/${state.data.Id}`),
				method: (create ? "POST" : "PUT"),
				data: {
					Name: editState.Name,
					Email: editState.Email
				}
			});

			if (create) {
				state.setLoading(true);
				navigate(`/admin/users/${result.data.Id}`);
			}
			else {
				setIsEditing(false);
				state.setData(result.data);
			}

		}
		catch (e) {

			setSubmissionError(e);

			if (e?.response?.status === 409) {
				ToastStore.toast({
					header: "Email address already exists",
					message: "The email address you've entered is already associated with a user account.",
					type: "error"
				});
			}
			else ToastStore.error(e);

		}

		setIsSubmitting(false);

	};

	useEffect(() => {
		if (submissionError?.response?.status === 409) {
			emailInputRef?.current?.querySelector?.("input")?.select?.();
		}
	}, [emailInputRef, submissionError]);

	const isAuthUser = (state.data?.Id === authUser.Id);

	const actions = [
		{
			text: (!state.data?.Active ? "Reactivate" : "Deactivate"),
			onClick: activationDialogOpen.setTrue,
			disabled: isAuthUser
		}
	];

	return (
		<AdminView
			actions={(!(isEditing || create) && actions)}
			actionIcon={(!(isEditing || create) ? "edit" : "check")}
			actionLabel={(!(isEditing || create) ? "Edit" : "Save")}
			actionsLoading={isSubmitting}
			backLink="/admin/users"
			backLinkLabel="All Users"
			error={state.error}
			hideChildrenWhenLoading={true}
			loading={state.loading}
			onAction={(!(isEditing || create) ? handleEditingStart : handleEditSubmit)}
			onActionCancel={(isEditing && handleEditingCancel)}
			onErrorRetry={state.fetch}
			showLoader={true}
			title={(create ? "New User" : state.data?.Name)}>
			<Message
				header="You are viewing your own user account"
				content="Some controls are disabled because they can't be used with your own account."
				icon="warning"
				hidden={!isAuthUser}
				warning={true} />
			<TableList
				data={[
					[
						{
							label: "Name",
							render: () => state.data?.Name,
							renderEditor: () => (
								<Input
									autoFocus={true}
									disabled={isSubmitting}
									name="Name"
									maxLength={255}
									minLength={1}
									onChange={handleEditChange}
									required={true}
									value={editState.Name} />
							)
						},
						{
							label: "Email",
							render: () => <a href={`mailto:${state.data?.Email}`}>{state.data?.Email}</a>,
							renderEditor: () => (
								<Ref innerRef={emailInputRef}>
									<Input
										disabled={isSubmitting}
										error={(submissionError?.response?.status === 409)}
										name="Email"
										maxLength={255}
										onChange={handleEditChange}
										placeholder="Email Address"
										required={true}
										type="email"
										value={editState.Email} />
								</Ref>
							)
						}
					],
					[
						{
							label: "Active",
							subtext: "Only active users can login.",
							render: () => <ActiveIcon active={state.data?.Active} />,
							renderEditor: () => <div><Button content={(!state.data?.Active ? "Reactivate" : "Deactivate")} disabled={isAuthUser} onClick={activationDialogOpen.setTrue} type="button" /></div>,
							retainContentWhenEditing: true,
							hidden: create,
							cellGap: 1
						},
						{
							label: "2FA Enabled",
							subtext: "Requires a TOTP code at login.",
							render: () => <ActiveIcon active={state.data?.TotpEnabled} />,
							renderEditor: () => <div>{((!state.data?.TotpEnabled || isAuthUser) ? (!isAuthUser ? <Text color="secondary" content="Only the user can enable 2FA on their account." /> : <Link endArrow={true} label="Manage in Profile" uri="/profile" />) : <Button content="Disable" onClick={disableTotpDialogOpen.setTrue} type="button" />)}</div>,
							retainContentWhenEditing: true,
							hidden: create,
							cellGap: ((!isAuthUser && state.data?.TotpEnabled) ? 1 : undefined)
						},
						{
							label: "Password",
							render: () => <div><Button content="Reset Password" onClick={pwrDialogOpen.setTrue} type="button" /></div>,
							hidden: create
						},
						{
							label: "Password",
							render: () => <Text color="secondary" content="An automatically generated password will be sent to the user's email address." />,
							hidden: !create
						},
						{
							label: "Permissions",
							render: () => (!create ? <div><Link endArrow={true} label="Manage Permissions" uri={`/admin/users/${state.data?.Id}/permissions`} /></div> : <Text color="secondary" content="You can assign permissions once the user has been created." />)
						}
					]
				]}
				disabled={isSubmitting}
				formRef={formRef}
				nestedTables={true}
				onEditFormSubmit={handleEditSubmit}
				isEditing={(isEditing || create)} />
			<AdminUserActivationDialog
				onClose={activationDialogOpen.setFalse}
				onComplete={handleActivationToggled}
				open={activationDialogOpen.value}
				user={state.data} />
			<AdminUserPasswordResetDialog
				onClose={pwrDialogOpen.setFalse}
				open={pwrDialogOpen.value}
				user={state.data} />
			<AdminUserTotpDisableDialog
				onComplete={handleTotpDisabled}
				onClose={disableTotpDialogOpen.setFalse}
				open={disableTotpDialogOpen.value}
				user={state.data} />
		</AdminView>
	);

};
