import scss from "./Accordion.module.scss";

export default props => {

	const rootClasses = [scss.root];

	if (props.noSummarySpacing) {
		rootClasses.push(scss.no_bottom_margin);
	}

	return (
		<details
			className={rootClasses.join(" ")}
			onToggle={props.onToggle}
			open={props.open}>
			<summary>
				<div className={`field ${scss.label}`}>
					<label>{props.label}</label>
				</div>
			</summary>
			{props.children}
		</details>
	);

};
