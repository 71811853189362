export default [
	{
		label: "Home",
		icon: "home",
		uri: "/",
		hiddenOnHome: true
	},
	{
		label: "Registration",
		icon: "signup",
		uri: "/registration",
		permission: "FamilyRegistration"
	},
	{
		label: "Session Sign In",
		icon: "calendar check",
		uri: "/signin",
		permission: "SessionSignIn"
	},
	{
		label: "On-Site List",
		icon: "fire extinguisher",
		uri: "/onsite",
		permission: "ViewOnsiteList"
	},
	{
		label: "Reports",
		icon: "line graph",
		uri: "/reports",
		permission: "ReportAccess"
	},
	{
		label: "Administration",
		icon: "settings",
		uri: "/admin",
		permission: "Administrator"
	}
];
