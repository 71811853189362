import useViewport from "Hooks/useViewport.js";
import {Icon, Pagination} from "semantic-ui-react";

export default props => {

	const {width: viewportWidth} = useViewport();

	const activePage = (props.activePage || 1);
	const totalPages = (props.totalPages || 1);

	const isFirstPage = (activePage === 1);
	const isLastPage = (activePage === totalPages);

	return (
		<Pagination
			activePage={activePage}
			boundaryRange={((viewportWidth < 400) ? 0 : 1)}
			className={props.className}
			disabled={props.disabled}
			ellipsisItem={null}
			firstItem={{content: <Icon name="angle double left" />, icon: true, disabled: (props.disabled || isFirstPage)}}
			lastItem={{content: <Icon name="angle double right" />, icon: true, disabled: (props.disabled || isLastPage)}}
			nextItem={{content: <Icon name="angle right" />, icon: true, disabled: (props.disabled || isLastPage)}}
			prevItem={{content: <Icon name="angle left" />, icon: true, disabled: (props.disabled || isFirstPage)}}
			onPageChange={(e, c) => props.onPageChange(c.activePage)}
			size="small"
			totalPages={totalPages} />
	);

};
