import ErrorState from "Components/ErrorState.js";
import {Loader} from "semantic-ui-react";

export default ({children, error, loading, onErrorRetry}) => {

	if (loading) {
		return <Loader active={true} />;
	}
	else if (error) {
		return <ErrorState e={error} onRetry={onErrorRetry} />;
	}
	else return children;

};
