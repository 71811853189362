/**
 * Register the application's service worker.
 *
 * Only takes effect when service worker support is available
 * in the environment and we're running in production mode (as
 * service workers can have unintended side effects in development).
 *
 * @return {void}
 */
export default () => {
	if (process.env.NODE_ENV === "production") {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.register("/sw.js").catch(() => {
				// Errors don't matter here
			});
		}
	}
};
