import api from "api.js";
import Dialog from "Components/Dialog.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useState} from "react";

export default props => {

	const [submitting, setSubmitting] = useState(false);

	const handleConfirm = async () => {

		let success = false;
		setSubmitting(true);

		try {

			await api({
				url: `/users/${props.user?.Id}/${(!props.user?.Active ? "activate" : "deactivate")}`,
				method: "POST"
			});

			success = true;

		}
		catch (e) {

			/**
			 * The user is already activated/deactivated
			 *
			 * We can pretend this is success
			 */
			if (e?.response?.status === 409) {
				success = true;
			}
			else ToastStore.error(e);

		}

		if (success) {
			props.onClose();
			props.onComplete();
		}

		setSubmitting(false);

	};

	const content = [`Are you sure you want to ${(!props.user?.Active ? "reactivate" : "deactivate")} this user account?`];
	if (props.user?.Active) content.push(`Inactive users cannot login. Any existing login sessions will be immediately deauthorised.`);

	return (
		<Dialog
			content={content.join("\n")}
			header={`${(!props.user?.Active ? "Reactivate" : "Deactivate")} User`}
			loading={submitting}
			onClose={props.onClose}
			onOk={handleConfirm}
			open={props.open} />
	);


};
