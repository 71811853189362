/**
 * Enum class
 *
 * @package Abcarousel
 * @subpackage Includes
 * @author Heron Web Ltd
 * @copyright Abingdon Carousel
 */
class Enum {

	/**
	 * Get the names of all the cases defined in the enum.
	 *
	 * @return {Array<String>}
	 */
	static get cases() {
		return Object.keys(this);
	}


	/**
	 * Get the name of the case corresponding to a given value.
	 *
	 * @param {mixed} value
	 * @return {String|undefined}
	 */
	static getCaseFromValue(value) {
		let caseName = null;
		const index = Object.values(this).indexOf(value);
		if (index >= 0) caseName = Object.keys(this)[index];
		return (caseName || undefined);
	}


	/**
	 * Convert a case name to a "friendly" label, suitable for UI-display, 
	 * by splitting the apparent words in the name based on use of capitals.
	 *
	 * @param {String} cs An enum case name
	 * @return {String}
	 */
	static toFriendlyCaseName(cs) {

		const friendly = [];

		for (const c of cs.split("")) {
			friendly.push(`${((c.toUpperCase() !== c) ? "" : " ")}${c}`);
		}

		return friendly.join("").trim();

	}

}

export default Enum;
