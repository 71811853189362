import scss from "./Panel.module.scss";
import {Accordion, Icon} from "semantic-ui-react";

export default props => {

	return (
		<Accordion fluid styled>
			<Accordion.Title active={props.open} className={scss.title} onClick={props.onOpenToggle}>
				<Icon name="dropdown" />
				{props.title}
				{(props.action && <div className={scss.action}>{props.action}</div>)}
			</Accordion.Title>
			<Accordion.Content active={props.open} className={scss.content}>
				{props.children}
			</Accordion.Content>
		</Accordion>
	);

};
