import FieldLabel from "Components/FieldLabel.js";
import Flex from "Components/Flex.js";
import {Form} from "semantic-ui-react";

export default props => {

	const valuesDomAttrsMap = [
		[true, 1],
		[false, 0],
		[null, "null"]
	];

	const transformDomAttrValueToValue = val => {
		const v = valuesDomAttrsMap.find(v => (v[1] === val))?.[0];
		return ((v !== undefined) ? v : val);
	};

	const transformValueToDomAttrValue = val => {
		const v = valuesDomAttrsMap.find(v => (v[0] === val))?.[1];
		return ((v !== undefined) ? v : val);
	};

	const handleChange = (e, c) => {

		let value = c.value;

		if (!props.disableValueTransformations) {
			value = transformDomAttrValueToValue(value);
		}

		props.onChange(value, props.name);

	};

	return (
		<Flex gap={0}>
			{(props.fieldLabel && <FieldLabel error={props.error} label={props.fieldLabel} required={props.required} />)}
			<Flex
				columnar={props.columnar}
				columnGap={2}
				innerRef={props.innerRef}
				mt={(props.fieldLabel ? 0.5 : undefined)}
				rowGap={0.5}
				wrap={true}>
				{
					props.options.map((o, key) => {

						const value = o.value;

						return (
							<Form.Radio
								checked={(props.value === value)}
								disabled={props.disabled}
								key={key}
								label={o.label}
								name={props.name}
								onChange={handleChange}
								value={(!props.disableValueTransformations ? transformValueToDomAttrValue(value) : value)} />
						);

					})
				}
			</Flex>
		</Flex>
	);

};
