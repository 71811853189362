import api from "api.js";
import AdminView from "Admin/AdminView.js";
import Checkbox from "Components/Checkbox.js";
import Flex from "Components/Flex.js";
import TableList from "Components/TableList.js";
import Text from "Components/Text.js";
import useAuth from "Hooks/useAuth.js";
import useData from "Hooks/useData.js";
import useNavigator from "Hooks/useNavigator.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export default () => {

	const {authUser} = useAuth();
	const navigate = useNavigator();
	const params = useParams();

	const [submitting, setSubmitting] = useState(false);
	const [values, setValues] = useState({});

	const state = useData(useCallback(
		() => api(`/auth/users/${params.Id}/permissions`).then(({data}) => data),
		[params.Id]
	));

	const isAuthUser = (state.data?.User.Id === authUser.Id);

	const handleTogglePermission = (assigned, permission) => {
		setValues({...values, [permission]: assigned});
	};

	useEffect(() => {
		if (state.data) {

			const values = {};

			state.data.Permissions.forEach(p => {
				values[p.Name] = p.Assigned;
			});

			setValues(values);

		}
	}, [state.data]);

	const tableData = state.data?.Permissions?.sort?.((a, b) => a.Name.localeCompare(b.Name))?.map?.(permission => {
		return {
			label: permission.Name,
			render() {
				return (
					<Flex gap={0.5}>
						<Checkbox
							disabled={(submitting || ((permission.Name === "Sysadmin") && isAuthUser))}
							name={permission.Name}
							onChange={handleTogglePermission}
							value={values[permission.Name]} />
						<Text
							color="secondary"
							content={permission.Description} />
					</Flex>
				);
			}
		};
	});

	const handleSave = async () => {

		setSubmitting(true);

		try {

			await api({
				url: `/auth/users/${state.data?.User.Id}/permissions`,
				method: "PUT",
				data: {Permissions: Object.keys(values).filter(p => values[p])}
			});

			navigate(`/admin/users/${state.data?.User.Id}`);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setSubmitting(false);

	};

	return (
		<AdminView
			actionIcon="check"
			actionLabel="Save"
			actionsLoading={submitting}
			backLink={`/admin/users/${params.Id}`}
			backLinkLabel="User Details"
			error={state.error}
			hideChildrenWhenLoading={true}
			loading={state.loading}
			onAction={handleSave}
			onErrorRetry={state.fetch}
			showLoader={true}
			title={(state.data ? `Permissions: ${state.data?.User.Name}` : undefined)}>
			<TableList data={tableData} />
		</AdminView>
	);

};
