import Accordion from "Components/Accordion.js";
import Input from "Components/Input.js";
import PostcodeInput from "Components/PostcodeInput.js";
import Radio from "Components/Radio.js";
import RegistrationViewForm from "./RegistrationViewForm.js";
import strings from "./RegistrationView.strings.json";
import useOptionalTextareaAccordion from "Hooks/useOptionalTextareaAccordion.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {createRef, useCallback, useState} from "react";
import {Ref} from "semantic-ui-react";

export default props => {

	const formRef = (props.formRef || createRef());
	const giftAidField = createRef();
	const notesField = createRef();

	/** Display the Gift Aid field in its error state? */
	const [giftAidError, setGiftAidError] = useState(false);

	/** Form state */
	const [formState, setFormState] = useState((props.initialState || {
		Name: null,
		Notes: null,
		Town: null,
		Postcode: null,
		GiftAidStatus: null
	}));

	/** Is the notes accordion open? */
	const notesAccordionState = useOptionalTextareaAccordion(!!props.initialState?.Notes, notesField);

	/** Field change handler */
	const handleChange = useCallback((value, name) => {
		setGiftAidError(false);
		setFormState({...formState, [name]: value});
	}, [formState]);

	/** We're submitting */
	const handleSubmit = e => {

		e.preventDefault();

		if (!validateForm()) {
			return;
		}

		props.onSubmit({...formState});

	};

	/** Validate the form state */
	const validateForm = useCallback(() => {
		if (!formRef?.current?.reportValidity?.()) {
			return false;
		}
		else if (formState.GiftAidStatus === null) {

			ToastStore.toast({
				message: "Please complete the Gift Aid field before you continue.",
				type: "error"
			});

			setGiftAidError(true);

			giftAidField?.current?.scrollIntoView?.();

			return false;

		}
		else return true;
	}, [formRef, formState.GiftAidStatus, giftAidField]);

	/** Render! */
	return (
		<RegistrationViewForm
			caption={strings.familyDetails.caption}
			innerRef={formRef}
			onSubmit={handleSubmit}>
			<Input
				autoFocus={true}
				disabled={props.disabled}
				label="Surname"
				maxLength={255}
				onChange={handleChange}
				name="Name"
				required={true}
				value={formState.Name} />
			<Input
				disabled={props.disabled}
				label="Town"
				maxLength={255}
				onChange={handleChange}
				required={true}
				value={formState.Town} />
			<PostcodeInput
				disabled={props.disabled}
				label="Postcode"
				onChange={handleChange}
				required={true}
				value={formState.Postcode} />
			<Radio
				columnar={true}
				disabled={props.disabled}
				error={giftAidError}
				fieldLabel="Eligible for Gift Aid?"
				innerRef={giftAidField}
				name="GiftAidStatus"
				onChange={handleChange}
				options={[
					{
						label: "Yes - Gift Aid eligible",
						value: true
					},
					{
						label: "No - Not Gift Aid eligible",
						value: false
					}
				]}
				required={true}
				value={formState.GiftAidStatus} />
			<Accordion
				label="Notes"
				noSummarySpacing={true}
				onToggle={notesAccordionState.toggle}
				open={notesAccordionState.open}>
				<Ref innerRef={notesField}>
					<Input
						control="textarea"
						disabled={props.disabled}
						maxLength={65535}
						name="Notes"
						onChange={handleChange}
						placeholder="Record any relevant notes..."
						rows={5}
						value={formState.Notes} />
				</Ref>
			</Accordion>
		</RegistrationViewForm>
	);

};
