import api from "api.js";
import useData from "Hooks/useData.js";
import ResourcePicker from "./ResourcePicker.js";
import {useCallback, useState} from "react";

export default props => {

	const {apiUrl} = props;

	const [query, setQuery] = useState({
		Search: null,
		Limit: 100,
		Page: 1
	});

	const handleSearchChange = Search => {
		setQuery({...query, Search});
	};

	const state = useData(useCallback(
		() => {
			if (query.Search) {
				return api({url: (apiUrl || "/people"), params: query}).then(({data}) => data);
			}
			else return () => {};
		},
		[apiUrl, query]
	));

	return (
		<ResourcePicker
			countAvailable={state.data?.Count}
			disabled={props.disabled}
			fluid={props.fluid}
			getOptionId={p => p.Id}
			getOptionLabel={p => p.Name}
			multiple={props.multiple}
			noResultsMessage={(!query.Search ? "Type to search..." : null)}
			onChange={props.onChange}
			onSearchChange={handleSearchChange}
			options={state.data?.Objects}
			placeholder="Search for people"
			size={props.size}
			state={state}
			value={props.value} />
	);

};
