import Enum from "Includes/Enum.js";

/**
 * People referral types enum
 *
 * @package Abcarousel
 * @subpackage Resources
 * @author Heron Web Ltd
 * @copyright Abingdon Carousel
 */
class PeopleReferralTypes extends Enum {

	static DomesticAbuse = 1;

	static FoodBank = 2;

	static SocialServices = 3;

	static Other = 4;

}

export default PeopleReferralTypes;
