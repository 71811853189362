import api from "api.js";
import useData from "Hooks/useData.js";
import {useParams} from "react-router-dom";
import {useCallback} from "react";

export default ({child: Child}) => {

	const {Id: personId} = useParams();

	/** Fetch our Person object */
	const state = useData(useCallback(
		() => (personId ? api({url: `/people/${personId}`}).then(({data}) => data) : null),
		[personId]
	));

	/** Render! */
	return <Child personId={personId} personState={state} />;

};
