import api from "api.js";
import ActiveIcon from "Components/ActiveIcon.js";
import AdminFamilyView from "./AdminFamilyView.js";
import Flex from "Components/Flex.js";
import Input from "Components/Input.js";
import PostcodeInput from "Components/PostcodeInput.js";
import Radio from "Components/Radio.js";
import Segment from "Components/Segment.js";
import TableList from "Components/TableList.js";
import Text from "Components/Text.js";
import useNavigator from "Hooks/useNavigator.js";
import {createRef, useState} from "react";
import {Form, Ref} from "semantic-ui-react";
import {ToastStore} from "Toasts/ToastProvider.js";

export default props => {

	const formRef = createRef();
	const navigate = useNavigator();

	const create = !props.familyId;
	const state = props.familyState;

	/** Creation edit state */
	const [editState, setEditState] = useState({
		Name: null,
		Town: null,
		Postcode: null,
		Notes: null,
		GiftAidStatus: null
	});

	/** Set up our state */
	const [isEditing, setIsEditing] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	/**
	 * Editing.
	 * 
	 * @return {void}
	 */
	const handleEditingStart = () => {
		setIsEditing(true);
		setEditState({...state.data});
	};

	/**
	 * Cancelling editing.
	 *
	 * @return {void}
	 */
	const handleEditingCancel = () => {
		setIsEditing(false);
	};

	/**
	 * Edited a value.
	 *
	 * @param {mixed} value
	 * @param {String} name
	 * @return {void}
	 */
	const handleEditChange = (value, name) => {
		setEditState({...editState, [name]: value});
	};

	/**
	 * Submitting family creation/edit
	 *
	 * @async
	 * @return {void}
	 */
	const handleEditSubmit = async () => {

		if (!formRef?.current?.reportValidity?.()) {
			return;
		}

		setIsSubmitting(true);

		try {

			const result = await api({
				url: (create ? `/families` : `/families/${state.data.Id}`),
				method: (create ? "POST" : "PUT"),
				data: {
					Name: editState.Name,
					Town: editState.Town,
					Postcode: editState.Postcode,
					Notes: editState.Notes,
					GiftAidStatus: editState.GiftAidStatus
				}
			});

			if (create) {
				state.setLoading(true);
				navigate(`/admin/families/${result.data.Id}`);
			}
			else {
				setIsEditing(false);
				state.setData(result.data);
			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	};

	return (
		<AdminFamilyView
			actionIcon={(!(isEditing || create) ? "edit" : "check")}
			actionLabel={(!(isEditing || create) ? "Edit" : "Save")}
			actionsLoading={isSubmitting}
			familyId={props.familyId}
			onAction={((!(isEditing || create) ? handleEditingStart : handleEditSubmit))}
			onActionCancel={(isEditing && handleEditingCancel)}
			state={props.familyState}>
			<Ref innerRef={formRef}>
				<Form>
					<TableList
						data={[
							{
								label: "Name",
								render: () => state.data?.Name,
								renderEditor: () => (
									<Input
										autoFocus={true}
										disabled={isSubmitting}
										name="Name"
										maxLength={255}
										minLength={1}
										onChange={handleEditChange}
										required={true}
										value={editState.Name} />
								)
							},
							{
								label: "Town",
								render: () => state.data?.Town,
								renderEditor: () => (
									<Input
										disabled={isSubmitting}
										name="Town"
										maxLength={255}
										minLength={1}
										onChange={handleEditChange}
										required={true}
										value={editState.Town} />
								)
							},
							{
								label: "Postcode",
								render: () => state.data?.Postcode,
								renderEditor: () => (
									<PostcodeInput
										disabled={isSubmitting}
										name="Postcode"
										onChange={handleEditChange}
										required={true}
										value={editState.Postcode} />
								)
							},
							{
								label: "Gift Aid",
								render: () => ((state.data?.GiftAidStatus === null) ? <Text color="secondary" content="(Not Set)" /> : <ActiveIcon active={state.data?.GiftAidStatus} />),
								renderEditor: () => (
									<Radio
										disabled={isSubmitting}
										name="GiftAidStatus"
										onChange={handleEditChange}
										options={[{label: "Yes", value: true}, {label: "No", value: false}, {label: "Not Set", value: null}]}
										value={editState.GiftAidStatus} />
								)
							}
						]}
						editWithoutForm={true}
						isEditing={(isEditing || create)} />
					<Flex gap={2} mt={2}>
						<Segment label="Notes">
							{(
								!isEditing ?
									(
										<Text content={(state.data?.Notes || "(None)")} />
									) :
									(
										<Input
											control="textarea"
											disabled={isSubmitting}
											name="Notes"
											maxLength={65535}
											onChange={handleEditChange}
											rows={5}
											value={editState.Notes} />
									)
							)}
						</Segment>
					</Flex>
				</Form>
			</Ref>
		</AdminFamilyView>
	);

};
