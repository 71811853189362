import api from "api.js";
import scss from "./TotpSettingsSection.module.scss";
import strings from "./TotpSettingsSection.strings.json";
import useAuth from "Hooks/useAuth.js";
import Flex from "Components/Flex.js";
import Input from "Components/Input.js";
import ProfileViewSection from "./ProfileViewSection.js";
import RefreshUserProfile from "Tasks/RefreshUserProfile.js";
import Text from "Components/Text.js";
import TotpConfirmationDialog from "./TotpConfirmationDialog.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {createRef, useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Form, Message, Ref} from "semantic-ui-react";

export default () => {

	const {authUser} = useAuth();
	const passwordInputRef = createRef();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [result, setResult] = useState(null);
	const [password, setPassword] = useState(null);

	/**
	 * TOTP enablement status is given by the user profile data 
	 * saved into the app's state - we disable all our controls 
	 * when an update is in-progress, in case the TOTP enablement 
	 * state turns out to have transitioned compared to what we know.
	 */
	const userProfileDataUpdating = useSelector(store => store.userProfileDataUpdating);


	/**
	 * Password input changed.
	 *
	 * @return {void}
	 */
	const handleChangePassword = useCallback(password => {
		setError(null);
		setPassword(password);
	}, []);


	/**
	 * Setup confirmation dialog closed.
	 * 
	 * @return {void}
	 */
	const handleConfirmDialogClose = useCallback(() => {
		setPassword(null);
		setResult(null);
	}, []);


	/**
	 * Submission handler.
	 * 
	 * @return {void}
	 */
	const handleSubmit = useCallback(async () => {

		setError(null);
		setLoading(true);

		try {

			/** We are ENABLING 2FA */
			if (!authUser.TotpEnabled) {

				const result = await api({
					url: "/profile/2fa/enable",
					method: "POST",
					data: {Password: password}
				});

				setResult(result.data);

			}

			/** We are DISABLING 2FA */
			else {

				await api({
					url: "/profile/2fa/disable",
					method: "POST",
					data: {Password: password}
				});

				/**
				 * We have to refresh the user profile data now so 
				 * we transition to the "2FA disabled" UI because the 
				 * profile data stored in the app state determines 
				 * our current state.
				 */
				await RefreshUserProfile();

				setPassword(null);
				ToastStore.success("Two-factor authentication has been disabled.");

			}

		}
		catch (e) {

			setError(e);

			if (e?.response?.status !== 401) {
				ToastStore.error(e);
			}

		}

		setLoading(false);

	}, [authUser.TotpEnabled, password]);


	/**
	 * Refocus the password input when a submission error 
	 * is due to the user entering their password incorrectly.
	 */
	useEffect(() => {
		if (error?.response?.status === 401) {
			passwordInputRef?.current?.querySelector?.("input")?.select?.();
		}
	}, [error, passwordInputRef]);


	/**
	 * Render the form content
	 *
	 * @type {ReactNode}
	 */
	const form = (
		<>
			<Flex gap={0.5} mb={1}>
				<Text
					className={scss.text}
					color={(!authUser.TotpEnabled ? "secondary" : undefined)}
					content={(!authUser.TotpEnabled ? strings.help : strings.helpEnabled)} />
			</Flex>
			<Form
				error={!!error}
				onSubmit={handleSubmit}>
				<Ref innerRef={passwordInputRef}>
					<Input
						disabled={(loading || userProfileDataUpdating)}
						error={(error?.response?.status === 401)}
						icon="key"
						iconPosition="left"
						onChange={handleChangePassword}
						placeholder="Your Password"
						required={true}
						type="password"
						value={password} />
				</Ref>
				<Button
					content={(!authUser.TotpEnabled ? "Enable" : "Disable")}
					disabled={(loading || userProfileDataUpdating)}
					loading={loading}
					primary={true}
					type="submit" />
				<Message
					error={true}
					hidden={(error?.response?.status !== 401)}
					content="You've entered your password incorrectly." />
			</Form>
		</>
	);


	/**
	 * Render the main content!
	 */
	return (
		<ProfileViewSection fullWidth={authUser.TotpEnabled}>
			{(!authUser.TotpEnabled ? form : <Message success={true}>{form}</Message>)}
			<TotpConfirmationDialog
				onClose={handleConfirmDialogClose}
				open={!!result}
				password={password}
				secret={result?.Secret}
				user={authUser} />
		</ProfileViewSection>
	);

};
