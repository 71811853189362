export default ({error, label, required, resetCss}) => {

	const classes = ["field"];

	if (resetCss) {
		classes.push("reset");
	}

	if (required) {
		classes.push("required");
	}

	const markStyles = {};

	if (!error) {
		markStyles.display = "contents";
	}

	return (
		<div className={classes.join(" ")}>
			<label><mark style={markStyles}>{label}</mark></label>
		</div>
	);

};
