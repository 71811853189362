import initialState from "./State.js";

export default (state, action) => {

	if (action.type === "update") {
		state = {...state, ...action.data};
	}

	else if (action.type === "reset") {
		state = {...initialState, flags: state.flags, inhibitLogoutListener: (action.inhibitLogoutListener || false)};
	}

	return {...state};

};
