import api from "api.js";
import AlertReferralsList from "Admin/People/AlertReferralsList.js";
import Checkbox from "Components/Checkbox.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import Text from "Components/Text.js";
import moment from "moment";
import pluralize from "pluralize";
import useAuth from "Hooks/useAuth.js";
import {useCallback, useState} from "react";
import {Message} from "semantic-ui-react";
import {ToastStore} from "Toasts/ToastProvider.js";

export default props => {

	const alertCount = (props.alerts?.length || 1);
	const {onClose} = props;

	const {hasPermission} = useAuth();
	const canDismiss = (hasPermission("PersonAlertsDismiss") || hasPermission("PersonAlertsManage"));

	const [alertsToDismiss, setAlertsToDismiss] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	/**
	 * Toggling whether to dismiss an alert on submit.
	 *
	 * @param {Boolean} dismiss
	 * @param {Integer} alertId
	 * @return {void}
	 */
	const handleToggleDismissAlert = useCallback((dismiss, alertId) => {
		let alerts = [...alertsToDismiss];
		if (dismiss) alerts = alerts.filter(id => (id !== alertId));
		else alerts.push(alertId);
		setAlertsToDismiss(alerts);
	}, [alertsToDismiss, setAlertsToDismiss]);

	/**
	 * Submitting dismissals.
	 *
	 * @async
	 * @return {void}
	 */
	const handleSubmit = useCallback(async () => {

		if (alertsToDismiss?.length) {

			try {

				setIsSubmitting(true);

				const result = await api({url: `/people/alerts/dismiss`, method: "POST", data: {Alerts: alertsToDismiss}});

				const errors = Object.values(result.data).filter(v => !v);

				if (errors.length) {

					let message;
					if (alertsToDismiss?.length === 1) {
						message = "The alert could not be dismissed.";
					}
					else message = `${errors.length} alerts could not be dismissed.`;

					ToastStore.toast({message, type: "warning"});

				}

			}
			catch (e) {
				ToastStore.error(e);
			}

		}

		setIsSubmitting(false);
		onClose();

	}, [alertsToDismiss, onClose]);

	return (
		<Dialog
			header={`${pluralize("Alert", alertCount)}: ${props.person?.Name}`}
			loading={isSubmitting}
			onClose={handleSubmit}
			open={props.open}>
			{
				props.alerts?.map((alert, key) => {

					const isDismissable = (alert.Dismissable && canDismiss);
					const isMarkedForDismiss = alertsToDismiss.includes(alert.Id);

					return (
						<Message key={key} warning={true}>
							<Message.Content>
								<Message.Header content={(new moment(alert.CreationAuditStamp.Timestamp)).format("DD/MM/YYYY HH:mm")} />
								{(!!alert.Referrals?.length && <AlertReferralsList referrals={alert.Referrals} />)}
								<Flex gap={0} mt={1} mb={(isDismissable ? 1 : 0)}>
									<div>
										<Text content={alert.Content} />
									</div>
								</Flex>
								{(isDismissable && <Checkbox checkbox={true} disabled={(isSubmitting || (!isMarkedForDismiss && (alertsToDismiss.length > 100)))} label="Dismiss this alert?" onChange={value => handleToggleDismissAlert(!value, alert.Id)} value={isMarkedForDismiss} />)}
							</Message.Content>
						</Message>
					);

				})
			}
		</Dialog>
	);

};
