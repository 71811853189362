import AppBar from "Ui/AppBar.js";
import ErrorBoundary from "./Errors/ErrorBoundary.js";
import F12Dialog from "F12/F12Dialog.js";
import LogoutListener from "./Listeners/LogoutListener.js";
import NavigateListener from "./Listeners/NavigateListener.js";
import RefreshUserProfile from "Tasks/RefreshUserProfile.js";
import Router from "./Router.js";
import useAuth from "Hooks/useAuth.js";
import {useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import {Divider} from "semantic-ui-react";

export default () => {

	const {isAuthed} = useAuth();
	const [launchUserProfileUpdateDone, setLaunchUserProfileUpdateDone] = useState(false);

	useEffect(() => {
		if (isAuthed && !launchUserProfileUpdateDone) {
			RefreshUserProfile();
		}
		setLaunchUserProfileUpdateDone(true);
	}, [isAuthed, launchUserProfileUpdateDone]);

	return (
		<BrowserRouter>
			{(isAuthed && <AppBar />)}
			<ErrorBoundary>
				<Router />
			</ErrorBoundary>
			<Divider hidden={true} />
			<ErrorBoundary fallback={null}>
				<F12Dialog />
			</ErrorBoundary>
			<ErrorBoundary fallback={null}>
				<LogoutListener />
			</ErrorBoundary>
			<ErrorBoundary fallback={null}>
				<NavigateListener />
			</ErrorBoundary>
		</BrowserRouter>
	);

};
