import FieldLabel from "./FieldLabel.js";
import Flex from "./Flex.js";
import {Form} from "semantic-ui-react";

export default props => {

	const handleChange = () => {
		props.onChange(!props.value, props.name);
	};

	return (
		<Flex>
			{(props.fieldLabel && <FieldLabel error={props.error} label={props.fieldLabel} required={props.required} />)}
			<Form.Checkbox
				checked={props.value}
				disabled={props.disabled}
				label={props.label}
				name={props.name}
				onChange={handleChange}
				required={props.required}
				toggle={!props.checkbox}
				value={(props.value ? 1 : 0)} />
		</Flex>
	);

};
