/**
 * Get whether a value is a valid E.164 phone number.
 *
 * (The backend API expects to handle all phone numbers as E.164.)
 *
 * @param {String} phone
 * @return {Boolean}
 */
const isValidE164 = phone => !!phone?.match?.(/^((\+[1-9])|\d)\d{1,14}$/);

export {isValidE164};
