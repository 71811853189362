import pluralize from "pluralize";
import scss from "./AdminAttendanceMetrics.module.scss";
import {Statistic} from "semantic-ui-react";

export default props => {

	const {
		AttendancesCount,
		DatesCount,
		InternalSessionsCount,
		ExternalSessionsCount,
		FamiliesCount,
		PeopleCount,
		TownsCount
	} = (props.state.data || {});

	const SessionsCount = ((InternalSessionsCount || 0) + (ExternalSessionsCount || 0));

	return (
		<div className={props.className}>
			<Statistic.Group
				className={scss.statistics}
				size="mini"
				widths="6">
				<Statistic
					color="pink"
					label={pluralize("Visit", (AttendancesCount || 0))}
					value={(AttendancesCount || "-")} />
				<Statistic
					color="blue"
					label={pluralize("Day", (DatesCount || 0))}
					value={(DatesCount || "-")} />
				<Statistic
					color="teal"
					label={pluralize("Session", SessionsCount)}
					value={(SessionsCount || "-")} />
				<Statistic
					color="yellow"
					label={pluralize("Family", (FamiliesCount || 0))}
					value={(FamiliesCount || "-")} />
				<Statistic
					color="brown"
					label={pluralize("Person", (PeopleCount || 0))}
					value={(PeopleCount || "-")} />
				<Statistic
					color="grey"
					label={pluralize("Town", (TownsCount || 0))}
					value={(TownsCount || "-")} />
			</Statistic.Group>
		</div>
	);

};
