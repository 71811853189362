import Flex from "Components/Flex.js";
import Input from "Components/Input.js";
import moment from "moment";
import scss from "./AdminReportsViewToolbar.module.scss";
import {Form} from "semantic-ui-react";
import {useCallback} from "react";

export default props => {

	const {onChange} = props;

	const handleDateChange = useCallback((value, name) => {
		// We want the inputs to be non-clearable!
		if (value) {
			onChange(value, name);
		}
	}, [onChange]);

	const swallowSubmitEvents = useCallback(e => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const today = (new moment()).format("YYYY-MM-DD");
	const startDate = new moment(props.query.StartDate);
	const endDate = new moment(props.query.EndDate);
	const timeframeTooLong = (Math.abs(startDate.diff(endDate, "years")) >= props.maxTimeframeYears);

	return (
		<div className={scss.root} style={props.style}>
			<Form onSubmit={swallowSubmitEvents}>
				<Flex columnar={true}>
					<Input
						inlineLabel={true}
						label="Start date"
						error={((startDate > endDate) || timeframeTooLong)}
						max={today}
						name="StartDate"
						onChange={handleDateChange}
						type="date"
						value={props.query.StartDate} />
					<Input
						inlineLabel={true}
						label="End date"
						error={((endDate < startDate) || timeframeTooLong)}
						max={today}
						name="EndDate"
						onChange={handleDateChange}
						type="date"
						value={props.query.EndDate} />
				</Flex>
			</Form>
		</div>
	);

};
