import api from "api.js";
import ActiveIcon from "Components/ActiveIcon.js";
import AdminPersonView from "./AdminPersonView.js";
import Checkbox from "Components/Checkbox.js";
import EditableList from "Components/EditableList.js";
import FamilyPicker from "Components/FamilyPicker.js";
import Flex from "Components/Flex.js";
import Input from "Components/Input.js";
import Link from "Components/Link.js";
import PeopleTypes from "Resources/PeopleTypes.js";
import PeopleTypeRadio from "Components/PeopleTypeRadio.js";
import PersonAlertsDialog from "./PersonAlertsDialog.js";
import Segment from "Components/Segment.js";
import TableList from "Components/TableList.js";
import TelInput from "Components/TelInput.js";
import Text from "Components/Text.js";
import libphonenumber from "libphonenumber-js";
import moment from "moment";
import scss from "./AdminPersonViewProperties.module.scss";
import useAuth from "Hooks/useAuth.js";
import useData from "Hooks/useData.js";
import useNavigator from "Hooks/useNavigator.js";
import useToggle from "Hooks/useToggle.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {createRef, useCallback, useEffect, useState} from "react";
import {Form, Loader, Ref} from "semantic-ui-react";

export default ({personId, personState}) => {

	const create = !personId;
	const formRef = createRef();
	const navigate = useNavigator();

	const {hasPermission} = useAuth();
	const hasActiveAlertsPermission = (hasPermission("PersonAlertsViewActive") || hasPermission("PersonAlertsManage"));
	const hasAllergiesPermission = hasPermission("ManageAllergiesData");
	const hasMedicalsPermission = hasPermission("ManageMedicalsData");

	const [editState, setEditState] = useState({
		Name: null,
		Family: null,
		Type: PeopleTypes.Parent,
		EmailAddresses: [{Email: ""}],
		PhoneNumbers: [{Phone: ""}],
		Dob: null,
		Notes: null,
		AllergyNotes: null,
		MedicalNotes: null,
		PhotoConsent: false
	});

	const [allowContactDetailsAutoFocus, setAllowContactDetailsAutoFocus] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	/** Alerts dialog */
	const alertsDialogState = useToggle();
	const [hasDismissedAlerts, setHasDismissedAlerts] = useState(false);

	/** Get our alerts */
	const personAlertsState = useData(useCallback(
		() => {
			if (hasActiveAlertsPermission && personId) {
				return api({url: `/people/${personId}/alerts/active`}).then(({data}) => data).catch(e => ToastStore.toast({message: `Error loading alerts: ${e}.`, type: "error"}));
			}
			else return null;
		},
		[hasActiveAlertsPermission, personId]
	));

	/** Person changed = need to reset alert state */
	useEffect(() => {
		setHasDismissedAlerts(false);
	}, [personId]);

	/** We've dismissed the alerts */
	useEffect(() => {
		if (alertsDialogState.value) {
			setHasDismissedAlerts(true);
		}
	}, [alertsDialogState, setHasDismissedAlerts]);

	/** Display the alerts dialog when we have alerts to show */
	useEffect(() => {
		if (hasActiveAlertsPermission && !hasDismissedAlerts && personAlertsState?.data?.length) {
			alertsDialogState.setTrue();
		}
	}, [alertsDialogState, hasActiveAlertsPermission, hasDismissedAlerts, personAlertsState]);

	/**
	 * Adding an email address.
	 *
	 * @return {void}
	 */
	const handleAddEmailAddress = () => {

		setEditState({
			...editState,
			EmailAddresses: [
				...editState.EmailAddresses,
				{Email: ""}
			]
		});

		/** We want the new input to be autofocused */
		setAllowContactDetailsAutoFocus(true);

	};

	/**
	 * Changing an email address.
	 *
	 * @param {String} value
	 * @param {Integer} index
	 * @return {void}
	 */
	const handleChangeEmailAddress = (value, index) => {
		const EmailAddresses = [...editState.EmailAddresses];
		EmailAddresses[index] = {...EmailAddresses[index], Email: value};
		setEditState({...editState, EmailAddresses});
	};

	/**
	 * Deleting an email address.
	 *
	 * @param {Object} email
	 * @return {void}
	 */
	const handleDeleteEmailAddress = email => {
		setEditState({
			...editState,
			EmailAddresses: editState.EmailAddresses.filter(e => (e !== email))
		});
	};

	/**
	 * Adding a phone number.
	 *
	 * @return {void}
	 */
	const handleAddPhoneNumber = () => {

		setEditState({
			...editState,
			PhoneNumbers: [
				...editState.PhoneNumbers,
				{Phone: ""}
			]
		});

		/** We want the new input to be autofocused */
		setAllowContactDetailsAutoFocus(true);

	};

	/**
	 * Changing a phone number.
	 *
	 * @param {String} value
	 * @param {Integer} index
	 * @return {void}
	 */
	const handleChangePhoneNumber = (value, index) => {
		const PhoneNumbers = [...editState.PhoneNumbers];
		PhoneNumbers[index] = {...PhoneNumbers[index], Phone: value};
		setEditState({...editState, PhoneNumbers});
	};

	/**
	 * Deleting a phone number.
	 *
	 * @param {Object} phone
	 * @return {void}
	 */
	const handleDeletePhoneNumber = phone => {
		setEditState({
			...editState,
			PhoneNumbers: editState.PhoneNumbers.filter(p => (p !== phone))
		});
	};

	/**
	 * Editing.
	 *
	 * @return {void}
	 */
	const handleEditingStart = () => {
		setAllowContactDetailsAutoFocus(false);
		setIsEditing(true);
		setEditState({...personState.data});
	};

	/**
	 * Cancelling editing.
	 *
	 * @return {void}
	 */
	const handleEditingCancel = () => {
		setIsEditing(false);
	};

	/**
	 * Edited a property.
	 *
	 * @param {mixed} value
	 * @param {String} name
	 * @return {void}
	 */
	const handleEditChange = (value, name) => {
		setEditState({...editState, [name]: value});
	};

	/**
	 * Submitting creation/edit.
	 *
	 * @async
	 * @return {void}
	 */
	const handleEditSubmit = async () => {

		if (!formRef?.current?.reportValidity?.()) {
			return;
		}

		const emails = editState.EmailAddresses.map(e => e.Email).filter(e => e);
		const phones = editState.PhoneNumbers.map(p => p.Phone).filter(p => p);

		setIsSubmitting(true);

		try {

			const result = await api({
				url: (create ? `/people` : `/people/${personState.data.Id}`),
				method: (create ? "POST" : "PUT"),
				data: {
					Name: editState.Name,
					Family: (editState.Family?.Id || null),
					Type: PeopleTypes.getCaseFromValue(editState.Type),
					EmailAddresses: emails,
					PhoneNumbers: phones,
					Dob: editState.Dob,
					Notes: editState.Notes,
					AllergyNotes: (hasAllergiesPermission ? editState.AllergyNotes : undefined),
					MedicalNotes: (hasMedicalsPermission ? editState.MedicalNotes : undefined),
					PhotoConsent: editState.PhotoConsent
				}
			});

			if (create) {
				personState.setLoading(true);
				navigate(`/admin/people/${result.data.Id}`);
			}
			else {
				setIsEditing(false);
				personState.setData(result.data);
			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	};

	/**
	 * Render the alerts dialog.
	 *
	 * @return {ReactNode}
	 */
	const renderAlertsDialog = () => (
		<PersonAlertsDialog
			alerts={personAlertsState?.data}
			onClose={alertsDialogState.setFalse}
			open={alertsDialogState.value}
			person={personState?.data} />
	);

	/**
	 * Render the allergy notes section.
	 *
	 * @return {ReactNode}
	 */
	const renderAllergies = () => (
		<Segment label="Allergy Notes">
			{(
				!(isEditing || create) ?
					(
						<Text content={(personState?.data?.AllergyNotes || "(None Recorded)")} />
					) :
					(
						<Input
							control="textarea"
							disabled={isSubmitting}
							name="AllergyNotes"
							maxLength={65535}
							onChange={handleEditChange}
							placeholder="Allergy Notes"
							rows={5}
							value={editState.AllergyNotes} />
					)
			)}
		</Segment>
	);

	/**
	 * Render the medical notes section.
	 *
	 * @return {ReactNode}
	 */
	const renderMedicals = () => (
		<Segment label="Medical Notes">
			{(
				!(isEditing || create) ?
					(
						<Text content={(personState?.data?.MedicalNotes || "(None Recorded)")} />
					) :
					(
						<Input
							control="textarea"
							disabled={isSubmitting}
							name="MedicalNotes"
							maxLength={65535}
							onChange={handleEditChange}
							placeholder="Medical Notes"
							rows={5}
							value={editState.MedicalNotes} />
					)
			)}
		</Segment>
	);

	/** Render! */
	return (
		<AdminPersonView
			actionIcon={(!(isEditing || create) ? "edit" : "check")}
			actionLabel={((!isEditing && !create) ? "Edit" : "Save")}
			actionsLoading={isSubmitting}
			onAction={(!(isEditing || create) ? handleEditingStart : handleEditSubmit)}
			onActionCancel={(isEditing && !create && handleEditingCancel)}
			personId={personId}
			state={personState}>
			{(personAlertsState.loading && <Loader active={true} />)}
			<Ref innerRef={formRef}>
				<Form>
					<TableList
						data={[
							{
								label: "Name",
								render: () => personState?.data?.Name,
								renderEditor: () => (
									<Input
										autoFocus={true}
										disabled={isSubmitting}
										name="Name"
										maxLength={255}
										onChange={handleEditChange}
										required={true}
										value={editState.Name} />
								)
							},
							{
								label: "Family",
								render: () => <Link label={(personState?.data?.Family?.Name || "Not Set")} uri={(personState?.data?.Family ? `/admin/families/${personState?.data.Family.Id}` : null)} />,
								renderEditor: () => (
									<FamilyPicker
										clearable={true}
										disabled={isSubmitting}
										name="Family"
										onChange={handleEditChange}
										value={editState.Family} />
								)
							},
							{
								label: "Type",
								render: () => PeopleTypes.getCaseFromValue(personState?.data?.Type),
								renderEditor: () => (
									<PeopleTypeRadio
										disabled={isSubmitting}
										name="Type"
										onChange={handleEditChange}
										value={editState.Type} />
								)
							},
							{
								label: "Date of Birth",
								render: () => (personState?.data?.Dob ? (new moment(personState?.data.Dob)).format("DD/MM/YYYY") : "Not Stored"),
								renderEditor: () => (
									<Input
										disabled={isSubmitting}
										max={(new moment()).format("YYYY-MM-DD")}
										name="Dob"
										onChange={handleEditChange}
										type="date"
										value={editState.Dob} />
								)
							},
							{
								label: "Photo Consent",
								render: () => <ActiveIcon active={personState?.data?.PhotoConsent} />,
								renderEditor: () => (
									<Checkbox
										checkbox={true}
										disabled={isSubmitting}
										name="PhotoConsent"
										onChange={handleEditChange}
										value={editState.PhotoConsent} />
								)
							}
						]}
						editWithoutForm={true}
						isEditing={(isEditing || create)} />
					<Flex gap={2} mt={2}>
						<Segment
							disableContentOutline={true}
							label="Contact Details">
							<TableList
								className={scss.contactDetailsTable}
								data={[
									{
										label: "Contact Emails",
										render() {

											const emails = (!(isEditing || create) ? personState?.data?.EmailAddresses : editState.EmailAddresses);

											if (!emails?.length && !(isEditing || create)) {
												return "None";
											}

											return (
												<EditableList
													className={scss.contactDetailsEditors}
													disabled={isSubmitting}
													editing={(isEditing || create)}
													items={emails}
													onNew={handleAddEmailAddress}
													onDelete={handleDeleteEmailAddress}
													renderItem={
														email => (
															<a href={`mailto:${email.Email}`}>
																{email.Email}
															</a>
														)
													}
													renderItemEditor={
														(email, key) => (
															<Input
																autoFocus={(allowContactDetailsAutoFocus && !email.Email)}
																disabled={isSubmitting}
																maxLength={255}
																name={key}
																onChange={handleChangeEmailAddress}
																placeholder="Email address"
																required={!!email.Email}
																type="email"
																value={email.Email} />
														)
													} />
											);

										}
									},
									{
										label: "Contact Phones",
										render() {

											const phones = (!(isEditing || create) ? personState?.data?.PhoneNumbers : editState.PhoneNumbers);

											if (!phones?.length && !(isEditing || create)) {
												return "None";
											}

											return (
												<EditableList
													className={scss.contactDetailsEditors}
													disabled={isSubmitting}
													editing={(isEditing || create)}
													items={phones}
													onNew={handleAddPhoneNumber}
													onDelete={handleDeletePhoneNumber}
													renderItem={
														phone => (
															<a href={`tel:${phone.Phone}`}>
																{libphonenumber(phone.Phone, "GB").formatNational()}
															</a>
														)
													}
													renderItemEditor={
														(phone, key) => (
															<TelInput
																autoFocus={(allowContactDetailsAutoFocus && !phone.Phone)}
																disabled={isSubmitting}
																name={key}
																onChange={handleChangePhoneNumber}
																required={!!phone.Phone}
																value={phone.Phone} />
														)
													} />
											);

										}
									}
								]}
								editWithoutForm={true}
								isEditing={(isEditing || create)} />
						</Segment>
						<Segment label="Notes">
							{(
								!(isEditing || create) ?
									(
										<Text content={(personState?.data?.Notes || "(None)")} />
									) :
									(
										<Input
											control="textarea"
											disabled={isSubmitting}
											name="Notes"
											maxLength={65535}
											onChange={handleEditChange}
											rows={5}
											value={editState.Notes} />
									)
							)}
						</Segment>
						{(hasAllergiesPermission && renderAllergies())}
						{(hasMedicalsPermission && renderMedicals())}
					</Flex>
				</Form>
			</Ref>
			{(hasActiveAlertsPermission && !!personAlertsState?.data?.length && renderAlertsDialog())}
		</AdminPersonView>
	);

};
