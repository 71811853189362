import Dialog from "Components/Dialog.js";
import SigninSubmissionErrorsList from "./SigninSubmissionErrorsList.js";

export default props => {
	return (
		<Dialog
			header="Submission error"
			onClose={props.onClose}
			open={props.open}>
			<SigninSubmissionErrorsList
				result={props.result} />
		</Dialog>
	);
};
