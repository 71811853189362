import scss from "./Flex.module.scss";
import {useCallback} from "react";

export default props => {

	const rem = useCallback((multi=1) => `${multi}rem`, []);

	const undefinedOr = useCallback((v, fb) => ((v !== undefined) ? v : fb), []);

	const defaultGap = undefinedOr(props.gap, 1);

	const classes = [];

	if (props.className) {
		classes.push(props.className);
	}

	if (props.removeContentMargins) {
		classes.push(scss.removeContentMargins);
	}

	const style = {
		alignContent: props.alignContent,
		alignItems: props.alignItems,
		columnGap: rem(undefinedOr(props.columnGap, defaultGap)),
		display: (props.display || "flex"),
		flexDirection: (!props.columnar ? "column" : "row"),
		flexWrap: (props.wrap ? "wrap" : undefined),
		height: props.height,
		justifyContent: props.justifyContent,
		justifyItems: props.justifyItems,
		marginBottom: rem((undefinedOr(props.mb, undefinedOr(props.my, 0)))),
		marginTop: rem((undefinedOr(props.mt, undefinedOr(props.my, 0)))),
		marginLeft: rem((undefinedOr(props.ml, undefinedOr(props.mx, 0)))),
		marginRight: rem((undefinedOr(props.mr, undefinedOr(props.mx, 0)))),
		paddingBottom: rem((undefinedOr(props.pb, undefinedOr(props.py, 0)))),
		paddingTop: rem((undefinedOr(props.pt, undefinedOr(props.py, 0)))),
		paddingLeft: rem((undefinedOr(props.pl, undefinedOr(props.px, 0)))),
		paddingRight: rem((undefinedOr(props.pr, undefinedOr(props.px, 0)))),
		rowGap: rem(undefinedOr(props.rowGap, defaultGap)),
		width: props.width,
		...props.style
	};

	return (
		<div
			className={classes.join(" ")}
			ref={props.innerRef}
			style={style}>
			{props.children}
		</div>
	);

};
