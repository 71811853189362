import scss from "./TableList.module.scss";
import Flex from "Components/Flex.js";
import Text from "Components/Text.js";
import {useCallback} from "react";
import {Form, Ref, Table} from "semantic-ui-react";

export default ({className, data, editWithoutForm, formRef, nestedTables, onEditFormSubmit, isEditing}) => {

	if (!nestedTables) {
		data = [data];
	}

	const classes = [scss.table];

	if (className) {
		classes.push(className);
	}

	const handleFormSubmit = useCallback(e => {
		e?.preventDefault?.();
		e?.stopPropagation?.();
		onEditFormSubmit?.();
	}, [onEditFormSubmit]);

	const table = data.map((data, key) => (
		<Table
			className={classes.join(" ")}
			definition={true}
			key={key}>
			<Table.Body>
				{
					data.filter(f => !f.hidden).map((field, key) => (
						<Table.Row key={key}>
							<Table.Cell className={scss.label}>
								{field.label}
								{(field.subtext && <Text className={scss.subtext} color="secondary" content={field.subtext} />)}
							</Table.Cell>
							<Table.Cell>
								<Flex gap={((field.cellGap !== undefined) ? field.cellGap : 0.5)} style={field.cellStyles}>
									{(isEditing && field.retainContentWhenEditing && field.renderEditor && field.render())}
									{(!isEditing ? field.render() : (field.renderEditor?.() || field.render()))}
								</Flex>
							</Table.Cell>
						</Table.Row>
					))
				}
			</Table.Body>
		</Table>
	));

	return ((!isEditing || editWithoutForm) ? table : <Ref innerRef={formRef}><Form onSubmit={handleFormSubmit}>{table}</Form></Ref>);

};
