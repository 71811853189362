import FamilyPicker from "Components/FamilyPicker.js";
import Flex from "Components/Flex.js";
import Dialog from "Components/Dialog.js";
import RegistrationViewFamilyMemberForm from "Registration/RegistrationViewFamilyMemberForm.js";
import RegistrationViewFamilyMemberFormInitialState from "Registration/RegistrationViewFamilyMemberFormInitialState.js";
import RegistrationViewScss from "Registration/RegistrationView.module.scss";
import recordRegistration from "Registration/RecordRegistration.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {Form, Ref} from "semantic-ui-react";
import {createRef, useCallback, useEffect, useState} from "react";

export default props => {

	const formRef = createRef();
	const {onClose, onRegistered, open} = props;

	const [family, setFamily] = useState(null);
	const [state, setState] = useState(RegistrationViewFamilyMemberFormInitialState());
	const [isSubmitting, setIsSubmitting] = useState(false);

	/**
	 * Submitting!
	 * 
	 * @async
	 * @return {void}
	 */
	const handleSubmit = useCallback(async () => {

		if (!formRef?.current?.reportValidity?.()) {
			return;
		}

		setIsSubmitting(true);

		try {

			const result = await recordRegistration((family?.Id || null), [{...state}]);

			const member = result.Members?.[0];
			const error = result.MemberErrors?.[0];

			if (member && (error === undefined)) {
				onClose();
				onRegistered(member.Person);
			}
			else {
				ToastStore.error(`Submission error: ${(error || "(Unknown)")}.`);
			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	}, [family, formRef, onClose, onRegistered, state]);

	/**
	 * Form submit handler.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	const handleFormSubmit = useCallback(e => {
		e.preventDefault();
		handleSubmit();
	}, [handleSubmit]);

	/** Reset the state after open/close */
	useEffect(() => {
		if (!open) {
			setIsSubmitting(false);
			setFamily(null);
			setState(RegistrationViewFamilyMemberFormInitialState());
		}
	}, [open]);

	/** Render! */
	return (
		<Dialog
			className={RegistrationViewScss.form}
			header="Register Visitor"
			loading={isSubmitting}
			onClose={onClose}
			onOk={handleSubmit}
			open={open}
			size="large">
			<Ref innerRef={formRef}>
				<Form onSubmit={handleFormSubmit}>
					<Flex gap={1.5}>
						<Form.Field>
							<FamilyPicker
								clearable={true}
								disabled={isSubmitting}
								onChange={setFamily}
								value={family} />
						</Form.Field>
						<RegistrationViewFamilyMemberForm
							allowDelete={false}
							autoFocus={true}
							data={state}
							disabled={isSubmitting}
							index={0}
							inline={true}
							onChange={setState}
							required={true} />
					</Flex>
				</Form>
			</Ref>
		</Dialog>
	);

};
