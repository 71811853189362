import useAuth from "Hooks/useAuth.js";
import MenuItems from "Ui/AppBarMenuItems.js";

export default () => {

	const {hasPermission} = useAuth();

	const items = MenuItems.filter(i => {
		const permission = i.permission;
		return (!permission || hasPermission(permission));
	});

	return items;

};
