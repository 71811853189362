import ActiveIcon from "Components/ActiveIcon.js";
import DataTable from "Components/DataTable.js";
import Link from "Components/Link.js";
import PeopleTypes from "Resources/PeopleTypes.js";
import useAuth from "Hooks/useAuth.js";
import useViewport from "Hooks/useViewport.js";

export default props => {

	const {hasPermission} = useAuth();

	const {width: viewportWidth} = useViewport();
	const hideAuxiliaryColumns = (viewportWidth < 1080);

	const columns = [
		{
			id: "Name",
			render: i => <Link label={i.Name} uri={`/admin/people/${i.Id}`} />,
			sortable: true,
			width: ((viewportWidth < 1150) ? 2 : 4)
		},
		{
			id: "Family",
			render: i => <Link label={(i.Family?.Name || "Not Set")} uri={(i.Family ? `/admin/families/${i.Family.Id}` : null)} />,
			sortable: true,
			width: 2
		},
		{
			id: "Type",
			render: i => PeopleTypes.getCaseFromValue(i.Type),
			width: 2,
			hidden: (viewportWidth < 780)
		},
		{
			id: "HasNotes",
			label: "Notes",
			render: i => <ActiveIcon active={!!i.Notes} />,
			width: 2,
			hidden: hideAuxiliaryColumns,
			filter: {
				labels: {
					true: "With Notes",
					false: "Without Notes"
				},
				value: props.query.HasNotes,
				defaultValue: null,
				sortTrueFirst: true
			}
		},
		{
			id: "HasAllergyNotes",
			label: "Allergy Notes",
			render: i => <ActiveIcon active={!!i.AllergyNotes} />,
			width: 2,
			hidden: (hideAuxiliaryColumns || !hasPermission("ManageAllergiesData")),
			filter: {
				labels: {
					true: "With Allergy Notes",
					false: "Without Allergy Notes"
				},
				value: props.query.HasAllergyNotes,
				defaultValue: null,
				sortTrueFirst: true
			}
		},
		{
			id: "HasMedicalNotes",
			label: "Medical Notes",
			render: i => <ActiveIcon active={!!i.MedicalNotes} />,
			width: 2,
			hidden: (hideAuxiliaryColumns || !hasPermission("ManageMedicalsData")),
			filter: {
				labels: {
					true: "With Medical Notes",
					false: "Without Medical Notes"
				},
				value: props.query.HasMedicalNotes,
				defaultValue: null,
				sortTrueFirst: true
			}
		},
		{
			id: "PhotoConsent",
			label: "Photo Consent",
			render: i => <ActiveIcon active={i.PhotoConsent} />,
			width: 2,
			hidden: hideAuxiliaryColumns,
			filter: {
				labels: {
					true: "Consented",
					false: "Not consented"
				},
				value: props.query.PhotoConsent,
				defaultValue: null,
				sortTrueFirst: true
			}
		}
	];

	return (
		<DataTable
			columns={columns}
			definition={true}
			hiddenColumns={props.hiddenColumns}
			onQueryChange={props.onQueryChange}
			query={props.query}
			state={props.state}
			stickyHeader={props.stickyHeader} />
	);

};
