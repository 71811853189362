import api from "api.js";
import useAdminViewport from "Admin/useAdminViewport.js";
import useData from "Hooks/useData.js";
import useQuery from "Hooks/useQuery.js";
import useViewport from "Hooks/useViewport.js";
import AdminAttendanceTable from "Admin/Attendance/AdminAttendanceTable.js";
import AdminPersonView from "./AdminPersonView.js";
import {useCallback, useMemo} from "react";

export default ({personId, personState}) => {

	const {computeBodyTopOffset} = useAdminViewport();
	const {width: viewportWidth} = useViewport();

	const query = useQuery(useMemo(() => ({
		Limit: 10,
		Page: 1,
		Sort: "Date",
		SortDirection: "Desc"
	}), []));

	const state = useData(useCallback(
		() => {

			const personId = personState?.data?.Id;

			if (personId) {
				return api({url: `/attendance`, params: {...query.query, Person: personId}}).then(({data}) => data);
			}
			else return null;

		},
		[personState, query.query]
	), true);

	return (
		<AdminPersonView
			personId={personId}
			state={personState}>
			<AdminAttendanceTable
				columnWidthOverrides={{Date: ((viewportWidth < 850) ? 3 : 1), Session: 7}}
				hiddenColumns={["Person", "Family", "Type"]}
				onQueryChange={query.setQuery}
				query={query.query}
				state={state}
				stickyHeader={computeBodyTopOffset({hasViewTabs: true})} />
		</AdminPersonView>
	);

};
