import api from "api.js";
import useData from "Hooks/useData.js";
import useQuery from "Hooks/useQuery.js";
import AdminPersonView from "./AdminPersonView.js";
import AdminPersonViewAlertComposer from "./AdminPersonViewAlertComposer.js";
import AdminPersonViewAlertFeed from "./AdminPersonViewAlertFeed.js";
import Flex from "Components/Flex.js";
import Hidden from "Components/Hidden.js";
import Loadable from "Components/Loadable.js";
import Pagibar from "Components/Pagibar.js";
import scss from "./AdminPersonViewAlerts.module.scss";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Loader} from "semantic-ui-react";

export default ({personId, personState}) => {

	const [isCreate, setIsCreate] = useState(false);

	const query = useQuery(useMemo(() => ({
		Page: 1,
		Limit: 25
	}), []));

	const state = useData(useCallback(
		() => {

			const personId = personState?.data?.Id;

			if (personId) {
				return api({url: `/people/${personId}/alerts`, params: query.query}).then(({data}) => data);
			}
			else return null;

		},
		[personState, query.query]
	));

	/**
	 * A new alert was created.
	 * 
	 * @return {void}
	 */
	const handleCreated = alert => {

		handleCreationToggle();

		if (query.query.Page === 1) {
			state.setData({
				...state.data,
				Objects: [
					alert,
					...state.data.Objects
				],
				Count: (state.data.Count + 1)
			});
		}
		else query.setQuery({...query.query, Page: 1});

	};

	/**
	 * An alert was updated.
	 *
	 * @param {Object} updated
	 * @return {void}
	 */
	const handleUpdated = updated => {

		const objects = state.data?.Objects;
		if (!objects) return;

		const index = objects.indexOf(objects.find(o => (o.Id === updated.Id)));

		if (index > -1) {
			objects[index] = updated;
		}

		state.setData({...state.data, Objects: objects});

	};

	/**
	 * An alert was deleted.
	 *
	 * @param {Object} alert
	 * @return {void}
	 */
	const handleDeleted = alert => {

		let objects = state.data?.Objects;

		if (objects) {
			objects = objects.filter(a => (a !== alert));
			state.setData({...state.data, Objects: objects});
		}

	};

	/**
	 * Toggling the creation state.
	 *
	 * @return {void}
	 */
	const handleCreationToggle = () => {
		setIsCreate(!isCreate);
	};

	/**
	 * Page changed.
	 *
	 * @param {Integer} Page
	 * @return {void}
	 */
	const handlePageChange = Page => {
		query.setQuery({...query.query, Page});
	};

	/** Scroll to top on query change */
	useEffect(() => {
		window.scrollTo({top: 0});
	}, [query.query]);

	/** Scroll to top on switch to creation mode */
	useEffect(() => {
		if (isCreate) {
			window.scrollTo({top: 0});
		}
	}, [isCreate]);

	/** Total numer of pages */
	const totalPages = Math.ceil(((state?.data?.Count || 0) / query.query.Limit));

	/** Render! */
	return (
		<AdminPersonView
			personId={personId}
			state={personState}>
			<Loadable
				error={state.error}
				loading={(state.loading && !state.data)}
				onErrorRetry={state.fetch}>
				<Flex
					gap={2}
					removeContentMargins={true}>
					<Hidden hidden={isCreate}>
						<div>
							<Button
								content="New"
								icon="add"
								onClick={handleCreationToggle}
								primary={true} />
						</div>
					</Hidden>
					<Hidden hidden={!isCreate}>
						<AdminPersonViewAlertComposer
							onCancel={handleCreationToggle}
							onCreate={handleCreated}
							person={personState.data} />
					</Hidden>
					<AdminPersonViewAlertFeed
						data={state.data}
						disabled={state.loading}
						emptyMessage={((state.data && (query.query.Page > totalPages)) ? "No results." : undefined)}
						onDeleted={handleDeleted}
						onUpdated={handleUpdated} />
					<Pagibar
						activePage={query.query.Page}
						disabled={state.loading}
						className={scss.pagibar}
						onPageChange={handlePageChange}
						totalPages={totalPages} />
				</Flex>
				<Loader active={state.loading} />
			</Loadable>
		</AdminPersonView>
	);

};
