import {useEffect} from "react";
import {useLocation} from "react-router-dom";

/**
 * Navigation listener
 * 
 * Resets the scroll position on URI change.
 * 
 * @return {ReactNode}
 */
export default () => {

	const location = useLocation();

	useEffect(() => {
		window.scrollTo({top: 0});
	}, [location.pathname]);

	return null;

};
