import Flex from "Components/Flex.js";
import scss from "./EditableList.module.scss";
import {Button, List} from "semantic-ui-react";

export default props => {

	return (
		<List className={props.className}>
			{
				props.items?.map?.((item, key) => {

					const classes = [scss.item];
					if (props.editing) classes.push(scss.editing);

					return (
						<List.Item key={key}>
							<Flex
								alignItems="center"
								className={classes.join(" ")}
								columnar={true}
								gap={0.5}
								justifyContent="space-between">
								{(!props.editing ? props.renderItem(item, key) : (props.renderItemEditor ? props.renderItemEditor(item, key) : props.renderItem(item, key)))}
								{(props.editing && <Button disabled={props.disabled} icon="trash alternate" onClick={() => props.onDelete(item)} type="button" />)}
							</Flex>
						</List.Item>
					);

				})
			}
			<List.Item>
				{(props.editing && <Flex alignItems="flex-start" mt={0.25}><Button content="Add" disabled={props.disabled} icon="add" onClick={props.onNew} type="button" /></Flex>)}
			</List.Item>
		</List>
	);

};
