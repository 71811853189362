import ErrorFallback from "./ErrorFallback.js";
import * as Sentry from "@sentry/react";

export default ({children, fallback, inlineFallback, noMargin, noTitle, noRefresh, paddedFallback}) => (
	<Sentry.ErrorBoundary
		fallback={(
			(fallback !== undefined) ?
				fallback :
				(
					<ErrorFallback
						noMargin={noMargin}
						noTitle={(inlineFallback || noTitle)}
						noRefresh={(inlineFallback || noRefresh)}
						padded={paddedFallback} />
				)
		)}>
		{children}
	</Sentry.ErrorBoundary>
);
