import Flex from "Components/Flex.js";
import scss from "./RegistrationViewSuccessStep.module.scss";
import useNavigator from "Hooks/useNavigator.js";
import {Button, Header, Icon} from "semantic-ui-react";

export default props => {

	const {hasErrors} = props;
	const navigate = useNavigator();

	const highlightColour = (!hasErrors ? "green" : "orange");

	return (
		<Flex
			alignItems="center"
			gap={0}>
			<Icon
				className={scss.icon}
				color={highlightColour}
				name={(!hasErrors ? "check circle outline" : "warning")}
				size="huge" />
			<Header
				as="p"
				color={highlightColour}
				content={props.header}
				size="huge" />
			<p><strong>{props.caption}</strong></p>
			<div className={scss.content}>
				{props.children}
			</div>
			<Flex mt={1}>
				{props.renderActions?.()}
				<Button
					basic={true}
					content="New Registration"
					icon="add"
					onClick={() => navigate("/registration")}
					size="large" />
			</Flex>
		</Flex>
	);

};
