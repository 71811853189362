import {useCallback, useState} from "react";

/**
 * A hook that's designed to manage the state for `<details>` components 
 * that handle a `<textarea>`, such as Notes fields on forms.
 *
 * The use case is that the component
 *  - should never change `open` on the `<details>`, after the initial 
 *    state, because it doesn't work properly as a controlled component 
 *    due to conflicts with the element's internal state which cause 
 *    open/close events to continually fire after each other, and
 *  - wants to focus the `<textarea>` each time the `<accordion>` is 
 *    toggled into the open state.
 *
 * @param {Boolean} optional initialOpen (`false`)
 * @param {Ref} optional textareaRef Ref to the `<textarea>` element
 * @return {Object}
 */
export default (initialOpen=false, textareaRef=null) => {

	const [open, setOpen] = useState(initialOpen);

	const toggle = useCallback(e => {

		e.preventDefault();

		const newState = !open;
		setOpen(newState);

		if (newState) {
			textareaRef?.current?.querySelector?.("textarea")?.focus?.();
		}

	}, [open, textareaRef]);

	return {
		open: initialOpen,
		currentlyOpen: open,
		toggle
	};

};
