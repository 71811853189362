import scss from "./ButtonMenu.module.scss";
import useNavigator from "Hooks/useNavigator.js";
import {Button, Icon} from "semantic-ui-react";

export default props => {

	const navigate = useNavigator();

	return (
		<nav className={scss.root}>
			{
				props.items.map((i, key) => {

					const loading = (props.loading || i.loading);

					return (
						<Button
							basic={props.basic}
							color="blue"
							className={scss.button}
							disabled={(props.disabled || i.disabled || loading)}
							key={key}
							loading={loading}
							onClick={(props.onClick ? () => props.onClick(i) : () => navigate(i.uri))}
							size="huge">
							{(i.icon && <Icon name={i.icon} />)}
							{i.label}
						</Button>
					);

				})
			}
		</nav>
	);

};
