import Appv from "Components/Appv.js";
import Flex from "Components/Flex.js";
import Link from "Components/Link.js";
import ErrorBoundary from "App/Errors/ErrorBoundary.js";
import PasswordChangeForm from "./PasswordChangeForm.js";
import RefreshUserProfile from "Tasks/RefreshUserProfile.js";
import TotpSettingsSection from "./TotpSettingsSection.js";
import dLogout from "Dispatchers/dLogout.js";
import scss from "./ProfileView.module.scss";
import useAuth from "Hooks/useAuth.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useSelector} from "react-redux";
import {Button, Container, Divider, Header, Segment} from "semantic-ui-react";

export default () => {

	const {authUser} = useAuth();
	const userProfileDataUpdating = useSelector(store => store.userProfileDataUpdating);

	const handleProfileRefresh = async () => {

		try {
			await RefreshUserProfile({throwOnError: true});
			ToastStore.success("Your profile data has been refreshed.");
		}
		catch (e) {
			ToastStore.error(e);
		}

	};

	return (
		<Container text={true}>
			<Segment>
				<header className={scss.header}>
					<Flex alignItems="flex-start" gap={0}>
						<Header as="h1" color="blue">{authUser.Name}</Header>
						<p className={scss.email}>{authUser.Email}</p>
						<Link label="Logout" onClick={dLogout} />
					</Flex>
					<Flex columnar={true} gap={0}>
						<Button
							disabled={userProfileDataUpdating}
							icon="refresh"
							loading={userProfileDataUpdating}
							title="Refresh Profile Data"
							onClick={handleProfileRefresh} />
					</Flex>
				</header>
			</Segment>
			<Divider hidden={true} />
			<Segment>
				<Header as="h2" content="Password" />
				<ErrorBoundary inlineFallback={true}>
					<PasswordChangeForm />
				</ErrorBoundary>
			</Segment>
			<Divider hidden={true} />
			<Segment>
				<Header as="h2" content="Two-Factor Authentication" />
				<ErrorBoundary inlineFallback={true}>
					<TotpSettingsSection />
				</ErrorBoundary>
			</Segment>
			<Segment basic={true}>
				<Appv label={true} showUnknown={true} />
			</Segment>
		</Container>
	);

};
