import qs from "query-string";
import useNavigator from "./useNavigator.js";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useLocation} from "react-router-dom";

export default (defaultQuery, typeMap={}) => {

	const navigate = useNavigator();
	const location = useLocation();
	const search = location.search;

	const getQueryKeyType = useCallback(key => {
		return (typeMap[key] || (typeof defaultQuery[key]));
	}, [defaultQuery, typeMap]);

	/** Compute the current query from the query string */
	const resolveQuery = useCallback(() => {

		const currentQuery = qs.parse(search);
		const defaultQueryKeys = Object.keys(defaultQuery);

		const resolvedQuery = {};

		for (const key in currentQuery) {
			if (defaultQueryKeys.includes(key)) {

				let val = currentQuery[key];

				if (getQueryKeyType(key) === "boolean") {
					if (val === "null") {
						val = null;
					}
					else if (val === "1") {
						val = true;
					}
					else if (val === "0") {
						val = false;
					}
					else val = undefined;
				}
				if (getQueryKeyType(key) === "number") {
					val = parseInt(val);
				}

				resolvedQuery[key] = val;

			}
		}

		for (const key of defaultQueryKeys) {
			if (resolvedQuery[key] === undefined) {
				resolvedQuery[key] = defaultQuery[key];
			}
		}

		return resolvedQuery;

	}, [search, defaultQuery, getQueryKeyType]);


	/** Initial query */
	const initialQuery = useRef(resolveQuery());

	/** Previous query (initial query on first mount) */
	const prevQuery = useRef(initialQuery.current);

	/** Currently resolved query */
	const [resolvedQuery, setResolvedQuery] = useState(initialQuery.current);


	/** Update the query string */
	const setQuery = useCallback(query => {

		const newQuery = {};
		const changedProps = [];

		for (const key in query) {
			if (query[key] !== resolvedQuery[key]) {
				changedProps.push(key);
			}
		}

		if (defaultQuery.Page && !changedProps.every(p => (["Page", "Sort", "SortDirection"].includes(p)))) {
			query.Page = 1;
		}

		for (const key in query) {

			if (query.hasOwnProperty(key)) {

				if (query[key] !== defaultQuery[key]) {

					let val = query[key];

					if (getQueryKeyType(key) === "boolean") {
						val = ((val === null) ? "null" : (val ? 1 : 0));
					}

					newQuery[key] = val;

				}

			}

		}

		const queryStr = qs.stringify(newQuery);
		navigate(`${location.pathname}${(queryStr ? `?${queryStr}` : "")}`);

	}, [location.pathname, navigate, defaultQuery, resolvedQuery, getQueryKeyType]);


	/** We've resolved a new query */
	const commitQueryUpdate = useCallback((newQuery, currentQuery) => {

		/** Update the previous query to the current query */
		prevQuery.current = currentQuery;

		/** Set the new query */
		setResolvedQuery(newQuery);

	}, []);


	/** Recompute query whenever the query string changes */
	useEffect(() => {

		const resolved = resolveQuery();

		if (qs.stringify(resolved) !== qs.stringify(resolvedQuery)) {
			commitQueryUpdate(resolved, resolvedQuery);
		}

	}, [resolveQuery, commitQueryUpdate, resolvedQuery, prevQuery]);


	/** We're done! */
	return useMemo(() => ({
		query: resolvedQuery,
		initialQuery: initialQuery.current,
		setQuery
	}), [resolvedQuery, setQuery]);

};
