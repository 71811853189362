import Flex from "Components/Flex.js";
import ReferralTypes from "Resources/PeopleReferralTypes.js";
import {Label} from "semantic-ui-react";

export default ({referrals}) => (
	<Flex columnar={true} gap={0} mt={0.25} wrap={true}>
		{
			referrals.map(ref => ReferralTypes.getCaseFromValue(ref)).sort((a, b) => a.localeCompare(b)).map((ref, key) => {

				const label = ReferralTypes.toFriendlyCaseName(ref);

				return (
					<Label
						color="grey"
						content={label}
						key={key}
						size="small"
						title={`Referred to ${label} service`} />
				);

			})
		}
	</Flex>
);
