import api from "api.js";
import useAdminViewport from "Admin/useAdminViewport.js";
import useData from "Hooks/useData.js";
import useNavigator from "Hooks/useNavigator.js";
import AdminFamiliesTable from "./AdminFamiliesTable.js";
import AdminView from "Admin/AdminView.js";
import useQuery from "Hooks/useQuery.js";
import {useCallback, useMemo} from "react";

export default () => {

	const {computeBodyTopOffset} = useAdminViewport();
	const navigate = useNavigator();

	const query = useQuery(useMemo(() => ({
		Limit: 25,
		Page: 1,
		Search: null,
		Sort: "Name",
		SortDirection: "Asc"
	}), []));

	const handleQueryChange = useCallback(
		props => query.setQuery({...query.query, ...props}),
		[query]
	);

	const state = useData(useCallback(
		() => api({url: "/families", params: query.query}).then(({data}) => data),
		[query.query]
	), true);

	return (
		<AdminView
			actionIcon="add"
			actionLabel="New"
			initialSearch={query.initialQuery?.Search}
			loading={state.loading}
			onAction={() => navigate("/admin/families/new")}
			onSearchChange={Search => handleQueryChange({Search})}
			search={query.query.Search}
			searchable={true}
			title="Families">
			<AdminFamiliesTable
				onQueryChange={handleQueryChange}
				query={query.query}
				state={state}
				stickyHeader={computeBodyTopOffset({hasActions: true, hasSearch: true})} />
		</AdminView>
	);

};
