import ActiveIcon from "Components/ActiveIcon.js";
import Flags from "Resources/Flags.js";
import Flex from "Components/Flex.js";
import Input from "Components/Input.js";
import TableList from "Components/TableList.js";
import scss from "./F12View.module.scss";
import useAuth from "Hooks/useAuth.js";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Container, Divider, Form, Header, Segment} from "semantic-ui-react";
import {ToastStore} from "Toasts/ToastProvider.js";
import * as Sentry from "@sentry/react";

export default props => {

	const {isAuthed} = useAuth();
	const dispatch = useDispatch();

	const flags = useSelector(store => store.flags);
	const [flagsInput, setFlagsInput] = useState(flags.join(","));

	const handleCommitFlags = () => {
		dispatch({
			type: "update",
			data: {flags: (flagsInput?.split(",") || [])}
		});
	};

	return (
		<Container className={props.containerClassName} text={true}>
			{(!isAuthed && <Divider hidden={true} />)}
			{(!isAuthed && <a href="/"><img alt="Abingdon Carousel" className={scss.logo} src="/icon.png" /></a>)}
			{(!props.noHeader && <Header as="h1" content="Developer tools" />)}
			<p>The controls on this screen are intended for developer use only.</p>
			<p><strong>Changes can cause unpredictable application behaviour, data loss, and errors.</strong></p>
			<Divider hidden={true} />
			<Segment>
				<Header as="h2" content="Feature flags" />
				<Form>
					<Input
						control="textarea"
						placeholder="No flags enabled"
						onBlur={handleCommitFlags}
						onChange={setFlagsInput}
						rows={3}
						value={flagsInput} />
				</Form>
				<Flex gap={0} mt={1}>
					<p><strong>Known flags:</strong> {(Flags.join(", ") || "(None)")}</p>
					<p>List flags here to enable them, assigning values using equals signs. Separate multiple flags with commas.</p>
				</Flex>
			</Segment>
			<Divider hidden={true} />
			<Segment>
				<Header as="h2" content="Sentry integration" />
				<Button content="Capture test exception" onClick={() => Sentry.captureException(new Error("Sentry test."))} />
			</Segment>
			<Divider hidden={true} />
			<Segment>
				<Header as="h2" content="Toast notifications" />
				<Button content="Create toast notification" onClick={() => ToastStore.toast({message: "This is a toast notification.", header: "Toast notification", type: "success"})} />
			</Segment>
			<Divider hidden={true} />
			<Segment>
				<Header as="h2" content="Build information" />
				<TableList
					data={[
						{
							label: "App version",
							render: () => (process.env.REACT_APP_APP || "-")
						},
						{
							label: "API base URL",
							render: () => (process.env.REACT_APP_API || "-")
						},
						{
							label: "Sentry enabled",
							render: () => <ActiveIcon active={process.env.REACT_APP_SENTRY_DSN} />
						}
					]} />
			</Segment>
			{(!isAuthed && <Divider hidden={true} />)}
			{(!isAuthed && <a href="/">Login</a>)}
			{(!isAuthed && <Divider hidden={true} />)}
		</Container>
	);

};
