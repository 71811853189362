import useViewport from "Hooks/useViewport.js";

export default () => {

	const {width: viewportWidth} = useViewport();

	/** Render the toolbar in the mobile layout? */
	const useMobileToolbar = (viewportWidth <= 640);

	/** Render the tab strip in its "icon only" layout? */
	const tabsIconsOnly = (viewportWidth <= 780);

	/**
	 * Compute an effective `top` offset value relative to the 
	 * body content area of an admin view.
	 * 
	 * Designed for e.g. sticky positioning that aligns an element 
	 * to the apparent "top" of the view, below the toolbar components.
	 * 
	 * @param {String} optional options.top
	 * @param {Boolean} optional options.hasActions
	 * @param {Boolean} optional options.hasSearch
	 * @param {Boolean} optional options.hasViewTabs
	 * @return {Integer}
	 */
	const computeBodyTopOffset = ({top="13.6rem", hasActions=false, hasSearch=false, hasTabs=true, hasViewTabs=false}={}) => {

		let val = parseFloat(top.split("rem")[0]);

		if (useMobileToolbar) {
			val -= 3.65;
		}

		if (hasActions || hasSearch) {

			if (useMobileToolbar) {
				if (hasSearch) {
					val += 2.8;
				}
				if (hasActions) {
					val += 3.1;
				}
			}
			else val += 0.1;

		}

		if (!hasTabs) {
			val -= 4;
		}

		if (hasViewTabs) {
			val += (!useMobileToolbar ? 1.9 : 2.05);
		}

		return `${val}rem`;

	};

	return {
		computeBodyTopOffset,
		tabsIconsOnly,
		useMobileToolbar,
		width: viewportWidth
	};

};
