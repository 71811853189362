import recordSignin from "./RecordSignin.js";
import Flex from "Components/Flex.js";
import SigninSubmissionErrorDialog from "./SigninSubmissionErrorDialog.js";
import SigninSubmissionIncompleteDialog from "./SigninSubmissionIncompleteDialog.js";
import SigninSessionSelector from "./SigninSessionSelector.js";
import SigninVisitorSelector from "./SigninVisitorSelector.js";
import View from "Components/View.js";
import scss from "./SigninView.module.scss";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useCallback, useState} from "react";
import {Button, Divider, Header} from "semantic-ui-react";

export default () => {

	/** State */
	const [state, setState] = useState({
		People: [],
		Sessions: []
	});

	/** Submitted? */
	const [submitted, setSubmitted] = useState(null);

	/** Submitting? */
	const [submitting, setSubmitting] = useState(false);

	/** Show the "submission incomplete" warning? */
	const [submissionIncompleteWarning, setSubmissionIncompleteWarning] = useState(false);

	/**
	 * Selection changed
	 *
	 * @param {Object} props
	 * @return {void}
	 */
	const handleChange = useCallback(props => {
		setState({...state, ...props});
		setSubmitted(null);
	}, [state]);

	/**
	 * People selection changed
	 *
	 * @param {Array<Object>} Person objects
	 * @return {void}
	 */
	const handleChangePeople = useCallback(People => {
		handleChange({People});
	}, [handleChange]);

	/**
	 * Session selection changed
	 *
	 * @param {Array<Object>} Session objects
	 * @return {void}
	 */
	const handleChangeSessions = useCallback(Sessions => {
		handleChange({Sessions});
	}, [handleChange]);

	/**
	 * Clear the current submission state.
	 * 
	 * @return {void}
	 */
	const handleClearSubmission = useCallback(() => {
		handleChangePeople(state.People.filter(p => ![null, 409].includes(submitted?.[p.Id])));
		setSubmitted(null);
	}, [state, submitted, handleChangePeople]);

	/**
	 * Closing the "submission incomplete warning"
	 *
	 * @return {void}
	 */
	const handleCloseSubmissionIncompleteWarning = useCallback(() => {
		setSubmissionIncompleteWarning(false);
	}, []);

	/**
	 * Submitting!
	 * 
	 * @return {void}
	 */
	const handleSubmit = useCallback(async () => {

		if (!state.Sessions?.length || !state.People?.length) {
			setSubmissionIncompleteWarning(true);
			return;
		}

		setSubmitting(true);

		try {

			const result = await recordSignin(
				state.People,
				state.Sessions
			);

			setSubmitted(result);

			if (result.allOk) {

				const newState = {People: []};

				/**
				 * Leave the session selected, ready for the next visitor 
				 * to be signed in, unless we've selected multiple sessions, 
				 * in which case the selection is probably bespoke to this 
				 * visitor so is best being recreated for the next one.
				 */
				if (state.Sessions.length > 1) {
					newState.Sessions = [];
				}

				handleChange(newState);

				ToastStore.success("Signed in successfully.");

			}

		}
		catch (e) {
			ToastStore.error(e);
		}

		setSubmitting(false);

	}, [state, handleChange]);

	/** Render! */
	return (
		<View>
			<Header as="h1" content="Sign In" />
			<Divider hidden={true} />
			<SigninSessionSelector
				disabled={submitting}
				onChange={handleChangeSessions}
				selection={state.Sessions} />
			<Divider hidden={true} />
			<SigninVisitorSelector
				disabled={submitting}
				onChange={handleChangePeople}
				selection={state.People} />
			<Divider hidden={true} />
			<Flex alignItems="center" columnar={true}>
				<Button
					className={scss.signinButton}
					color="blue"
					content="Sign In"
					disabled={submitting}
					icon="check"
					loading={submitting}
					onClick={handleSubmit}
					size="large" />
			</Flex>
			<SigninSubmissionErrorDialog
				onClose={handleClearSubmission}
				open={(submitted && !submitted.allOk)}
				result={submitted} />
			<SigninSubmissionIncompleteDialog
				onClose={handleCloseSubmissionIncompleteWarning}
				open={submissionIncompleteWarning} />
		</View>
	);

};
