import useAuth from "Hooks/useAuth.js";
import useNavigator from "Hooks/useNavigator.js";
import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

export default () => {

	const {isAuthed} = useAuth();
	const isAuthedCurrent = useRef(isAuthed);

	const dispatch = useDispatch();
	const navigate = useNavigator();

	const inhibited = useSelector(store => store.inhibitLogoutListener);

	useEffect(() => {

		if (!isAuthed && isAuthedCurrent.current && !inhibited) {
			navigate("/");
		}

		isAuthedCurrent.current = isAuthed;
		dispatch({type: "update", data: {inhibitLogoutListener: false}});

	}, [isAuthed, inhibited, navigate, dispatch]);

	return null;

};
