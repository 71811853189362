import recordSignin from "Signin/RecordSignin.js";
import Flex from "Components/Flex.js";
import RegistrationViewForm from "Registration/RegistrationViewForm.js";
import SigninSessionSelector from "Signin/SigninSessionSelector.js";
import scss from "./RegistrationViewSignInStep.module.scss";
import strings from "./RegistrationView.strings.json";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useState} from "react";
import {Button} from "semantic-ui-react";

export default props => {

	const [selection, setSelection] = useState([]);
	const [submitting, setSubmitting] = useState(false);

	/**
	 * Submitting!
	 *
	 * @async
	 * @return {void}
	 */
	const handleSubmit = async () => {

		if (!selection.length) {

			ToastStore.toast({
				message: strings.signIn.errors.emptySelection,
				type: "error"
			});

			return;

		}

		setSubmitting(true);

		try {

			const result = await recordSignin(
				props.people,
				selection
			);

			props.onSubmit(result);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setSubmitting(false);

	};

	/**
	 * Render!
	 */
	return (
		<RegistrationViewForm
			caption={strings.signIn.caption}>
			<SigninSessionSelector
				className={scss.sessionSelector}
				disabled={submitting}
				disableMinHeight={true}
				disableOutline={true}
				onChange={setSelection}
				selection={selection} />
			<Flex
				justifyContent="flex-end"
				columnar={true}
				gap={0.5}>
				<Button
					content="Cancel"
					icon="close"
					onClick={props.onCancel}
					size="large" />
				<Button
					content="Sign In"
					disabled={submitting}
					loading={submitting}
					icon="check"
					onClick={handleSubmit}
					positive={true}
					size="large" />
			</Flex>
		</RegistrationViewForm>
	);

};
