import api from "api.js";
import AdminPersonViewAlertFormInputs from "./AdminPersonViewAlertFormInputs.js";
import Flex from "Components/Flex.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useState} from "react";
import {Form, Header, Ref, Segment} from "semantic-ui-react";

export default props => {

	const initialState = {
		Content: "",
		Active: true,
		Dismissable: true,
		Warning: true,
		Referrals: []
	};

	const [state, setState] = useState(initialState);
	const [submitting, setSubmitting] = useState(false);

	/**
	 * Submitting!
	 *
	 * @async
	 * @param {Event} e
	 * @return {void}
	 */
	const handleSubmit = async e => {

		e.preventDefault();

		setSubmitting(true);

		try {

			const alert = {
				...state,
				Person: props.person.Id,
				Referrals: state.Referrals.map(ReferralType => ({ReferralType}))
			};

			const result = await api({
				url: `/people/alerts`,
				method: "POST",
				data: alert
			});

			ToastStore.toast({
				message: "Alert saved successfully.",
				type: "success"
			});

			props.onCreate(result.data);
			setState(initialState);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setSubmitting(false);

	};

	/** Render! */
	return (
		<Ref innerRef={props.formRef}>
			<Form onSubmit={handleSubmit}>
				<Segment>
					<Flex removeContentMargins={true}>
						<Header
							content="New Alert" />
						<AdminPersonViewAlertFormInputs
							disabled={submitting}
							onCancel={props.onCancel}
							onChange={setState}
							person={props.person}
							state={state} />
					</Flex>
				</Segment>
			</Form>
		</Ref>
	);

};
