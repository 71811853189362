import api from "api.js";
import useAuth from "Hooks/useAuth.js";
import Dialog from "Components/Dialog.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useState} from "react";

export default props => {

	const {authUser} = useAuth();
	const [submitting, setSubmitting] = useState(false);

	const isAuthUser = (props.user?.Id === authUser.Id);

	const handleConfirm = async () => {

		setSubmitting(true);

		try {

			await api({
				url: `/users/${props?.user.Id}/pwr`,
				method: "POST"
			});

			props.onClose();
			ToastStore.success((!isAuthUser ? (props.user?.Active ? "A new password has been emailed to the user." : "A new password has been generated for the user.") : "You have been emailed a new password."), "Password reset complete");

		}
		catch (e) {
			ToastStore.error(e);
		}

		setSubmitting(false);

	};

	const content = [(!isAuthUser ? "Are you sure you want to reset this user's password?" : "Are you sure you want to reset your password?")];
	if (!isAuthUser) content.push((props.user?.Active ? "A new automatically generated password will be sent to the user's email address." : "A new password will be generated but it will not be emailed to the user because their account is inactive."));

	return (
		<Dialog
			content={content.join("\n")}
			header="Reset Password"
			loading={submitting}
			onClose={props.onClose}
			onOk={handleConfirm}
			open={props.open} />
	);

};
