import "index.scss";
import "semantic-ui-css/semantic.min.css";
import sentry from "./sentry.js";
import worker from "./worker.js";
import App from "./App/App.js";
import {createRoot} from "react-dom/client";

/** Initialise Sentry */
sentry();

/** Register the service worker */
worker();

/** Bootstrap the React app */
const reactRoot = createRoot(document.getElementById("react"));
reactRoot.render(<App />);
