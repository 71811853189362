import dLogout from "Dispatchers/dLogout.js";
import scss from "./AppBarProfileBar.module.scss";
import useAuth from "Hooks/useAuth.js";
import useNavigator from "Hooks/useNavigator.js";
import {Button, Icon, Label} from "semantic-ui-react";

export default () => {

	const {authUser} = useAuth();
	const navigate = useNavigator();

	return (
		<section className={scss.root}>
			<Button
				as="div"
				labelPosition="left">
				<Label
					as="a"
					basic={true}
					content={authUser.Name}
					onClick={() => navigate("/profile")}
					pointing="right"
					title="Your Profile" />
				<Button
					icon={true}
					onClick={dLogout}
					title="Logout">
					<Icon name="sign-out" />
				</Button>
			</Button>
			<Button
				className={scss.mobile}
				color="blue"
				icon={true}
				onClick={() => navigate("/profile")}
				title="Your Profile">
				<Icon name="user circle" />
			</Button>
		</section>
	);

};
