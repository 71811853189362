import api from "api.js";
import useData from "Hooks/useData.js";
import ErrorState from "Components/ErrorState.js";
import Hidden from "Components/Hidden.js";
import SigninViewSection from "./SigninViewSection.js";
import Text from "Components/Text.js";
import scss from "./SigninSessionSelector.module.scss";
import {Button, Loader} from "semantic-ui-react";
import {useCallback} from "react";

export default props => {

	const state = useData(useCallback(
		() => api({url: "/sessions", params: {Archived: false}}).then(({data}) => data),
		[]
	));

	/** Do we have sessions? */
	const isEmptyState = (state.data?.Objects?.length === 0);

	/** CSS classes */
	const classes = [];

	if (!isEmptyState) {
		classes.push(scss.root);
	}

	if (props.className) {
		classes.push(props.className);
	}

	/**
	 * Toggling session selection.
	 *
	 * @param {Object} session
	 * @return {void}
	 */
	const handleChange = session => {
		let selection = [...props.selection];
		if (!props.selection.includes(session)) selection.push(session);
		else selection = selection.filter(s => (s !== session));
		props.onChange(selection);
	};

	/** Render! */
	return (
		<SigninViewSection
			className={classes.join(" ")}
			disableOutline={props.disableOutline}
			icon="calendar alternate"
			label="Select a session">
			<Loader
				active={state.loading}
				inline="centered"
				size="large" />
			{(state.error && <ErrorState e={state.error} onRetry={state.fetch} />)}
			<Hidden hidden={!state.data?.Objects?.length}>
				<nav className={scss.sessions}>
					{
						state.data?.Objects?.map((session, key) => {

							const active = props.selection.includes(session);

							return (
								<Button
									active={active}
									basic={true}
									className={(active ? scss.activeSessionButton : undefined)}
									content={session.Name}
									color="blue"
									disabled={props.disabled}
									key={key}
									onClick={() => handleChange(session)}
									size="big" />
							);

						})
					}
				</nav>
			</Hidden>
			{(isEmptyState && <Text color="secondary" content="There are no sessions available." />)}
		</SigninViewSection>
	);

};
