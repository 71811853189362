import api from "api.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import Hidden from "Components/Hidden.js";
import Input from "Components/Input.js";
import RefreshUserProfile from "Tasks/RefreshUserProfile.js";
import Text from "Components/Text.js";
import scss from "./TotpConfirmationDialog.module.scss";
import strings from "./TotpConfirmationDialog.strings.json";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useEffect, useRef, useState} from "react";
import {QRCodeSVG} from "qrcode.react";
import {Button, Ref} from "semantic-ui-react";

export default ({onClose, open, password, secret, user}) => {

	const inputRef = useRef(null);

	const [code, setCode] = useState(null);
	const [isConfirm, setIsConfirm] = useState(false);
	const [submitting, setSubmitting] = useState(null);

	const otpUri = `otpauth://totp/Abingdon%20Carousel%20FRSIS:${user.Email}?secret=${secret}&issuer=Abingdon%20Carousel`;


	/**
	 * Back button pressed.
	 * 
	 * @return {void}
	 */
	const handleBack = () => {
		setIsConfirm(false);
	};


	/**
	 * OK button pressed.
	 *
	 * @return {void}
	 */
	const handleOk = () => {

		if (!isConfirm) {
			setIsConfirm(true);
		}
		else handleSubmit();

	};


	/**
	 * We're disabling 2FA.
	 *
	 * @return {void}
	 */
	const handleDisable = async () => {

		setSubmitting("disable");

		try {

			await api({
				url: "profile/2fa/disable",
				method: "POST",
				data: {Password: password}
			});

			onClose();

		}
		catch (e) {
			ToastStore.error(e);
		}

		setSubmitting(null);

	};


	/**
	 * Submitting the confirmation.
	 *
	 * @return {void}
	 */
	const handleSubmit = async () => {

		if (!inputRef?.current?.querySelector?.("input")?.reportValidity?.()) {
			return;
		}

		setSubmitting("confirm");

		try {

			await api({
				url: "/profile/2fa/verify",
				method: "POST",
				data: {Password: password, Code: code}
			});

			/** Profile data update required so we know we're now TOTP-enabled */
			await RefreshUserProfile();

			onClose();

		}
		catch (e) {

			if (e?.response?.status === 401) {
				ToastStore.toast({
					header: "Incorrect code",
					message: "Please check you've registered the account with your authenticator app correctly. This error can also occur if the time on the device running your app is incorrect.",
					type: "error"
				});
			}
			else ToastStore.error(e);

		}

		setSubmitting(null);

	};


	/**
	 * Reset the state after closing/re-opening.
	 */
	useEffect(() => {
		setCode(null);
		setIsConfirm(false);
	}, [open]);


	/**
	 * Reselect the input when needed
	 */
	useEffect(() => {
		if (!submitting) {
			inputRef?.current?.querySelector?.("input")?.select();
		}
	}, [inputRef, submitting]);


	/**
	 * Render the code confirmation UI.
	 * 
	 * @return {ReactNode}
	 */
	const renderConfirm = () => (
		<Flex gap={0}>
			<Text
				content="Please enter the code from your app to verify your account has been added successfully." />
			<Ref innerRef={inputRef}>
				<Input
					autoFocus={true}
					disabled={!!submitting}
					onChange={setCode}
					maxLength={6}
					minLength={6}
					icon="key"
					iconPosition="left"
					inputMode="numeric"
					onEnter={handleOk}
					placeholder="123456"
					required={true}
					value={code} />
			</Ref>
		</Flex>
	);


	/**
	 * Render!
	 */
	return (
		<Dialog
			cancelLabel="Back"
			header="Enable Two-Factor Authentication"
			disabled={!!submitting}
			loading={(submitting === "confirm")}
			okLabel={(isConfirm ? "Verify" : "Next")}
			onClose={(isConfirm && handleBack)}
			onOk={handleOk}
			open={open}>
			<Flex columnar={true} gap={2}>
				<Hidden hidden={isConfirm}>
					<Flex alignItems="center" className={scss.secret}>
						<QRCodeSVG value={otpUri} />
						<p><code>{secret}</code></p>
					</Flex>
				</Hidden>
				<Flex gap={0}>
					{(!isConfirm ? <Text content={strings.help} /> : renderConfirm())}
					<Flex
						alignItems="flex-start"
						className={(!isConfirm ? scss.cancelBtn : undefined)}
						mt={(isConfirm ? 1 : undefined)}>
						<Button
							disabled={!!submitting}
							content="Cancel and Disable Two-Factor Authentication"
							loading={(submitting === "disable")}
							onClick={handleDisable}
							size="tiny" />
					</Flex>
				</Flex>
			</Flex>
		</Dialog>
	);

};
