import {useCallback, useEffect, useState} from "react";

export default (enabled=true) => {

	const getViewportDimensions = useCallback(() => {
		return {
			width: window.innerWidth,
			height: window.innerHeight
		};
	}, []);

	const [state, setState] = useState(getViewportDimensions());

	const updateState = useCallback(() => setState(getViewportDimensions()), [getViewportDimensions]);

	useEffect(() => {
		if (enabled) {
			window.addEventListener("resize", updateState);
			return () => window.removeEventListener("resize", updateState);
		}
		else return undefined;
	}, [enabled, updateState]);

	return state;

};
