import api from "api.js";
import Action from "Components/CommentAction.js";
import AdminPersonViewAlertDeletionDialog from "./AdminPersonViewAlertDeletionDialog.js";
import AdminPersonViewAlertFormInputs from "./AdminPersonViewAlertFormInputs.js";
import AlertReferralsList from "./AlertReferralsList.js";
import Flex from "Components/Flex.js";
import Hidden from "Components/Hidden.js";
import ReferralTypes from "Resources/PeopleReferralTypes.js";
import Text from "Components/Text.js";
import moment from "moment";
import scss from "./AdminPersonViewAlertFeedItem.module.scss";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useEffect, useState} from "react";
import {Comment, Form, Segment} from "semantic-ui-react";

export default ({alert, disabled, onDeleted, onUpdated}) => {

	const [isDeleting, setIsDeleting] = useState(false);

	const [isEditing, setIsEditing] = useState(false);
	const [editState, setEditState] = useState(null);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const isDisabled = (disabled || isSubmitting);

	/** Reset the editing state when our alert changes */
	useEffect(() => {
		setIsEditing(false);
	}, [alert]);

	/**
	 * Submitting edits.
	 *
	 * @async
	 * @param {Event} e
	 * @return {void}
	 */
	const handleEditSubmit = async e => {

		e.preventDefault();

		setIsSubmitting(true);

		try {

			const result = await api({
				url: `/people/alerts/${alert.Id}`,
				method: "PUT",
				data: {...editState}
			});

			onUpdated(result.data, alert);
			setIsEditing(false);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setIsSubmitting(false);

	};

	/**
	 * Toggling the editing state.
	 *
	 * @return {void}
	 */
	const handleToggleEditing = () => {

		setEditState({
			...alert,
			Referrals: alert.Referrals.map(val => ReferralTypes.getCaseFromValue(val))
		});

		setIsEditing(!isEditing);

	};

	/**
	 * We've deleted ourself.
	 *
	 * @return {void}
	 */
	const handleDeleted = () => {
		onDeleted(alert);
	};

	/**
	 * Toggling the deleting state.
	 *
	 * @return {void}
	 */
	const handleToggleDeleting = () => {
		setIsDeleting(!isDeleting);
	};

	/**
	 * Render the view state.
	 *
	 * @return {ReactNode}
	 */
	const renderView = () => (
		<>
			<Comment.Text
				content={
					<Text
						className={scss.content}
						content={alert.Content} />
				} />
			<Comment.Actions>
				<Action
					content="Edit"
					disabled={isDisabled}
					onClick={handleToggleEditing} />
				<Action
					content="Delete"
					disabled={isDisabled}
					onClick={handleToggleDeleting} />
			</Comment.Actions>
		</>
	);

	/**
	 * Render the editor state.
	 *
	 * @return {ReactNode}
	 */
	const renderEditor = () => (
		<>
			<Comment.Actions>
				<Flex my={1}>
					<Form onSubmit={handleEditSubmit}>
						<Segment>
							<Flex>
								<AdminPersonViewAlertFormInputs
									disabled={isSubmitting}
									onCancel={handleToggleEditing}
									onChange={setEditState}
									person={alert.Person}
									state={editState} />
							</Flex>
						</Segment>
					</Form>
				</Flex>
			</Comment.Actions>
		</>
	);

	/** Metadata text */
	const metadataString = [];
	if (alert.Active) metadataString.push(`Alert is active and ${(alert.Dismissable ? "dismissable" : "not dismissable")}.`);
	if (alert.Warning) metadataString.push("Displays warning on the Session Sign In screen.");

	/** Render! */
	return (
		<>
			<Comment>
				<Comment.Author
					content={(new moment(alert.CreationAuditStamp.Timestamp)).format("DD/MM/YYYY HH:mm")} />
				<Hidden hidden={(!metadataString.length || isEditing)}>
					<Flex gap={0.25}>
						<Comment.Metadata className={scss.metadata} content={metadataString.join(" ")} />
					</Flex>
				</Hidden>
				{(!!alert.Referrals.length && <AlertReferralsList referrals={alert.Referrals} />)}
				{(!isEditing ? renderView() : renderEditor())}
			</Comment>
			<AdminPersonViewAlertDeletionDialog
				alert={alert}
				onClose={handleToggleDeleting}
				onDeleted={handleDeleted}
				open={isDeleting} />
		</>
	);

};
