import {useState} from "react";

export default (val=false) => {

	const [value, setValue] = useState(val);

	const setFalse = () => setValue(false);
	const setTrue = () => setValue(true);

	return {value, setFalse, setTrue};

};
