import AdminPersonViewAlertFeedItem from "./AdminPersonViewAlertFeedItem.js";
import Flex from "Components/Flex.js";
import Text from "Components/Text.js";
import {Comment} from "semantic-ui-react";

export default props => {

	const objects = props?.data?.Objects;

	if (!objects?.length) {
		return (
			<Text
				color="secondary"
				content={(props.emptyMessage || "No alerts have been recorded against this person.")} />
		);
	}

	return (
		<Flex>
			<Comment.Group>
				{
					objects.map((alert, key) => (
						<AdminPersonViewAlertFeedItem
							alert={alert}
							disabled={props.disabled}
							key={key}
							onDeleted={props.onDeleted}
							onUpdated={props.onUpdated} />
					))
				}
			</Comment.Group>
		</Flex>
	);

};
