import ActiveIcon from "Components/ActiveIcon.js";
import scss from "./SigninSubmissionErrorsList.module.scss";
import {List} from "semantic-ui-react";

export default ({result}) => (
	<List>
		{
			result?.submittedVisitations?.map?.((v, key) => {

				const res = result.data?.[key];
				const success = (res === null);

				const person = result.people.find(p => (p.Id === v.Person));
				const session = result.sessions.find(s => (s.Id === v.Session));

				let errorMessage = null;

				if (res === 409) {
					errorMessage = "Already signed in.";
				}
				else if (success) {
					errorMessage = "Signed in successfully.";
				}
				else {
					errorMessage = "Unknown error.";
				}

				return (
					<List.Item key={key}>
						<ActiveIcon
							active={success}
							className={scss.icon} />
						<List.Content>
							<List.Header content={`${person.Name} (${session.Name})`} />
							<List.Description content={errorMessage} />
						</List.Content>
					</List.Item>
				);

			})
		}
	</List>
);
