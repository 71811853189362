import useNavigator from "Hooks/useNavigator.js";
import scss from "./Link.module.scss";

export default props => {

	const navigate = useNavigator();

	const handleClick = e => {
		if (!props.onClick) {
			e.preventDefault();
			navigate(props.uri);
		}
		else props.onClick();
	};

	let label = props.label;

	if (props.endArrow) {
		label = `${label} ${String.fromCharCode(8594)}`;
	}
	if (props.startArrow) {
		label = `${String.fromCharCode(8592)} ${label}`;
	}

	if (props.uri || props.onClick) {

		const classes = [scss.link];

		if (props.className) {
			classes.push(props.className);
		}

		return (
			<a
				className={classes.join(" ")}
				href={props.uri}
				onClick={handleClick}>
				{label}
			</a>
		);

	}
	else {

		const classes = [scss.p];

		if (props.className) {
			classes.push(props.className);
		}

		return (
			<p
				className={classes.join(" ")}>
				{label}
			</p>
		);

	}

};
