import scss from "./ToastProvider.module.scss";
import Text from "Components/Text.js";
import ToastStore from "./ToastStore.js";
import {useState} from "react";
import {Message} from "semantic-ui-react";

const store = new ToastStore();

export default () => {

	const [toasts, setToasts] = useState([]);
	store.getToasts = () => toasts;
	store.setToasts = setToasts;

	return (
		<div className={scss.root}>
			{
				toasts.map((toast, key) => (
					<Message
						key={key}
						content={<Text content={toast.message} />}
						error={(toast.type === "error")}
						header={toast.header}
						icon={toast.icon}
						info={(toast.type === "info")}
						onDismiss={() => store.remove(toast)}
						success={(toast.type === "success")}
						warning={(toast.type === "warning")} />
				))
			}
		</div>
	);

};

export {store as ToastStore};
