import ButtonMenu from "Components/ButtonMenu.js";
import Text from "Components/Text.js";
import View from "Components/View.js";
import useMenuItems from "Ui/useAppBarMenuItems.js";
import {Divider, Header} from "semantic-ui-react";

export default () => {

	const menuItems = useMenuItems().filter(i => !i.hiddenOnHome);

	return (
		<View>
			<Header as="h1" content="Abingdon Carousel FRSIS" />
			<Divider hidden={true} />
			<ButtonMenu items={menuItems} />
			{(!menuItems?.length && <Text color="secondary" content="Welcome to Abingdon Carousel FRSIS." />)}
			<Divider hidden={true} />
		</View>
	);

};

