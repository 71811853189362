import ActiveIcon from "Components/ActiveIcon.js";
import DataTable from "Components/DataTable.js";
import Link from "Components/Link.js";
import useViewport from "Hooks/useViewport.js";

export default props => {

	const {width: viewportWidth} = useViewport();
	const hideEmailColumn = (viewportWidth < 800);
	const hideTotpColumn = (viewportWidth < 1080);

	const columns = [
		{
			id: "Name",
			render: i => <Link label={i.Name} uri={`/admin/users/${i.Id}`} />,
			sortable: true,
			width: ((hideEmailColumn || hideTotpColumn) ? 2 : 4)
		},
		{
			id: "Email",
			render: i => <a href={`mailto:${i.Email}`}>{i.Email}</a>,
			hidden: hideEmailColumn,
			sortable: true,
			width: (hideTotpColumn ? 2 : 4)
		},
		{
			id: "Active",
			render: i => <ActiveIcon active={i.Active} />,
			width: 1,
			filter: {
				labels: {
					true: "Active",
					false: "Inactive"
				},
				value: props.query.Active,
				defaultValue: null,
				sortTrueFirst: true
			}
		},
		{
			id: "TotpEnabled",
			label: "2FA",
			render: i => <ActiveIcon active={i.TotpEnabled} />,
			hidden: hideTotpColumn,
			width: 1,
			filter: {
				labels: {
					true: "Enabled",
					false: "Disabled"
				},
				value: props.query.TotpEnabled,
				defaultValue: null,
				sortTrueFirst: true
			}
		}
	];

	return (
		<DataTable
			columns={columns}
			definition={true}
			onQueryChange={props.onQueryChange}
			query={props.query}
			state={props.state}
			stickyHeader={props.stickyHeader} />
	);

};
