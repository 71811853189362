export default {

	/**
	 * Auth state
	 *
	 * @type {Object}
	 */
	auth: {

		/**
		 * Auth expiration time
		 *
		 * @type {String|null} YYYY-MM-DD HH:MM:SS
		 */
		expiry: null,

		/**
		 * Auth token
		 *
		 * @type {String|null}
		 */
		token: null

	},

	/**
	 * Feature flags
	 *
	 * @type {Array}
	 */
	flags: [],

	/**
	 * Inhibit the logout listener next time?
	 *
	 * @type {Boolean}
	 */
	inhibitLogoutListener: false,

	/**
	 * Router navigation key
	 *
	 * Used as a key on the rendered Router component so we can 
	 * easily force a re-render of the current route by simply 
	 * changing this value.
	 *
	 * @type {Integer}
	 */
	navigationKey: 0,

	/**
	 * User object
	 *
	 * @type {Object}
	 */
	user: {

		/**
		 * User ID
		 *
		 * @type {Integer|null}
		 */
		Id: null,

		/**
		 * User's name
		 *
		 * @type {String|null}
		 */
		Name: null,

		/**
		 * User's email address
		 *
		 * @type {String|null}
		 */
		Email: null,

		/**
		 * Array of permission names assigned to the user
		 *
		 * @type {Array<String>}
		 */
		Permissions: []

	},

	/**
	 * User profile data currently updating?
	 * 
	 * @type {Boolean}
	 */
	userProfileDataUpdating: false

};
