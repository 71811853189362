import AdminView from "Admin/AdminView.js";
import useUriMatcher from "Hooks/useUriMatcher.js";

export default props => {

	const {sessionId, state} = props;

	const tabs = useUriMatcher([
		{
			label: "General",
			icon: "calendar alternate",
			uri: `/admin/sessions/${props.sessionId}`
		},
		{
			label: "Attendance Record",
			icon: "calendar alternate outline",
			uri: `/admin/sessions/${props.sessionId}/attendance`
		}
	]);

	return (
		<AdminView
			actions={props.actions}
			actionIcon={props.actionIcon}
			actionLabel={props.actionLabel}
			actionsLoading={props.actionsLoading}
			backLink="/admin/sessions"
			backLinkLabel="All Sessions"
			error={state.error}
			hideChildrenWhenLoading={true}
			loading={state.loading}
			onAction={props.onAction}
			onActionCancel={props.onActionCancel}
			onErrorRetry={state.fetch}
			showLoader={true}
			tabs={(sessionId ? tabs.items : undefined)}
			tabsIconsOnlyBreakpoint={360}
			title={(!sessionId ? "New Session" : state.data?.Name)}>
			{props.children}
		</AdminView>
	);

};
