import Enum from "Includes/Enum.js";

/**
 * People types enum
 *
 * @package Abcarousel
 * @subpackage Resources
 * @author Heron Web Ltd
 * @copyright Abingdon Carousel
 */
class PeopleTypes extends Enum {

	static Parent = 1;

	static Guardian = 2;

	static Child = 3;

	static Staff = 5;

	static Professional = 4;

	static Other = 10;

}

export default PeopleTypes;
