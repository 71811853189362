export default [
	{
		id: "attendance/date",
		label: "Attendance by Date",
		icon: "calendar alternate outline"
	},
	{
		id: "attendance/session",
		label: "Attendance by Session",
		icon: "calendar alternate"
	},
	{
		id: "attendance/family",
		label: "Attendance by Family",
		icon: "address book"
	},
	{
		id: "attendance/town",
		label: "Attendance by Town",
		icon: "building outline"
	},
	{
		id: "families/new-returning",
		label: "New/Repeat Families",
		icon: "repeat"
	}
];
