import axios from "axios";
import moment from "moment";
import ReduxStore from "App/Redux/Store.js";
import {ToastStore} from "Toasts/ToastProvider.js";

/**
 * Reset the state (logout) because the user's token is invalid or expired.
 *
 * @return {void}
 */
const resetDueToTokenInvalid = () => {

	ToastStore.toast({
		header: "Session expired",
		message: `You've been logged out because your session has expired.`,
		type: "error"
	});

	/**
	 * `inhibitLogoutListener` is `true` because we want the user to 
	 * stay on the current URI so they can log back in and get straight 
	 * back to where they were before.
	 */
	ReduxStore.dispatch({type: "reset", inhibitLogoutListener: true});

};


/**
 * The request interceptor is used to prepare values in the request 
 * to match the expectations of the API (e.g. convert booleans to integers).
 *
 * @param {AxiosRequest} request
 * @return {AxiosRequest}
 */
const requestInterceptor = request => {

	if (request.params) {
		for (const key in request.params) {
			if (request.params[key] === false) {
				request.params[key] = 0;
			}
			else if (request.params[key] === true) {
				request.params[key] = 1;
			}
		}
	}

	return request;

};


/**
 * The response error interceptor handles 403 status codes due to 
 * the user lacking the permission that the API requires, and due 
 * to their access token having expired or been marked as invalid.
 *
 * We rethrow the error after we've handled it so that the code 
 * that made the request still gets to see it as usual.
 * 
 * @param {AxiosError} error
 * @return {never}
 */
const responseErrorInterceptor = error => {

	const response = error?.response;

	if (response?.status === 403) {

		/** User doesn't have the endpoint's permission */
		if (response.data["auth.reason"] === "PermissionGate") {
			ToastStore.toast({
				header: "Access denied",
				message: `You don't have permission to access "${error.config.method.toUpperCase()} ${error.config.url}".`,
				type: "error"
			});
		}

		/** The user's token is invalid or expired - log them out now */
		else if (response.data["auth.reason"] === "TokenInvalid") {
			resetDueToTokenInvalid();
		}

	}

	throw error;

};


/**
 * Make an API request.
 *
 * Intended to be used for **all** API requests made by the application.
 *
 * Wraps Axios with our custom functionality, utilities, and behaviours.
 *
 * @param {AxiosRequest} request Request to send
 * @return {Promise} See Axios' `request()` method
 */
const request = request => {

	const auth = ReduxStore.getState().auth;

	/**
	 * The auth token has expired - abort and logout now
	 *
	 * Note that we still have to throw an error, so that the 
	 * code that called this request handles the event appropriately.
	 */
	if (auth && (new moment(auth.expiry)).isBefore(new moment())) {
		resetDueToTokenInvalid();
		throw new Error("Authentication token expired.");
	}

	/**
	 * Create our Axios instance for this request
	 */
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API,
		headers: {
			"Authorization": auth?.token,
			"Content-Type": "application/json"
		},
		timeout: 30000
	});

	/**
	 * Apply our interceptors
	 */
	instance.interceptors.request.use(requestInterceptor);
	instance.interceptors.response.use(undefined, responseErrorInterceptor);

	/**
	 * Send the request!
	 */
	return instance.request(request);

};

export default request;
