import api from "api.js";
import useData from "Hooks/useData.js";
import AdminAttendanceMetrics from "./AdminAttendanceMetrics.js";
import AdminAttendanceTable from "./AdminAttendanceTable.js";
import AdminView from "Admin/AdminView.js";
import ErrorBoundary from "App/Errors/ErrorBoundary.js";
import scss from "./AdminAttendanceView.module.scss";
import useAdminViewport from "Admin/useAdminViewport.js";
import useQuery from "Hooks/useQuery.js";
import {useCallback, useMemo} from "react";

export default () => {

	const {computeBodyTopOffset} = useAdminViewport();

	const query = useQuery(useMemo(() => ({
		Search: null,
		Sort: "Date",
		SortDirection: "Desc",
		Limit: 25,
		Page: 1
	}), []));

	const handleQueryChange = useCallback(
		props => query.setQuery({...query.query, ...props}),
		[query]
	);

	const state = useData(useCallback(
		() => api({url: "/attendance", params: query.query}).then(({data}) => data),
		[query.query]
	), true);

	const stateMetrics = useData(useCallback(
		() => api({url: "/attendance/metrics", params: query.query}).then(({data}) => data),
		[query.query]
	));

	const loading = (state.loading || stateMetrics.loading);

	return (
		<AdminView
			initialSearch={query.initialQuery?.Search}
			loading={loading}
			onSearchChange={Search => handleQueryChange({Search})}
			search={query.query.Search}
			searchable={true}
			title="Attendance">
			<ErrorBoundary
				inlineFallback={true}
				noMargin={true}
				paddedFallback={true}>
				<AdminAttendanceMetrics
					className={scss.metrics}
					state={stateMetrics} />
			</ErrorBoundary>
			<AdminAttendanceTable
				disabled={loading}
				onQueryChange={handleQueryChange}
				query={query.query}
				state={state}
				stickyHeader={computeBodyTopOffset({hasSearch: true})} />
		</AdminView>
	);

};
