import api from "api.js";
import Dialog from "Components/Dialog.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useState} from "react";

export default props => {

	const [submitting, setSubmitting] = useState(false);

	const handleConfirm = async () => {

		setSubmitting(true);

		try {

			await api({
				url: `/users/${props.user?.Id}/2fa/disable`,
				method: "POST"
			});

			props.onClose();
			props.onComplete();
			ToastStore.success(`Two-factor authentication has been disabled for ${props.user?.Name}.`);

		}
		catch (e) {
			ToastStore.error(e);
		}

		setSubmitting(false);

	};

	const content = [
		`Are you sure you want to disable two-factor authentication for ${props.user?.Name}?`,
		`The user will need to remove their account from their authenticator app and re-register anew to reactivate two-factor authentication again in the future.`
	];

	return (
		<Dialog
			content={content.join("\n")}
			header="Disable Two-Factor Authentication"
			loading={submitting}
			onClose={props.onClose}
			onOk={handleConfirm}
			open={props.open} />
	);

};
