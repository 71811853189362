import api from "api.js";
import AdminOnsiteTable from "./AdminOnsiteTable.js";
import AdminView from "Admin/AdminView.js";
import Checkbox from "Components/Checkbox.js";
import Flex from "Components/Flex.js";
import useAdminViewport from "Admin/useAdminViewport.js";
import useData from "Hooks/useData.js";
import useQuery from "Hooks/useQuery.js";
import {useCallback, useEffect, useMemo} from "react";

export default () => {

	const {computeBodyTopOffset} = useAdminViewport();

	const query = useQuery(useMemo(() => ({
		Search: null,
		IncludeSignedOut: false
	}), []));

	const filterData = records => {

		const search = query.query.Search?.trim();

		if (search) {
			records = records?.filter(r => {
				return (
					r.Name?.toLowerCase?.()?.includes?.(search) ||
					r.Family?.Name?.toLowerCase?.()?.includes?.(search)
				);
			});
		}

		if (!query.query.IncludeSignedOut) {
			records = records?.filter(r => {
				return !!r.Attendance.find(a => !a.SignedOut);
			});
		}

		return records;

	};

	const handleQueryChange = useCallback(
		props => query.setQuery({...query.query, ...props}),
		[query]
	);

	const handleIncludeSignedOutChange = useCallback(
		IncludeSignedOut => handleQueryChange({IncludeSignedOut}),
		[handleQueryChange]
	);

	const handleSearchChange = useCallback(
		Search => handleQueryChange({Search: (Search || null)}),
		[handleQueryChange]
	);

	const state = useData(useCallback(
		() => api({url: "/onsite"}).then(({data}) => data),
		[]
	), true);

	useEffect(() => window.scrollTo({top: 0}), [query.query]);

	return (
		<AdminView
			hideTabs={true}
			initialSearch={query.initialQuery?.Search}
			loading={state.loading}
			onSearchChange={handleSearchChange}
			search={query.query.Search}
			searchable={true}
			title={`Currently On-Site ${(state.data ? `(${state.data.filter(a => a.Attendance.some(a => !a.SignedOut)).length})` : "")}`.trim()}>
			<Flex
				columnar={true}
				justifyContent="space-between">
				<p>This is a list of everyone who's signed in today.</p>
				<Checkbox
					checkbox={true}
					label="Include signed out?"
					onChange={handleIncludeSignedOutChange}
					value={query.query.IncludeSignedOut} />
			</Flex>
			<AdminOnsiteTable
				disabled={state.loading}
				filterData={filterData}
				onQueryChange={handleQueryChange}
				onUpdateData={state.setData}
				query={query.query}
				state={state}
				stickyHeader={computeBodyTopOffset({hasTabs: false, hasSearch: true})} />
		</AdminView>
	);

};
