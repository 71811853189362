import * as Sentry from "@sentry/react";

export default () => {

	const dsn = process.env.REACT_APP_SENTRY_DSN;

	if (dsn) {
		Sentry.init({
			dsn,
			release: process.env.REACT_APP_APP
		});
	}

};

