import Flex from "Components/Flex.js";
import Hidden from "Components/Hidden.js";
import RegistrationViewFamilyDetailsForm from "./RegistrationViewFamilyDetailsForm.js";
import RegistrationViewFamilyMembersForm from "./RegistrationViewFamilyMembersForm.js";
import RegistrationViewRegistrationSuccessStep from "./RegistrationViewRegistrationSuccessStep.js";
import RegistrationViewReviewStep from "./RegistrationViewReviewStep.js";
import RegistrationViewSignInStep from "./RegistrationViewSignInStep.js";
import RegistrationViewSignInSuccessStep from "./RegistrationViewSignInSuccessStep.js";
import RegistrationViewSteps from "./RegistrationViewSteps.js";
import Segment from "Components/Segment.js";
import View from "Components/View.js";
import recordRegistration from "./RecordRegistration.js";
import scss from "./RegistrationView.module.scss";
import strings from "./RegistrationView.strings.json";
import useFlags from "Hooks/useFlags.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {createRef, useEffect, useState} from "react";
import {Button, Divider, Header} from "semantic-ui-react";

export default () => {

	let targetStep = null;

	const formRef = createRef();
	const {getFlag} = useFlags();

	const [activeStep, setActiveStep] = useState((parseInt(getFlag("REGISTRATION_DEFAULT_STEP")) || 0));
	const [unlockedSteps, setUnlockedSteps] = useState([]);

	const [signInSubmitted, setSignInSubmitted] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [targetFamilyMemberIndex, setTargetFamilyMemberIndex] = useState(undefined);

	const [familyDetails, setFamilyDetails] = useState(null);
	const [familyMembers, setFamilyMembers] = useState(null);

	/**
	 * Unlock steps as they become selected
	 */
	useEffect(() => {
		if (!unlockedSteps.includes(activeStep)) {
			setUnlockedSteps([...unlockedSteps, activeStep]);
		}
	}, [activeStep, unlockedSteps]);

	/**
	 * Reset the scroll position when the active step changes
	 */
	useEffect(() => {
		window.scrollTo({top: 0});
	}, [activeStep]);

	/**
	 * We're moving to a new step
	 *
	 * @param {Integer} step Index
	 * @return {void}
	 */
	const handleChangeStep = step => {
		targetStep = step;
		setTargetFamilyMemberIndex(undefined);
		handleGotoStep();
	};

	/**
	 * Go directly to the Family Details stage.
	 *
	 * (E.g. from the review stage.)
	 * 
	 * @return {void}
	 */
	const handleGotoFamilyDetails = () => {
		setActiveStep(0);
	};

	/**
	 * Go directly to the Family Members stage, optionally targeting 
	 * a specific family member for editing (by its index in the 
	 * current array of family member objects).
	 *
	 * (E.g. from the review stage.)
	 *
	 * @param {Integer} targetFamilyMemberIndex
	 * @return {void}
	 */
	const handleGotoFamilyMembers = targetFamilyMemberIndex => {
		setActiveStep(1);
		setTargetFamilyMemberIndex(targetFamilyMemberIndex);
	};

	/** 
	 * We want to change to a step
	 *
	 * We submit the active form element, when applicable, to ensure 
	 * we're always in a valid state before we move between steps, 
	 * so the user is never made to go back and correct details at 
	 * the end. Note: It is up to the active submission handler to 
	 * actually transition the step, when it gets fired on 
	 * successful submit and after validation!
	 *
	 * When there's no active form element, we transition to the 
	 * target or next step automatically, via `handleTargetStepTransition()`.
	 *
	 * @return {void}
	 */
	const handleGotoStep = () => {
		if (formRef?.current) {
			formRef?.current?.requestSubmit?.();
		}
		else handleTargetStepTransition();
	};

	/**
	 * We want to change to the next step
	 *
	 * See `handleGotoStep()` and `targetStep` for implementation details.
	 *
	 * @return {void}
	 */
	const handleGotoNextStep = () => {
		handleChangeStep((activeStep + 1));
	};

	/**
	 * We want to change to the previous step
	 *
	 * @return {void}
	 */
	const handleGotoPrevStep = () => {
		handleChangeStep((activeStep - 1));
	};

	/**
	 * Transition to the currently marked target step.
	 *
	 * Uses the next step when there is no target step.
	 *
	 * (Does not verify the current step is in a valid state.)
	 *
	 * @return {void}
	 */
	const handleTargetStepTransition = () => {
		setActiveStep(((targetStep !== null) ? targetStep : (activeStep + 1)));
		targetStep = null;
	};

	/**
	 * Reset the "target family member index" for the family 
	 * members form, so we no longer try to focus/scroll to 
	 * any family member in the form.
	 *
	 * @return {void}
	 */
	const handleTargetFamilyMemberIndexReset = () => {
		setTargetFamilyMemberIndex(undefined);
	};

	/**
	 * Submitting the Family Details step.
	 *
	 * @param {Object} familyDetails
	 * @return {void}
	 */
	const handleFamilyDetailsSubmit = familyDetails => {
		setFamilyDetails(familyDetails);
		handleTargetStepTransition();
	};

	/**
	 * Submitting the Family Members step.
	 *
	 * @return {void}
	 */
	const handleFamilyMembersSubmit = familyMembers => {
		setFamilyMembers(familyMembers);
		handleTargetStepTransition();
	};

	/**
	 * Submitting the registration!
	 *
	 * @async
	 * @return {void}
	 */
	const handleSubmit = async () => {

		/** We must have some family members to add to the family */
		if (!familyMembers?.length) {
			ToastStore.toast({
				message: strings.review.familyMembers.empty,
				type: "error"
			});
			return;
		}

		/** We are submitting! */
		setSubmitting(true);

		/** Submit! */
		try {

			const submissionResult = await recordRegistration(
				familyDetails,
				familyMembers
			);

			setSubmitted(submissionResult);

			/** Only the "Review" and "Sign In" steps should be unlocked now */
			setUnlockedSteps([2, 3]);

		}
		catch (e) {
			ToastStore.error(e);
		}

		/** We're done! */
		setSubmitting(false);

	};

	/** Main content renderers, indexed by their respective tab indexes */
	const mainComponent = [
		() => (
			<RegistrationViewFamilyDetailsForm
				formRef={formRef}
				initialState={familyDetails}
				onSubmit={handleFamilyDetailsSubmit} />
		),
		() => (
			<RegistrationViewFamilyMembersForm
				formRef={formRef}
				initialState={familyMembers}
				onResetTargetFamilyMemberIndex={handleTargetFamilyMemberIndexReset}
				onSubmit={handleFamilyMembersSubmit}
				targetFamilyMemberIndex={targetFamilyMemberIndex} />
		),
		() => (
			!submitted ?
				<RegistrationViewReviewStep
					disabled={submitting}
					familyDetails={familyDetails}
					familyMembers={familyMembers}
					onEditFamilyDetails={handleGotoFamilyDetails}
					onEditFamilyMember={handleGotoFamilyMembers}
					onSubmit={handleSubmit} /> :
				<RegistrationViewRegistrationSuccessStep
					familyDetails={familyDetails}
					familyMembers={familyMembers}
					onSignIn={handleGotoNextStep}
					result={submitted} />
		),
		() => {

			const people = submitted?.Members?.map(p => p.Person);

			return (
				!signInSubmitted ?
					<RegistrationViewSignInStep
						onCancel={handleGotoPrevStep}
						onSubmit={setSignInSubmitted}
						people={people} /> :
					<RegistrationViewSignInSuccessStep
						result={signInSubmitted} />
			);

		}
	];

	/** First step (can't go back)? */
	const isFirstStep = (activeStep === 0);

	/** Render! */
	return (
		<View>
			<Header
				as="h1"
				content="Family Registration" />
			<Divider
				hidden={true} />
			<RegistrationViewSteps
				active={activeStep}
				disabled={submitting}
				onChange={handleChangeStep}
				unlockedSteps={((activeStep !== 3) ? unlockedSteps : [3])} />
			<Divider
				hidden={true} />
			<Flex className={scss.main}>
				<Segment>
					{mainComponent[activeStep]()}
					<Hidden hidden={(submitted || (activeStep > 2))}>
						<Divider
							hidden={true} />
						<Flex
							columnar={true}
							justifyContent={(!isFirstStep ? "space-between" : "flex-end")}>
							<Hidden hidden={isFirstStep}>
								<Button
									content="Back"
									disabled={submitting}
									icon="arrow left"
									onClick={handleGotoPrevStep}
									primary={true}
									size="large" />
							</Hidden>
							<Button
								content={(activeStep === 2 ? "Register" : "Next")}
								disabled={submitting}
								icon="check"
								loading={submitting}
								onClick={((activeStep === 2) ? handleSubmit : handleGotoNextStep)}
								primary={true}
								positive={(activeStep === 2)}
								size="large"
								type="submit" />
						</Flex>
					</Hidden>
				</Segment>
			</Flex>
		</View>
	);

};
