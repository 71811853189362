import AdminAttendanceView from "Admin/Attendance/AdminAttendanceView.js";
import AdminDashboardView from "Admin/Dashboard/AdminDashboardView.js";
import AdminFamilyScreen from "Admin/Families/AdminFamilyScreen.js";
import AdminFamilyViewAttendance from "Admin/Families/AdminFamilyViewAttendance.js";
import AdminFamilyViewMembers from "Admin/Families/AdminFamilyViewMembers.js";
import AdminFamilyViewProperties from "Admin/Families/AdminFamilyViewProperties.js";
import AdminFamiliesView from "Admin/Families/AdminFamiliesView.js";
import AdminOnsiteView from "Admin/Onsite/AdminOnsiteView.js";
import AdminPeopleView from "Admin/People/AdminPeopleView.js";
import AdminPersonScreen from "Admin/People/AdminPersonScreen.js";
import AdminPersonViewAlerts from "Admin/People/AdminPersonViewAlerts.js";
import AdminPersonViewAttendance from "Admin/People/AdminPersonViewAttendance.js";
import AdminPersonViewProperties from "Admin/People/AdminPersonViewProperties.js";
import AdminReportsView from "Admin/Reports/AdminReportsView.js";
import AdminSessionScreen from "Admin/Sessions/AdminSessionScreen.js";
import AdminSessionViewAttendance from "Admin/Sessions/AdminSessionViewAttendance.js";
import AdminSessionViewProperties from "Admin/Sessions/AdminSessionViewProperties.js";
import AdminSessionsView from "Admin/Sessions/AdminSessionsView.js";
import AdminUserView from "Admin/Users/AdminUserView.js";
import AdminUserPermissionsView from "Admin/Users/Permissions/AdminUserPermissionsView.js";
import AdminUsersView from "Admin/Users/AdminUsersView.js";
import E404View from "Index/E404View.js";
import F12View from "F12/F12View.js";
import IndexView from "Index/IndexView.js";
import LoginView from "Login/LoginView.js";
import ProfileView from "Profile/ProfileView.js";
import RegistrationView from "Registration/RegistrationView.js";
import SigninView from "Signin/SigninView.js";

export default [
	{
		uri: "/",
		element: <IndexView />
	},
	{
		uri: "/admin",
		element: <AdminDashboardView />,
		permission: "Administrator"
	},
	{
		uri: "/admin/attendance",
		element: <AdminAttendanceView />,
		permission: "Administrator"
	},
	{
		uris: [
			"/admin/families/new",
			"/admin/families/:Id"
		],
		element: <AdminFamilyScreen child={AdminFamilyViewProperties} />,
		permission: "Administrator"
	},
	{
		uri: "/admin/families/:Id/attendance",
		element: <AdminFamilyScreen child={AdminFamilyViewAttendance} />,
		permission: "Administrator"
	},
	{
		uri: "/admin/families/:Id/members",
		element: <AdminFamilyScreen child={AdminFamilyViewMembers} />,
		permission: "Administrator"
	},
	{
		uri: "/admin/families",
		element: <AdminFamiliesView />
	},
	{
		uri: "/onsite",
		element: <AdminOnsiteView />,
		permission: "ViewOnsiteList"
	},
	{
		uris: [
			"/admin/people/new",
			"/admin/people/:Id"
		],
		element: <AdminPersonScreen child={AdminPersonViewProperties} />,
		permission: "Administrator"
	},
	{
		uri: "/admin/people/:Id/alerts",
		element: <AdminPersonScreen child={AdminPersonViewAlerts} />,
		permission: "PersonAlertsManage"
	},
	{
		uri: "/admin/people/:Id/attendance",
		element: <AdminPersonScreen child={AdminPersonViewAttendance} />,
		permission: "Administrator"
	},
	{
		uri: "/admin/people",
		element: <AdminPeopleView />,
		permission: "Administrator"
	},
	{
		uri: "/reports",
		element: <AdminReportsView />,
		permission: "ReportAccess"
	},
	{
		uris: [
			"/admin/sessions/new",
			"/admin/sessions/:Id"
		],
		element: <AdminSessionScreen child={AdminSessionViewProperties} />,
		permission: "Administrator"
	},
	{
		uri: "/admin/sessions/:Id/attendance",
		element: <AdminSessionScreen child={AdminSessionViewAttendance} />,
		permission: "Administrator"
	},
	{
		uri: "/admin/sessions",
		element: <AdminSessionsView />,
		permission: "Administrator"
	},
	{
		uri: "/admin/users/new",
		element: <AdminUserView create={true} />,
		permission: "Sysadmin"
	},
	{
		uri: "/admin/users/:Id/permissions",
		element: <AdminUserPermissionsView />,
		permission: "Sysadmin"
	},
	{
		uri: "/admin/users/:Id",
		element: <AdminUserView />,
		permission: "Sysadmin"
	},
	{
		uri: "/admin/users",
		element: <AdminUsersView />,
		permission: "Sysadmin"
	},
	{
		uri: "/f12",
		element: <F12View />,
		public: true
	},
	{
		uri: "/profile",
		element: <ProfileView />
	},
	{
		uri: "/registration",
		element: <RegistrationView />,
		permission: "FamilyRegistration"
	},
	{
		uri: "/signin",
		element: <SigninView />,
		permission: "SessionSignIn"
	},
	{
		uri: "*",
		element: <E404View />
	},
	{
		uri: "*",
		element: <LoginView />,
		public: true
	}
];
