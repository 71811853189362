import PeopleTypes from "Resources/PeopleTypes.js";
import RegistrationViewFamilyMemberForm from "./RegistrationViewFamilyMemberForm.js";
import RegistrationViewForm from "./RegistrationViewForm.js";
import newPersonState from "./RegistrationViewFamilyMemberFormInitialState.js";
import strings from "./RegistrationView.strings.json";
import {useState} from "react";
import {Button} from "semantic-ui-react";

export default props => {

	/**
	 * People have an `Id` which we use as their `key` to ensure that 
	 * expanded people forms remain expanded (or collapsed) as people 
	 * are deleted; this would not be possible if we only used the array 
	 * index as the key, because the indexes would change after deletion.
	 *
	 * The `Id` property is removed upon submission.
	 *
	 * @type {Array<Object>}
	 */
	const initialState = (
		props.initialState?.length ?
			props.initialState.map((person, key) => ({...person, Id: key})) :
			[{Id: 1, ...newPersonState()}]
	);

	/**
	 * Individual people forms
	 *
	 * @type {Array<Object>}
	 */
	const [people, setPeople] = useState(initialState);

	/**
	 * Adding a new person
	 *
	 * See `initialState` for description of how the `Id` property is used.
	 *
	 * @return {void}
	 */
	const handleAddPerson = () => {
		setPeople([
			...people,
			{
				...newPersonState(),
				Id: (people[(people.length - 1)].Id + 1),
				Type: PeopleTypes.Child
			}
		]);
	};

	/**
	 * Replacing a person's data
	 * 
	 * @param {Object} personData
	 * @param {Integer} personIndex
	 * @return {void}
	 */
	const handleChangePerson = (personData, personIndex) => {
		const peopleData = [...people];
		peopleData[personIndex] = personData;
		setPeople(peopleData);
	};

	/**
	 * Deleting a person
	 *
	 * @param {Integer} personIndex
	 * @return {void}
	 */
	const handleDeletePerson = personIndex => {
		const peopleData = [...people];
		peopleData.splice(personIndex, 1);
		setPeople(peopleData);
	};

	/**
	 * Submitting!
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	const handleSubmit = e => {

		e.preventDefault();

		/** We have to remove the `Id` property - see `initialState` */
		const submissionData = people
			.filter(person => !isPersonIgnored(person))
			.map(person => {
				person = {...person};
				delete person.Id;
				return person;
			});

		props.onSubmit(submissionData);

	};

	/**
	 * Get whether a person's form data appears to be in a valid state.
	 *
	 * @param {Object} person
	 * @return {Boolean}
	 */
	const isPersonIgnored = person => {
		return !(
			person.Name ||
			person.Dob ||
			person.Notes ||
			person.AllergyNotes ||
			person.MedicalNotes ||
			person.EmailAddresses.filter(e => e.Email).length ||
			person.PhoneNumbers.filter(p => p.Phone).length
		);
	};

	/** Render! */
	return (
		<RegistrationViewForm
			caption={strings.familyMembers.caption}
			innerRef={props.formRef}
			onSubmit={handleSubmit}>
			{
				people.map((person, key) => {

					const isTargetPerson = (props.targetFamilyMemberIndex === key);
					const hasTargetPerson = (props.targetFamilyMemberIndex !== undefined);

					return (
						<RegistrationViewFamilyMemberForm
							allowDelete={(people.length > 1)}
							autoFocus={(((key === 0) && (initialState.length === 1)) || (key >= initialState.length))}
							data={person}
							disabled={props.disabled}
							disableDefaultExpanded={(!isTargetPerson && hasTargetPerson)}
							key={person.Id}
							index={key}
							onChange={handleChangePerson}
							onDelete={handleDeletePerson}
							onScrolledIntoView={props.onResetTargetFamilyMemberIndex}
							required={!isPersonIgnored(person)}
							scrollIntoViewOnMount={isTargetPerson} />
					);

				})
			}
			<div>
				<Button
					content="Add Person"
					disabled={props.disabled}
					icon="add"
					onClick={handleAddPerson}
					primary={true}
					type="button" />
			</div>
		</RegistrationViewForm>
	);

};
