import useFlags from "Hooks/useFlags.js";
import {useSelector} from "react-redux";

export default () => {

	const {auth, user} = useSelector(store => store);
	const {hasFlag} = useFlags();

	const isAuthed = !!auth.token;

	return {
		authUser: (isAuthed ? user : null),
		hasPermission: p => (user?.Permissions?.includes?.(p) || user?.Permissions?.includes?.("Sysadmin") || hasFlag("FORCE_ALL_PERMISSIONS")),
		isAuthed
	};

};
