import scss from "./TabPanel.module.scss";
import useNavigator from "Hooks/useNavigator.js";
import useViewport from "Hooks/useViewport.js";
import {Menu, Select} from "semantic-ui-react";

export default props => {

	const navigate = useNavigator();
	const {width: viewportWidth} = useViewport(!!props.selectMaxWidth);

	const useSelect = (viewportWidth <= props.selectMaxWidth);

	const tabs = props.items.filter(i => !i.hidden);

	/** We can render as a select on small viewports */
	if (useSelect) {

		const handleChange = (e, {value: index}) => {
			props.onSelect(tabs[parseInt(index)]);
		};

		const options = tabs.map((item, key) => {
			return {
				active: item.active,
				icon: item.icon,
				key,
				text: item.label,
				value: key
			};
		});

		const activeValue = options.find(o => o.active).value;

		return (
			<Select
				className={scss.select}
				onChange={handleChange}
				options={options}
				value={activeValue} />
		);

	}

	/** We are rendering as an actual tab panel */
	else {
		return (
			<Menu
				className={props.className}
				size={props.size}
				tabular={true}>
				{
					tabs.map((item, key) => (
						<Menu.Item
							active={item.active}
							content={(!props.iconsOnly ? item.label : undefined)}
							icon={item.icon}
							key={key}
							onClick={() => (props.onSelect ? props.onSelect(item) : navigate(item.uri))} />
					))
				}
			</Menu>
		);
	}

};
