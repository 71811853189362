import AdminView from "Admin/AdminView.js";
import ButtonMenu from "Components/ButtonMenu.js";
import useAdminViewTabs from "Admin/useAdminViewTabs.js";

export default () => {

	const menuItems = useAdminViewTabs().filter(t => !!t.uri).map(t => ({...t, uri: `/admin${t.uri}`}));

	return (
		<AdminView title="Dashboard">
			<ButtonMenu
				items={menuItems} />
		</AdminView>
	);

};
