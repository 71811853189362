import scss from "./Text.module.scss";

export default props => {

	const classes = [];

	if (props.className) {
		classes.push(props.className);
	}
	if (props.color) {
		const colorClass = `color__${props.color}`;
		classes.push(scss[colorClass]);
	}
	if (props.noMargin) {
		classes.push(scss.noMargin);
	}

	let content = props.content;

	if (typeof content === "string") {
		content = content.split("\n");
	}
	if (!Array.isArray(content)) {
		content = [content];
	}

	content = content.filter(l => l);

	const style = {};

	if (props.bold) {
		style.fontWeight = "bold";
	}

	if (props.singleLine) {

		style.display = "-webkit-box";
		style.overflow = "hidden";
		style.textOverflow = "ellipsis";
		style.WebkitBoxOrient = "vertical";
		style.WebkitLineClamp = 1;

		if (content?.length) {
			content = [content[0]];
		}

	}

	return content.filter(l => l).map((l, key) => (
		<p className={classes.join(" ")} key={key} style={style}>
			{l}
		</p>
	));

};
