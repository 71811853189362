import ErrorBoundary from "./Errors/ErrorBoundary.js";
import Main from "./Main.js";
import ToastProvider from "Toasts/ToastProvider.js";
import {Store as ReduxStore, StorePersistor as ReduxStorePersistor} from "./Redux/Store.js";
import {PersistGate as ReduxPersistGate} from "redux-persist/integration/react";
import {Provider as ReduxProvider} from "react-redux";

export default () => {
	return (
		<ErrorBoundary paddedFallback={true}>
			<ReduxProvider store={ReduxStore}>
				<ReduxPersistGate loading={null} persistor={ReduxStorePersistor}>
					<Main />
					<ToastProvider />
				</ReduxPersistGate>
			</ReduxProvider>
		</ErrorBoundary>
	);
};
