import {useSelector} from "react-redux";

export default () => {

	const flags = useSelector(store => store.flags);

	const getFlag = flag => {

		const def = flags.map(f => f.trim()).map(f => f.split("=")).find(f => (f[0] === flag));

		if (def) {
			const value = def[1];
			return ((value !== undefined) ? value : true);
		}

		return undefined;

	};

	const hasFlag = flag => (getFlag(flag) !== undefined);

	return {
		flags,
		getFlag,
		hasFlag
	};

};
