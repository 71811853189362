import {Confirm} from "semantic-ui-react";

export default ({open, onClose}) => {
	return (
		<Confirm
			header="Submission incomplete"
			content="Please check you've selected a session and the people who are visiting."
			cancelButton={null}
			onConfirm={onClose}
			open={open} />
	);
};
