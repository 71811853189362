import Dialog from "Components/Dialog.js";
import F12View from "./F12View.js";
import scss from "./F12Dialog.module.scss";
import {useCallback, useEffect, useState} from "react";

export default () => {

	const [open, setOpen] = useState(false);

	const toggleOpen = useCallback(() => setOpen(!open), [open]);

	const keyListener = useCallback(e => {
		if (e.ctrlKey && (e.key === "F12")) {
			toggleOpen();
		}
	}, [toggleOpen]);

	useEffect(() => {
		window.addEventListener("keydown", keyListener);
		return () => window.removeEventListener("keydown", keyListener);
	}, [keyListener]);

	return (
		<Dialog
			header="Developer tools"
			onClose={toggleOpen}
			open={open}>
			<F12View
				containerClassName={scss.content}
				noHeader={true} />
		</Dialog>
	);

};
