import api from "api.js";
import PeopleTypes from "Resources/PeopleTypes.js";

/**
 * Submit a family registration
 *
 * The parameters must be according to the format set up by `RegistrationView` 
 * and its constitutent sub-components; we map the values to the formats used 
 * by the API here.
 *
 * @async
 * @param {Object|Integer} familyDetails Family data to register (or existing ID)
 * @param {Array<Object>} familyMembers Family members to register to the family
 * @return {Object} API result data
 */
export default async (familyDetails, familyMembers) => {

	const result = await api({
		url: `/families/register`,
		method: "POST",
		data: {
			Family: familyDetails,
			Members: familyMembers.map(member => {
				return {
					...member,
					Type: PeopleTypes.getCaseFromValue(member.Type),
					EmailAddresses: member.EmailAddresses.map(e => e.Email).filter(e => e),
					PhoneNumbers: member.PhoneNumbers.map(p => p.Phone).filter(p => p)
				};
			})
		}
	});

	return result.data;

};
