import Accordion from "Components/Accordion.js";
import Checkbox from "Components/Checkbox.js";
import EditableList from "Components/EditableList.js";
import FieldLabel from "Components/FieldLabel.js";
import Flex from "Components/Flex.js";
import Input from "Components/Input.js";
import Panel from "Components/Panel.js";
import PeopleTypes from "Resources/PeopleTypes.js";
import PeopleTypeRadio from "Components/PeopleTypeRadio.js";
import TelInput from "Components/TelInput.js";
import moment from "moment";
import scss from "./RegistrationViewFamilyMemberForm.module.scss";
import strings from "./RegistrationView.strings.json";
import useOptionalTextareaAccordion from "Hooks/useOptionalTextareaAccordion.js";
import {createRef, useEffect, useRef, useState} from "react";
import {Button, Ref} from "semantic-ui-react";

export default props => {

	const {scrollIntoViewOnMount, onScrolledIntoView} = props;

	const nodeRef = createRef();
	const notesRef = createRef();
	const [expanded, setExpanded] = useState(!props.disableDefaultExpanded);

	const notesAccordionInitialState = useRef(!!props.data?.Notes);
	const notesAccordionState = useOptionalTextareaAccordion(notesAccordionInitialState.current, notesRef);

	const prevEmailAddresses = useRef(props.data.EmailAddresses);
	const prevPhoneNumbers = useRef(props.data.PhoneNumbers);

	/**
	 * Create a unique `name` attribute for a field by its apparent name.
	 *
	 * @param {String} name
	 * @return {String}
	 */
	const createFieldName = name => `person${props.index}_${name}`;

	/**
	 * Get our form input elements.
	 * 
	 * @return {Array<DOMNode>}
	 */
	const getInputs = () => Array.from((nodeRef?.current?.querySelectorAll?.("input, textarea") || []));

	/**
	 * Add a new email address.
	 *
	 * @return {void}
	 */
	const handleAddEmailAddress = () => {
		handleChange(
			[...props.data.EmailAddresses, {Email: null}],
			createFieldName("EmailAddresses")
		);
	};

	/**
	 * Email address changed.
	 *
	 * @param {String} email
	 * @param {String} name
	 * @return {void}
	 */
	const handleChangeEmailAddress = (email, name) => {
		const emails = [...props.data.EmailAddresses];
		const emailIndex = parseInt(name.split("-")[1]);
		emails[emailIndex] = {...emails[emailIndex], Email: email};
		handleChange(emails, createFieldName("EmailAddresses"));
	};

	/**
	 * Delete an email address.
	 *
	 * @param {String} email
	 * @return {void}
	 */
	const handleDeleteEmailAddress = email => {
		handleChange(
			props.data.EmailAddresses.filter(e => (e !== email)),
			createFieldName("EmailAddresses")
		);
	};

	/**
	 * Add a new phone number.
	 *
	 * @return {void}
	 */
	const handleAddPhoneNumber = () => {
		handleChange(
			[...props.data.PhoneNumbers, {Phone: null}],
			createFieldName("PhoneNumbers")
		);
	};

	/**
	 * Phone number changed.
	 *
	 * @param {String} phone
	 * @param {String} name
	 * @return {void}
	 */
	const handleChangePhoneNumber = (phone, name) => {
		const phones = [...props.data.PhoneNumbers];
		const phoneIndex = parseInt(name.split("-")[1]);
		phones[phoneIndex] = {...phones[phoneIndex], Phone: phone};
		handleChange(phones, createFieldName("PhoneNumbers"));
	};

	/**
	 * Delete a phone number.
	 *
	 * @param {String} phone
	 * @return {void}
	 */
	const handleDeletePhoneNumber = phone => {
		handleChange(
			props.data.PhoneNumbers.filter(p => (p !== phone)),
			createFieldName("PhoneNumbers")
		);
	};

	/**
	 * Value changed.
	 *
	 * @param {mixed} value
	 * @return {void}
	 */
	const handleChange = (value, name) => {

		/** See `createFieldName()` */
		name = name.split("_")[1];

		/** Notify the value change */
		props.onChange({...props.data, [name]: value}, props.index);

	};

	/**
	 * We're toggling our expansion panel.
	 *
	 * @return {void}
	 */
	const handleExpansionToggle = () => {

		const newState = !expanded;

		/** 
		 * We are closing the panel
		 *
		 * We do not allow this if any input is an invalid state,
		 * because when the outer form is submitted, the browser 
		 * will not be able to show the error state for any of 
		 * our inputs that are invalid, if they are hidden in the 
		 * collapsed panel.
		 */
		if (!newState) {
			for (const input of getInputs()) {
				if (!input.reportValidity()) {
					return;
				}
			}
		}

		setExpanded(newState);

	};

	/**
	 * Track previous email addresses so we can tell whether 
	 * a new one has been rendered and can then autofocus 
	 * its input correctly
	 */
	useEffect(() => {
		prevEmailAddresses.current = props.data.EmailAddresses;
	}, [prevEmailAddresses, props.data.EmailAddresses]);

	/**
	 * Track previous phone numbers so we can tell whether 
	 * a new one has been rendered and can then autofocus 
	 * its input correctly
	 */
	useEffect(() => {
		prevPhoneNumbers.current = props.data.PhoneNumbers;
	}, [prevPhoneNumbers, props.data.PhoneNumbers]);

	/**
	 * Scroll into view on mount when needed!
	 */
	useEffect(() => {

		if (scrollIntoViewOnMount && nodeRef.current) {

			const scrollIntoViewTimer = setTimeout(() => {

				const top = nodeRef?.current?.getBoundingClientRect?.()?.top;

				if (top) {
					window.scrollTo({top: ((top + window.pageYOffset) - 215)});
					onScrolledIntoView();
				}

			});

			return () => clearTimeout(scrollIntoViewTimer);

		}
		else return undefined;

	}, [scrollIntoViewOnMount, onScrolledIntoView, nodeRef]);

	/**
	 * Render the main content!
	 *
	 * @return {ReactNode}
	 */
	const renderMain = () => (
		<Flex
			gap={2}
			innerRef={nodeRef}>
			<PeopleTypeRadio
				columnar={true}
				disabled={props.disabled}
				name={createFieldName("Type")}
				onChange={handleChange}
				required={props.required}
				value={props.data.Type} />
			<Input
				autoFocus={props.autoFocus}
				disabled={props.disabled}
				label="Name"
				maxLength={255}
				name={createFieldName("Name")}
				onChange={handleChange}
				required={props.required}
				value={props.data.Name} />
			<Input
				disabled={props.disabled}
				label="Date of Birth"
				max={(new moment()).format("YYYY-MM-DD")}
				name={createFieldName("Dob")}
				onChange={handleChange}
				required={(props.required && (props.data.Type === PeopleTypes.Child))}
				type="date"
				value={props.data.Dob} />
			<Checkbox
				checkbox={true}
				disabled={props.disabled}
				label={strings.familyMembers.photoConsent}
				fieldLabel="Photo Consent"
				name={createFieldName("PhotoConsent")}
				onChange={handleChange}
				value={props.data.PhotoConsent} />
			<Flex gap={0.5}>
				<FieldLabel
					label="Email Addresses" />
				<EditableList
					className={scss.contactDetails}
					disabled={props.disabled}
					editing={true}
					items={props.data.EmailAddresses}
					onNew={handleAddEmailAddress}
					onDelete={handleDeleteEmailAddress}
					renderItemEditor={
						(email, key) => (
							<Input
								autoFocus={(key > (prevEmailAddresses.current.length - 1))}
								disabled={props.disabled}
								maxLength={255}
								name={createFieldName(`email-${key}`)}
								onChange={handleChangeEmailAddress}
								placeholder="Email address"
								required={!!email.Email}
								type="email"
								value={email.Email} />
						)
					} />
			</Flex>
			<Flex gap={0.5}>
				<FieldLabel
					label="Phone Numbers" />
				<EditableList
					className={scss.contactDetails}
					disabled={props.disabled}
					editing={true}
					items={props.data.PhoneNumbers}
					onNew={handleAddPhoneNumber}
					onDelete={handleDeletePhoneNumber}
					renderItemEditor={
						(phone, key) => (
							<TelInput
								autoFocus={(key > (prevPhoneNumbers.current.length - 1))}
								disabled={props.disabled}
								name={createFieldName(`phone-${key}`)}
								onChange={handleChangePhoneNumber}
								required={!!phone.Phone}
								value={phone.Phone} />
						)
					} />
			</Flex>
			<Input
				control="textarea"
				disabled={props.disabled}
				label="Allergies"
				maxLength={65535}
				name={createFieldName("AllergyNotes")}
				placeholder="Please detail any allergies we need to be aware of..."
				onChange={handleChange}
				rows={5}
				value={props.data.AllergyNotes} />
			<Input
				control="textarea"
				disabled={props.disabled}
				label="Medications and Medical Conditions"
				maxLength={65535}
				name={createFieldName("MedicalNotes")}
				placeholder="Please provide any medical notes we should be aware of..."
				onChange={handleChange}
				rows={5}
				value={props.data.MedicalNotes} />
			<Accordion
				label="Notes"
				noSummarySpacing={true}
				onToggle={notesAccordionState.toggle}
				open={notesAccordionState.open}>
				<Ref innerRef={notesRef}>
					<Input
						control="textarea"
						disabled={props.disabled}
						maxLength={65535}
						name={createFieldName("Notes")}
						onChange={handleChange}
						placeholder="Record any relevant notes..."
						rows={5}
						value={props.data.Notes} />
				</Ref>
			</Accordion>
		</Flex>
	);

	/**
	 * Render as a `Panel`.
	 *
	 * @return {ReactNode}
	 */
	const renderPanelled = () => (
		<Panel
			action={<Button disabled={(props.disabled || !props.allowDelete)} icon="trash alternate" onClick={() => props.onDelete(props.index)} type="button" />}
			title={`Person ${(props.index + 1)}`}
			open={expanded}
			onOpenToggle={handleExpansionToggle}>
			{renderMain()}
		</Panel>
	);

	/**
	 * Render!
	 */
	return (!props.inline ? renderPanelled() : renderMain());

};
