import api from "api.js";
import Store from "App/Redux/Store.js";

export default async ({
	authToken=undefined,
	extraDispatchData=undefined,
	throwOnError=false
}={}) => {

	const headers = {};

	if (authToken) {
		headers.Authorization = authToken;
	}

	if (Store.getState().userProfileDataUpdating) {
		return;
	}

	Store.dispatch({
		type: "update",
		data: {userProfileDataUpdating: true}
	});

	try {

		const response = await api({url: `/profile`, headers});
		const user = response.data;

		Store.dispatch({
			type: "update",
			data: {user, ...extraDispatchData}
		});

	}

	catch (e) {
		if (throwOnError) {
			throw e;
		}
	}

	Store.dispatch({
		type: "update",
		data: {userProfileDataUpdating: false}
	});

};
