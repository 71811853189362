import scss from "./View.module.scss";

export default props => {

	let className = scss.root;
	if (props.className) className += ` ${props.className}`;

	return (
		<div
			{...props}
			className={className}>
			{props.children}
		</div>
	);

};
