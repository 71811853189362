import Text from "Components/Text.js";
import {Button, Modal} from "semantic-ui-react";

export default props => {

	const disabled = (props.disabled || props.loading);

	return (
		<Modal
			className={props.className}
			open={props.open}
			size={(props.size || "small")}>
			<Modal.Header content={props.header} />
			<Modal.Content scrolling={true}>
				{(props.children || <Text color="secondary" content={props.content} />)}
			</Modal.Content>
			<Modal.Actions>
				{(props.onOk && props.onClose && <Button content={(props.cancelLabel || "Cancel")} disabled={disabled} onClick={props.onClose} />)}
				<Button
					color="blue"
					content={(props.okLabel || "OK")}
					disabled={disabled}
					icon="check"
					loading={props.loading}
					onClick={(props.onOk || props.onClose)} />
			</Modal.Actions>
		</Modal>
	);

};
