import RegistrationViewSuccessStep from "./RegistrationViewSuccessStep.js";
import SignInSubmissionErrorsList from "Signin/SigninSubmissionErrorsList.js";
import strings from "./RegistrationView.strings.json";
import {Message} from "semantic-ui-react";

export default ({result}) => {

	const hasErrors = !result.allOk;
	const strs = strings.signIn.success[(!hasErrors ? "complete" : "errors")];

	return (
		<RegistrationViewSuccessStep
			caption={strs.caption}
			hasErrors={hasErrors}
			header={strs.header}>
			<Message
				error={true}
				hidden={!hasErrors}>
				<Message.Header>
					{strings.signIn.errors.submissionMessage}
				</Message.Header>
				<SignInSubmissionErrorsList
					result={result} />
			</Message>
		</RegistrationViewSuccessStep>
	);

};
