import api from "api.js";
import ActiveIcon from "Components/ActiveIcon.js";
import AdminOnsiteSignoutDialog from "./AdminOnsiteSignoutDialog.js";
import DataTable from "Components/DataTable.js";
import Dialog from "Components/Dialog.js";
import Flex from "Components/Flex.js";
import Link from "Components/Link.js";
import PeopleTypes from "Resources/PeopleTypes.js";
import Text from "Components/Text.js";
import useAuth from "Hooks/useAuth.js";
import useStableState from "Hooks/useStableState.js";
import {useCallback, useState} from "react";
import {Button, List} from "semantic-ui-react";
import {ToastStore} from "Toasts/ToastProvider.js";

export default props => {

	const {state, onUpdateData} = props;
	const {hasPermission} = useAuth();

	const [allergiesSubject, setAllergiesSubject] = useState(null);
	const clearAllergiesSubject = useCallback(() => setAllergiesSubject(null), []);


	/**
	 * Signing out state
	 */
	const signingOutState = useStableState([]);
	const [signoutTarget, setSignoutTarget] = useState(null);
	const clearSignoutTarget = useCallback(() => setSignoutTarget(null), []);

	/**
	 * Toggle whether a person is currently being signed out.
	 *
	 * @param {Object} person
	 * @param {Boolean} isSigningOut
	 * @return {void}
	 */
	const toggleIsSigningOut = useCallback((person, isSigningOut) => {

		const id = person.Id;

		let selection = [...signingOutState.ref.current];
		if (!selection.includes(id) && isSigningOut) selection.push(id);
		else selection = selection.filter(i => (i !== id));

		signingOutState.applyUpdate(selection);

	}, [signingOutState]);

	/**
	 * A person was signed out of one or more of their signed-in sessions.
	 *
	 * @param {Object} person
	 * @param {Array<Integer>} attendanceIds
	 * @return {void}
	 */
	const handleSignoutCompleted = useCallback((person, attendanceIds) => {

		const data = [...state.data];

		const personIndex = data.indexOf(data.find(i => (i.Id === person.Id)));
		data[personIndex] = {...data[personIndex]};

		for (const attendanceId of attendanceIds) {

			const attendanceIndex = data[personIndex].Attendance.indexOf(data[personIndex].Attendance.find(a => (a.Id === attendanceId)));

			data[personIndex].Attendance[attendanceIndex] = {
				...data[personIndex].Attendance[attendanceIndex],
				SignedOut: true
			};

		}

		onUpdateData(data);

	}, [state, onUpdateData]);

	/**
	 * Selected a person to sign out.
	 *
	 * @async
	 * @param {Object} person
	 * @return {void}
	 */
	const handleSignoutSelect = useCallback(async person => {

		/** Discover the attendance records we can sign out */
		const availableSignouts = person.Attendance.filter(a => !a.SignedOut);

		/** Only one = sign out immediately */
		if (availableSignouts.length === 1) {

			const attendanceId = availableSignouts[0].Id;
			toggleIsSigningOut(person, true);

			try {

				const result = await api({url: `/attendance/signout`, method: "POST", data: {Records: [attendanceId]}}).then(({data}) => data);
				const success = (Object.values(result)?.[0] === true);

				if (success) handleSignoutCompleted(person, [attendanceId]);
				else ToastStore.toast({message: "Sign out unsuccessful.", type: "error"});

			}
			catch (e) {
				ToastStore.error(e);
			}

			toggleIsSigningOut(person, false);

		}

		/** User must choose the records to sign out */
		else setSignoutTarget(person);

	}, [handleSignoutCompleted, toggleIsSigningOut]);


	/** Table columns */
	const columns = [
		{
			id: "Person",
			render: i => <Link label={i.Name} uri={`/admin/people/${i.Id}`} />,
			width: 1
		},
		{
			id: "Family",
			render: i => <Link label={(i.Family?.Name || "None")} uri={(i.Family ? `/admin/families/${i.Family.Id}` : "")} />,
			width: 1
		},
		{
			id: "Type",
			render: i => PeopleTypes.getCaseFromValue(i.Type),
			width: 1
		},
		{
			id: "Session",
			render: i => (
				<List>
					{
						i.Attendance.map((attendance, key) => (
							<List.Item key={key}>
								<Flex
									alignItems="center"
									columnar={true}
									gap={0.25}>
									<ActiveIcon
										active={!attendance.SignedOut}
										title={(!attendance.SignedOut ? "Signed In (On-Site)" : "Signed Out")} />
									<Link
										label={(attendance.Session?.Name || "(Unknown)")}
										uri={(attendance.Session && `/admin/sessions/${attendance.Session.Id}`)} />
								</Flex>
							</List.Item>
						))
					}
				</List>
			),
			width: 1,
			getCellStyles: i => ((i.Attendance.length > 1) ? {verticalAlign: "top"} : undefined)
		},
		{
			id: "Allergies",
			render(i) {
				return (
					<Flex
						alignItems="center"
						columnar={true}
						gap={0.5}
						justifyContent="space-between">
						<div>
							<Text
								color={(!i.AllergyNotes ? "secondary" : undefined)}
								content={(i.AllergyNotes || "(None Recorded)")}
								noMargin={true}
								singleLine={true} />
						</div>
						<div>
							<Button basic={true} circular={true} color={(i.AllergyNotes ? "orange" : "grey")} icon="info" onClick={() => setAllergiesSubject(i)} />
						</div>
					</Flex>
				);
			},
			hidden: !hasPermission("ManageAllergiesData"),
			width: 2
		},
		{
			id: "Sign Out",
			render(i) {

				const canSignOut = !!i.Attendance.find(a => !a.SignedOut);
				const isSigningOut = signingOutState.state.includes(i.Id);

				if (canSignOut) {
					return (
						<Button
							content="Sign Out"
							disabled={isSigningOut}
							onClick={() => handleSignoutSelect(i)}
							size="small" />
					);
				}
				else {
					return (
						<Text
							color="secondary"
							content="(Signed Out)" />
					);
				}

			},
			hidden: !(hasPermission("SessionSignOut") || hasPermission("Administrator")),
			width: 1
		}
	];

	/** Render! */
	return (
		<>
			<DataTable
				columns={columns}
				definition={true}
				disabled={props.disabled}
				emptyMessage={(!state.data?.length && "Nobody has signed in today.")}
				filterData={props.filterData}
				onQueryChange={props.onQueryChange}
				query={props.query}
				stackWidth={1075}
				state={state}
				stickyHeader={props.stickyHeader}
				unpaginatedData={true} />
			<Dialog
				header={`Allergies: ${allergiesSubject?.Name}`}
				onClose={clearAllergiesSubject}
				open={!!allergiesSubject}>
				<Text
					color="secondary"
					content={(allergiesSubject?.AllergyNotes || "(None Recorded)")} />
			</Dialog>
			<AdminOnsiteSignoutDialog
				onClose={clearSignoutTarget}
				onComplete={handleSignoutCompleted}
				open={!!signoutTarget}
				target={signoutTarget} />
		</>
	);

};
