import api from "api.js";
import useData from "Hooks/useData.js";
import {useCallback} from "react";
import {useParams} from "react-router-dom";

export default ({child: Child}) => {

	const {Id: sessionId} = useParams();

	const state = useData(useCallback(
		() => (sessionId ? api({url: `/sessions/${sessionId}`}).then(({data}) => data) : null),
		[sessionId]
	));

	return <Child sessionId={sessionId} sessionState={state} />;

};
