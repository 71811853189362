import Flex from "Components/Flex.js";
import Hidden from "Components/Hidden.js";
import PersonPicker from "Components/PersonPicker.js";
import SigninVisitorRegistrationDialog from "./SigninVisitorRegistrationDialog.js";
import SigninVisitorSelectorPersonListItem from "./SigninVisitorSelectorPersonListItem.js";
import SigninViewSection from "./SigninViewSection.js";
import scss from "./SigninVisitorSelector.module.scss";
import useAuth from "Hooks/useAuth.js";
import useToggle from "Hooks/useToggle.js";
import useViewport from "Hooks/useViewport.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useCallback} from "react";
import {Button} from "semantic-ui-react";

export default props => {

	const {onChange, selection} = props;

	const registrationDialogState = useToggle();

	const {hasPermission} = useAuth();
	const {width: viewportWidth} = useViewport();

	const handleRegistered = useCallback(person => {
		onChange([...selection, person]);
	}, [onChange, selection]);

	const handleSelect = useCallback(([person]) => {
		if (!selection.map(p => p.Id).includes(person.Id)) {
			onChange([...selection, person]);
		}
		else {
			ToastStore.toast({
				message: `You've already selected ${person.Name}.`,
				type: "error"
			});
		}
	}, [onChange, selection]);

	const handleRemove = useCallback(person => {
		onChange(selection.filter(p => (p !== person)));
	}, [onChange, selection]);

	return (
		<SigninViewSection
			icon="users"
			label="Select who's visiting">
			<Flex>
				<Hidden hidden={!selection.length}>
					<Flex>
						{
							selection.map((person, key) => {
								return (
									<SigninVisitorSelectorPersonListItem
										key={key}
										onRemove={handleRemove}
										person={person} />
								);
							})
						}
					</Flex>
				</Hidden>
				<Flex
					className={scss.selector}
					columnar={true}
					gap={0.5}>
					<PersonPicker
						apiUrl="/signin/people"
						disabled={props.disabled}
						fluid={true}
						multiple={true}
						onChange={handleSelect}
						size="large"
						value={[]} />
					<Hidden hidden={!hasPermission("FamilyRegistration")}>
						<Button
							content={((viewportWidth >= 640) ? "Register New" : undefined)}
							icon="add"
							onClick={registrationDialogState.setTrue} />
					</Hidden>
				</Flex>
				<SigninVisitorRegistrationDialog
					onClose={registrationDialogState.setFalse}
					onRegistered={handleRegistered}
					open={registrationDialogState.value} />
			</Flex>
		</SigninViewSection>
	);

};
