import api from "api.js";
import useData from "Hooks/useData.js";
import useQuery from "Hooks/useQuery.js";
import AdminUsersTable from "./AdminUsersTable.js";
import AdminView from "Admin/AdminView.js";
import useAdminViewport from "Admin/useAdminViewport.js";
import useNavigator from "Hooks/useNavigator.js";
import {useCallback, useMemo} from "react";

export default () => {

	const {computeBodyTopOffset} = useAdminViewport();
	const navigate = useNavigator();

	const query = useQuery(useMemo(() => ({
		Active: true,
		Search: null,
		TotpEnabled: null,
		Sort: "Name",
		SortDirection: "Asc",
		Limit: 25,
		Page: 1
	}), []), useMemo(() => ({
		Active: "boolean",
		TotpEnabled: "boolean"
	}), []));

	const handleQueryChange = useCallback(
		props => query.setQuery({...query.query, ...props}),
		[query]
	);

	const state = useData(useCallback(
		() => api({url: "/users", params: query.query}).then(({data}) => data),
		[query.query]
	), true);

	return (
		<AdminView
			actionIcon="add"
			actionLabel="New"
			initialSearch={query.initialQuery?.Search}
			loading={state.loading}
			onAction={() => navigate("/admin/users/new")}
			onSearchChange={Search => handleQueryChange({Search})}
			search={query.query.Search}
			searchable={true}
			title="Users">
			<AdminUsersTable
				onQueryChange={handleQueryChange}
				query={query.query}
				state={state}
				stickyHeader={computeBodyTopOffset({hasActions: true, hasSearch: true})} />
		</AdminView>
	);

};
