import {useCallback, useEffect, useMemo, useRef, useState} from "react";

export default (fetcher, scrollToTop=false) => {

	const fetchKey = useRef(0);

	const [data, setData] = useState(null);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);

	const fetch = useCallback(
		async () => {

			fetchKey.current++;
			const key = fetchKey.current;

			setError(false);
			setLoading(true);

			try {
				const data = await fetcher();
				if (key === fetchKey.current) {
					setData(data);
				}
			}
			catch (error) {
				if (key === fetchKey.current) {
					setData(null);
					setError(error);
				}
			}

			if (key === fetchKey.current) {

				setLoading(false);

				if (scrollToTop) {
					window.scrollTo({top: 0});
				}
			}

		},
		[fetcher, scrollToTop]
	);

	useEffect(
		() => {
			fetch();
		},
		[fetch]
	);

	const empty = !data?.length;

	return useMemo(
		() => ({data, error, empty, fetch, loading, setData, setLoading}),
		[data, error, empty, fetch, loading, setData, setLoading]
	);

};
