import ActiveIcon from "Components/ActiveIcon.js";
import EditableList from "Components/EditableList.js";
import Flex from "Components/Flex.js";
import PeopleTypes from "Resources/PeopleTypes.js";
import RegistrationViewForm from "./RegistrationViewForm.js";
import Segment from "Components/Segment.js";
import TableList from "Components/TableList.js";
import Text from "Components/Text.js";
import libphonenumber from "libphonenumber-js";
import moment from "moment";
import scss from "./RegistrationViewReviewStep.module.scss";
import strings from "./RegistrationView.strings.json";
import {Button, Header, Message} from "semantic-ui-react";

export default props => {

	const renderNoFamilyMembersMessage = () => (
		<Message
			error={true}
			content={strings.review.familyMembers.empty}
			visible={true} />
	);

	return (
		<RegistrationViewForm
			className={scss.root}
			caption={strings.review.caption}>
			<Flex gap={3}>
				<Segment
					disableContentOutline={true}
					label="Family Details">
					<TableList
						className={scss.table}
						data={[
							{
								label: "Name",
								render: () => props.familyDetails.Name
							},
							{
								label: "Town",
								render: () => props.familyDetails.Town
							},
							{
								label: "Postcode",
								render: () => props.familyDetails.Postcode
							},
							{
								label: "Gift Aid",
								render: () => <ActiveIcon active={props.familyDetails.GiftAidStatus} />
							},
							{
								label: "Notes",
								render: () => <Text content={(props.familyDetails.Notes || "(None)")} />
							}
						]} />
					<Flex alignItems="flex-start">
						<Button
							content="Edit these details"
							disabled={props.disabled}
							icon="edit"
							onClick={props.onEditFamilyDetails}
							primary={true}
							type="button" />
					</Flex>
				</Segment>
				<Segment
					disableContentOutline={true}
					label="Family Members">
					{
						props.familyMembers?.map?.((familyMember, key) => {

							const type = PeopleTypes.getCaseFromValue(familyMember.Type);

							return (
								<Flex gap={0.5} key={key} mt={((key !== 0) ? 1 : 0.5)}>
									<Header
										as="h3"
										content={`#${(key + 1)}: ${familyMember.Name} (${type})`}
										sub={true} />
									<TableList
										className={scss.table}
										data={[
											{
												label: "Name",
												render: () => <strong>{familyMember.Name}</strong>
											},
											{
												label: "Relationship",
												render: () => type
											},
											{
												label: "Date of Birth",
												render: () => (familyMember.Dob ? (new moment(familyMember.Dob)).format("DD/MM/YYYY") : "Not Set")
											},
											{
												label: "Photo Consent",
												render: () => <ActiveIcon active={familyMember.PhotoConsent} />
											},
											{
												label: "Email Addresses",
												render() {

													const emails = familyMember.EmailAddresses?.filter?.(e => e.Email);

													if (emails?.length) {
														return (
															<EditableList
																items={emails}
																renderItem={e => e.Email} />
														);
													}
													else return "(None)";

												}
											},
											{
												label: "Phone Numbers",
												render() {

													const phones = familyMember.PhoneNumbers?.filter?.(p => p.Phone);

													if (phones?.length) {
														return (
															<EditableList
																items={phones}
																renderItem={p => libphonenumber(p.Phone, "GB").formatNational()} />
														);
													}
													else return "(None)";

												}
											},
											{
												label: "Allergy Notes",
												render: () => <Text content={(familyMember.AllergyNotes || "(None Recorded)")} />
											},
											{
												label: "Medical Notes",
												render: () => <Text content={(familyMember.MedicalNotes || "(None Recorded)")} />
											},
											{
												label: "Notes",
												render: () => <Text content={(familyMember.Notes || "(None)")} />
											}
										]} />
									<Flex alignItems="flex-start" mt={0.5}>
										<Button
											content="Edit these details"
											disabled={props.disabled}
											icon="edit"
											onClick={() => props.onEditFamilyMember(key)}
											primary={true}
											type="button" />
									</Flex>
								</Flex>
							);

						})
					}
					{(!props.familyMembers?.length && renderNoFamilyMembersMessage())}
				</Segment>
			</Flex>
			<Message
				content={strings.review.confirmationWarning.content}
				header={strings.review.confirmationWarning.header}
				hidden={!props.familyMembers?.length} />
		</RegistrationViewForm>
	);

};
