import Flex from "Components/Flex.js";
import {Divider, Form, Ref} from "semantic-ui-react";

export default props => {

	const content = (
		<div className={props.className}>
			<div><p>{props.caption}</p></div>
			<Divider hidden={true} />
			<Flex gap={2}>
				{props.children}
			</Flex>
		</div>
	);

	return (
		<Ref innerRef={props.innerRef}>
			{(props.onSubmit ? <Form onSubmit={props.onSubmit}>{content}</Form> : content)}
		</Ref>
	);

};
