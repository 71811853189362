import api from "api.js";
import moment from "moment";

export default async (people, sessions) => {

	const data = [];

	people.forEach(Person => {
		sessions.forEach(Session => {
			data.push({Person: Person.Id, Session: Session.Id});
		});
	});

	const result = await api({
		url: "/attendance",
		method: "POST",
		data: {
			Date: (new moment()).format("YYYY-MM-DD"),
			Visitations: data
		}
	});

	const resultData = result.data;

	return {

		/**
		 * The API returns an object indexed by the index of 
		 * that visitation object that we sent in the request, 
		 * with the value as `null` for success, or an 
		 * entity error HTTP response status code on error
		 */
		allOk: Object.values(resultData).every(v => (v === null)),

		/**
		 * Raw data object in the result
		 */
		data: resultData,

		/**
		 * Visitations data that we submitted
		 */
		submittedVisitations: data,

		/**
		 * People we submitted for (each session)
		 */
		people,

		/**
		 * Sessions we submitted for (each person)
		 */
		sessions

	};

};
