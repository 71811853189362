import api from "api.js";
import scss from "./PasswordChangeForm.module.scss";
import strings from "./PasswordChangeForm.strings.json";
import ProfileViewSection from "./ProfileViewSection.js";
import Text from "Components/Text.js";
import {createRef, useCallback, useEffect, useMemo, useState} from "react";
import {Button, Form, Message, Ref} from "semantic-ui-react";

export default () => {

	const inputProps = {
		maxLength: 255,
		minLength: 16
	};

	const initialState = useMemo(() => ({
		Current: "",
		Password: "",
		Confirmation: ""
	}), []);

	const currentInputRef = createRef();
	const newInputRef = createRef();
	const confirmInputRef = createRef();

	const [error, setError] = useState(false);
	const [hasConfirmationError, setHasConfirmationError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	/** Form state */
	const [formState, setFormState] = useState(initialState);

	/** Form field change handler */
	const handleChange = useCallback((e, {name, value}) => {

		setSuccess(false);

		if (((error?.response?.status === 401) && (name === "Current")) ||
			((error?.response?.status === 400) && (name === "Password")) ||
			(error && ![400, 401].includes(error?.response?.status))) {
			setError(false);
		}

		if (["Password", "Confirmation"].includes(name)) {
			setHasConfirmationError(false);
		}

		setFormState({...formState, [name]: value});

	}, [formState, error]);

	/** Submission handler */
	const handleSubmit = useCallback(async () => {

		setError(false);
		setHasConfirmationError(false);
		setLoading(true);

		if (formState.Password !== formState.Confirmation) {
			setHasConfirmationError(true);
			setLoading(false);
			return;
		}

		try {

			const {Current, Password} = formState;

			const result = await api({
				url: "/profile/password",
				method: "PUT",
				data: {Current, Password}
			});

			if (result?.status === 202) {
				setSuccess(true);
				setFormState(initialState);
			}

		}

		catch (e) {
			setError(e);
		}

		setLoading(false);

	}, [formState, initialState]);

	/** Refocus inputs after errors */
	useEffect(() => {

		let inputRef = null;
		const status = error?.response?.status;

		if (status === 400) {
			inputRef = newInputRef;
		}
		else if (status === 401) {
			inputRef = currentInputRef;
		}
		else if (hasConfirmationError) {
			inputRef = confirmInputRef;
		}

		inputRef?.current?.querySelector?.("input")?.select?.();

	}, [error, hasConfirmationError, currentInputRef, newInputRef, confirmInputRef]);

	/** Render! */
	return (
		<ProfileViewSection>
			<Form
				className={scss.root}
				error={(!!error || hasConfirmationError)}
				onSubmit={handleSubmit}
				success={success}>
				<Text
					color="secondary"
					content={strings.help} />
				<Ref innerRef={currentInputRef}>
					<Form.Input
						disabled={loading}
						error={(error?.response?.status === 401)}
						icon="key"
						iconPosition="left"
						name="Current"
						onChange={handleChange}
						placeholder="Current Password"
						required={true}
						type="password"
						value={formState.Current} />
				</Ref>
				<Ref innerRef={newInputRef}>
					<Form.Input
						disabled={loading}
						error={(hasConfirmationError || (error?.response?.status === 400))}
						icon="lock"
						iconPosition="left"
						name="Password"
						onChange={handleChange}
						placeholder="New Password"
						required={true}
						type="password"
						value={formState.Password}
						{...inputProps} />
				</Ref>
				<Ref innerRef={confirmInputRef}>
					<Form.Input
						disabled={loading}
						error={hasConfirmationError}
						icon="lock"
						iconPosition="left"
						name="Confirmation"
						onChange={handleChange}
						placeholder="Confirm Your New Password"
						required={true}
						type="password"
						value={formState.Confirmation}
						{...inputProps} />
				</Ref>
				<Button
					className={scss.submit}
					content="Save"
					disabled={loading}
					loading={loading}
					primary={true}
					type="submit" />
				<Message
					className={scss.message}
					error={true}
					hidden={(error?.response?.status !== 400)}
					header="Password rejected"
					content="Your new password has been rejected. Please check you've met the criteria listed above." />
				<Message
					className={scss.message}
					error={true}
					hidden={(error?.response?.status !== 401)}
					header="Password incorrect"
					content="Please check you've entered your current password correctly." />
				<Message
					className={scss.message}
					error={true}
					hidden={!hasConfirmationError}
					header="Password confirmation doesn't match"
					content="Please check you've confirmed your new password correctly. This prevents accidental typos from being saved as your password." />
				<Message
					className={scss.message}
					error={true}
					hidden={(hasConfirmationError || ([400, 401].includes(error?.response?.status)))}
					header="Error"
					content={(error?.message || "No details available.")} />
				<Message
					className={scss.message}
					success={true}
					header="Password saved"
					content="Your new password has been saved. Use it next time you login." />
			</Form>
		</ProfileViewSection>
	);

};
