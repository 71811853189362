import {useCallback, useRef, useState} from "react";

export default initialValue => {

	const ref = useRef(initialValue);
	const [state, setState] = useState(initialValue);

	const applyUpdate = useCallback(update => {
		ref.current = update;
		setState(update);
	}, []);

	const mergeUpdate = useCallback(update => {
		applyUpdate({
			...ref.current,
			...update
		});
	}, [applyUpdate]);

	return {
		ref,
		state,
		applyUpdate,
		mergeUpdate
	};

};
