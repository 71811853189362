export default [
	{
		label: "Dashboard",
		icon: "dashboard",
		uri: null
	},
	{
		label: "Attendance",
		icon: "clock",
		uri: "/attendance"
	},
	{
		label: "Families",
		icon: "address book",
		uri: "/families"
	},
	{
		label: "People",
		icon: "address book outline",
		uri: "/people"
	},
	{
		label: "Sessions",
		icon: "calendar alternate",
		uri: "/sessions"
	},
	{
		label: "Users",
		icon: "address card",
		uri: "/users",
		permission: "Sysadmin"
	}
];
