import api from "api.js";
import Dialog from "Components/Dialog.js";
import {ToastStore} from "Toasts/ToastProvider.js";
import {useState} from "react";

export default props => {

	const [submitting, setSubmitting] = useState(false);

	const handleConfirm = async () => {

		let success = false;
		setSubmitting(true);

		try {

			await api({
				url: `/sessions/${props.session.Id}/${(!props.session.Archived ? "archive" : "unarchive")}`,
				method: "POST"
			});

			success = true;

		}
		catch (e) {

			/**
			 * The session is already in the desired state
			 *
			 * We can treat this as success
			 */
			if (e?.response?.status === 409) {
				success = true;
			}
			else ToastStore.error(e);

		}

		if (success) {
			props.onClose();
			props.onComplete();
		}

		setSubmitting(false);

	};

	const content = [`Are you sure you want to ${(!props.session?.Archived ? "archive" : "unarchive")} this session?`];
	if (!props.session?.Archived) content.push("Archived sessions are not shown on the sign in screen.");

	return (
		<Dialog
			content={content.join("\n")}
			header={`${(!props.session?.Archived ? "Archive" : "Unarchive")} Session`}
			loading={submitting}
			onClose={props.onClose}
			onOk={handleConfirm}
			open={props.open} />
	);

};
