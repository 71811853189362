import debounce from "lodash.debounce";
import pluralize from "pluralize";
import scss from "./ResourcePicker.module.scss";
import {useState} from "react";
import {Dropdown} from "semantic-ui-react";

export default props => {

	const [searchQuery, setSearchQuery] = useState("");

	const classes = [scss.root];

	if (props.size) {
		classes.push(props.size);
	}

	/**
	 * Options to render
	 */
	const options = [...(props.options || [])];

	/**
	 * Close handler
	 */
	const handleClose = () => {

		/** Reset to "Type to search" reliably (as query's always cleared) */
		setSearchQuery("");

	};

	/**
	 * Value change handler
	 */
	const handleChange = (e, {value}) => {

		let resolvedValue;

		/**
		 * empty string = cleared
		 */
		if (value !== "") {

			if (!props.multiple) {
				value = [value];
			}

			/**
			 * We want to report the actual input object as the value
			 */
			resolvedValue = value.map(v => {
				return options.find(o => {
					return (props.getOptionId(o) === v);
				});
			});

			/**
			 * Only single selection expected
			 */
			if (!props.multiple) {
				resolvedValue = (resolvedValue[0] || undefined);
			}

		}
		else resolvedValue = (props.multiple ? [] : null);

		/** Necessary so "No results" changes to "Type to search" */
		if (props.multiple) {
			setSearchQuery("");
		}

		props.onChange(resolvedValue, props.name);

	};

	/**
	 * Search query change handler
	 */
	const handleSearchChange = debounce((e, {searchQuery}) => {
		setSearchQuery(searchQuery);
		props.onSearchChange(searchQuery);
	}, 300);

	/**
	 * Convert singular value to multiple array structure 
	 * to enable consistent handling of the value in the next steps
	 */
	let value = props.value;
	if (!props.multiple) value = [value];

	/**
	 * Make sure all values in the selection are represented by options
	 */
	if (props.multiple) {
		for (const v of value) {
			if (!options.find(o => (props.getOptionId(o) === props.getOptionId(v)))) {
				options.push(v);
			}
		}
	}

	/**
	 * Map our input objects to options
	 */
	const uiOptions = options.map?.((option, key) => {
		return {
			key,
			value: props.getOptionId(option),
			text: props.getOptionLabel(option)
		};
	});

	/**
	 * Values are expected to be input objects - 
	 * we need to map to option values now
	 */
	let resolvedValue = value.map(v => (v ? props.getOptionId(v) : undefined));
	if (!props.multiple) resolvedValue = (resolvedValue[0] || undefined);

	/**
	 * When we're using multiple selection, we want 
	 * to know whether all the options are selected, 
	 * so we can set appropriate placeholder text
	 */
	let areAllOptionsSelected = true;

	/**
	 * Determine whether all the options are selected
	 */
	if (props.multiple) {
		for (const o of uiOptions) {
			if (!resolvedValue?.find(v => (v === o.value))) {
				areAllOptionsSelected = false;
				break;
			}
		}
	}

	/**
	 * Add the pagination count warning message
	 */
	if (props.countAvailable) {

		const diff = ((props.countAvailable || 0) - (props.options?.length || 0));

		if (diff > 0) {
			uiOptions.push({
				disabled: true,
				key: -1,
				value: -1,
				text: `+${diff} more ${pluralize("result", diff)} (too many items to show)`
			});
		}

	}

	/**
	 * Decide our placeholder text (that appears when the dropdown's open)
	 */
	let placeholderOpen = (props.placeholderOpen || "Type to search...");
	if (props.state.error) placeholderOpen = "Error.";
	else if (searchQuery && ((props.options?.length === 0) || (props.multiple && props.options?.length && areAllOptionsSelected))) placeholderOpen = (props.noResultsMessage || "No results.");

	/**
	 * Render!
	 */
	return (
		<Dropdown
			className={classes.join(" ")}
			clearable={props.clearable}
			disabled={props.disabled}
			fluid={props.fluid}
			loading={props.state.loading}
			multiple={props.multiple}
			noResultsMessage={placeholderOpen}
			onChange={handleChange}
			onClose={handleClose}
			onSearchChange={handleSearchChange}
			options={(uiOptions || [])}
			placeholder={((!props.multiple && props.value) ? props.getOptionLabel(props.value) : props.placeholder)}
			renderLabel={label => ({active: false, basic: true, className: scss.label, color: "blue", content: label.text})}
			scrolling={true}
			search={options => options}
			selection={true}
			value={resolvedValue} />
	);

};
