import DataTable from "Components/DataTable.js";
import Flex from "Components/Flex.js";
import Link from "Components/Link.js";
import PeopleTypes from "Resources/PeopleTypes.js";
import moment from "moment";
import useViewport from "Hooks/useViewport.js";

export default props => {

	const {width: viewportWidth} = useViewport();

	const renderFamily = i => <Link label={(i.Person ? (i.Person.Family?.Name || "None") : "(Unavailable)")} uri={(i.Person?.Family ? `/admin/families/${i.Person.Family.Id}` : null)} />;

	const renderPersonType = i => (i.Person ? PeopleTypes.getCaseFromValue(i.Person.Type) : "(Unavailable)");

	const familyColumnHidden = (viewportWidth < 650);
	const personTypeColumnHidden = (viewportWidth < 1080);

	const columns = [
		{
			id: "Date",
			render: i => (new moment(i.Date)).format("DD/MM/YYYY"),
			sortable: true,
			width: ((viewportWidth < 1150) ? 1 : 2)
		},
		{
			id: "Person",
			render(i, {visibleColumns}) {

				const familyColumnHidden = !visibleColumns.includes("Family");
				const personTypeColumnHidden = !visibleColumns.includes("Type");

				return (
					<Flex gap={0.25}>
						<Link label={(i.Person?.Name || "(Unavailable)")} uri={(i.Person ? `/admin/people/${i.Person.Id}` : null)} />
						{((familyColumnHidden || personTypeColumnHidden) && <small>{(personTypeColumnHidden && renderPersonType(i))}{(!props.dontCombineFamilyIntoPersonColWhenFamilyColHidden && familyColumnHidden && <span> - {renderFamily(i)}</span>)}</small>)}
					</Flex>
				);

			},
			sortable: true,
			width: 2
		},
		{
			id: "Family",
			render: renderFamily,
			sortable: true,
			width: 2,
			hidden: familyColumnHidden
		},
		{
			id: "Type",
			render: renderPersonType,
			width: 2,
			hidden: personTypeColumnHidden
		},
		{
			id: "Session",
			render: i => <Link label={(i.Session?.Name || "(Unavailable)")} uri={(i.Session ? `/admin/sessions/${i.Session.Id}` : null)} />,
			sortable: true,
			width: 2
		}
	];

	return (
		<DataTable
			alwaysVisibleColumns={props.alwaysVisibleColumns}
			columns={columns}
			columnWidthOverrides={props.columnWidthOverrides}
			disabled={props.disabled}
			hiddenColumns={props.hiddenColumns}
			onQueryChange={props.onQueryChange}
			query={props.query}
			state={props.state}
			stickyHeader={props.stickyHeader} />
	);

};
