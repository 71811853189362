import Input from "./Input.js";

export default props => {

	const handleChange = (value, name) => {
		props.onChange((value?.toUpperCase() || null), name);
	};

	return (
		<Input
			{...props}
			maxLength={255}
			onChange={handleChange}
			pattern="^[A-Z]{1,2}[0-9][A-Z0-9]? [0-9][A-Z]{2}$" />
	);

};
