import ErrorBoundary from "App/Errors/ErrorBoundary.js";
import Routes from "./Routes.js";
import useAuth from "Hooks/useAuth.js";
import {useSelector} from "react-redux";
import {Route, Routes as Switch} from "react-router-dom";

export default () => {

	const {isAuthed, hasPermission} = useAuth();
	const navigationKey = useSelector(store => store.navigationKey);

	const routes = Routes.filter(route => {
		return ((isAuthed || route.public) &&
				(!route.permission || hasPermission(route.permission)));
	});

	return (
		<Switch key={navigationKey}>
			{
				routes.map(route => {

					const uris = (route.uris || [route.uri]);

					return uris.map((uri, key) => (
						<Route
							key={key}
							path={uri}
							element={<ErrorBoundary>{route.element}</ErrorBoundary>} />
					));

				})
			}
		</Switch>
	);

};
