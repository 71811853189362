import {useLocation} from "react-router-dom";

export default (items, defaultIndex=null) => {

	const location = useLocation();
	const visibleItems = items.filter(i => !i.hidden);

	const active = (visibleItems.find(i => (i.uri === location.pathname)) || visibleItems[defaultIndex]);

	return {
		active,
		activeIndex: visibleItems.indexOf(active),
		items: visibleItems.map(i => ({...i, active: (i === active)}))
	};

};
