import scss from "./SignInViewSection.module.scss";
import {Header, Segment} from "semantic-ui-react";

export default props => {

	const classes = [];

	if (props.className) {
		classes.push(props.className);
	}

	if (props.disableOutline) {
		classes.push(scss.outlineDisabled);
	}

	return (
		<Segment
			basic={props.disableOutline}
			className={classes.join(" ")}>
			<Header
				as="h2"
				block={true}
				content={props.label}
				icon={props.icon}
				size="medium" />
			{props.children}
		</Segment>
	);

};
