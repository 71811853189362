import scss from "./DataTableFilterDropdown.module.scss";
import {Checkbox, Dropdown, Icon} from "semantic-ui-react";

export default ({col, disabled, onChange}) => {

	const handleChange = (checked, value) => {

		let materialisedValue;
		const isCurrentlyAll = ((col.filter.value === null) || (col.filter.value === undefined));
		const isCurrentlyTrue = (col.filter.value === true);

		if (isCurrentlyAll) materialisedValue = !value;
		else if (isCurrentlyTrue) materialisedValue = ((value === false) ? null : col.filter.value);
		else materialisedValue = ((value === true) ? null : col.filter.value);

		onChange(col.id, materialisedValue);

	};

	const renderItem = (label, value, key=null) => {
		return (
			<Dropdown.Item key={key}>
				<Checkbox
					checked={((col.filter.value === null) || (col.filter.value === undefined) || (col.filter.value === value))}
					disabled={disabled}
					label={label}
					onChange={(e, c) => handleChange(c.checked, value)} />
			</Dropdown.Item>
		);
	};

	const items = [
		renderItem(col.filter.labels.false, false, 0),
		renderItem(col.filter.labels.true, true, 1)
	];

	if (col.filter.sortTrueFirst) {
		items.reverse();
	}

	return (
		<Dropdown
			className={scss.dropdown}
			item={true}
			icon={<Icon color={(((col.filter.value !== col.filter.defaultValue) && (col.filter.value !== undefined)) ? "blue" : undefined)} name="filter" />}
			simple={true}>
			<Dropdown.Menu>
				{items}
			</Dropdown.Menu>
		</Dropdown>
	);

};
