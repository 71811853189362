import scss from "./DropdownButton.module.scss";
import {Button, Dropdown} from "semantic-ui-react";

export default props => {

	const handleClick = e => {
		e.target?.blur?.();
		props.onClick();
	};

	let button = null;
	let dropdown = null;

	if (props.onClick) {
		button = (
			<Button
				className={scss.button}
				content={props.content}
				disabled={props.disabled}
				icon={props.icon}
				loading={props.loading}
				onClick={handleClick} />
		);
	}

	if (props.options?.length) {
		dropdown = (
			<Dropdown
				button={true}
				className={`button icon ${scss.dropdown}`}
				disabled={props.disabled}
				floating={true}
				icon="dropdown"
				options={props.options.map((o, key) => ({...o, key, onClick: (!o.disabled ? o.onClick : undefined)}))}
				trigger={<></>} />
		);
	}

	return (
		<Button.Group
			color={props.color}>
			{button}
			{dropdown}
		</Button.Group>
	);

};
