import RegistrationViewSuccessStep from "./RegistrationViewSuccessStep.js";
import strings from "./RegistrationView.strings.json";
import useAuth from "Hooks/useAuth.js";
import {Button, Message} from "semantic-ui-react";

export default ({familyMembers, onSignIn, result}) => {

	const {hasPermission} = useAuth();

	const hasErrors = !!result?.MemberErrors?.length;
	const strs = strings.success[(!hasErrors ? "complete" : "errors")];

	const renderSignInBtn = () => (
		<Button
			content="Sign In"
			icon="calendar check"
			onClick={onSignIn}
			primary={true}
			size="large" />
	);

	return (
		<RegistrationViewSuccessStep
			caption={(!hasErrors ? strs.caption : strs.caption[((result.MemberErrors.length === 1) ? "singleError" : "multipleErrors")])}
			hasErrors={hasErrors}
			header={strs.header}
			renderActions={() => (hasPermission("SessionSignIn") ? renderSignInBtn() : undefined)}>
			<Message
				error={true}
				hidden={(!hasErrors || (familyMembers.length === 1))}>
				<Message.Header>
					{strings.success.errors.message.caption}
				</Message.Header>
				<Message.List>
					{
						result.MemberErrors?.map?.(error => {

							const {Index} = error;

							return (
								<Message.Item key={Index}>
									#{(Index + 1)} - <strong>{familyMembers[Index].Name}</strong> ({error.Error})
								</Message.Item>
							);

						})
					}
				</Message.List>
			</Message>
		</RegistrationViewSuccessStep>
	);

};
